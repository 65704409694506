import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

import { AppLayoutComponent } from '@layout/app.layout.component';
import { ContractComponent } from '@modules/customers/pages/contract/contract.component';
import { CustomerComponent } from '@modules/customers/pages/customer/customer.component';
import { CustomersComponent } from '@modules/customers/pages/customers/customers.component';
import { DashboardComponent } from '@modules/dashboard/dashboard.component';
import { PlaygroundComponent } from '@modules/playground/playground.component';
import { SettingsComponent } from '@app/modules/settings/pages/settings/settings.component';
import { UsersComponent } from './modules/users/pages/users/users.component';
import { FinancialAdvisorsComponent } from './modules/financial-advisors/pages/financial-advisors/financial-advisors.component';
import {
  ROLE_MASTER,
  ROLE_ADMIN,
  ROLE_FINANCIAL_ADVISOR,
  ROLE_LEGAL
} from '@data/models';

import { RoleGuard } from '@app/core/guards';
import { ExpensesComponent } from '@modules/expenses/pages/expenses/expenses.component';
import { CustomersInvestmentsComponent } from '@modules//customers-investments/pages/customers-investments/customers-investments.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        data: { breadcrumb: 'Menu.Dashboard' },
        component: DashboardComponent
      },
      {
        path: 'home',
        data: { breadcrumb: 'Menu.Dashboard' },
        component: DashboardComponent
      },
      {
        path: 'dashboard',
        data: { breadcrumb: 'Menu.Dashboard' },
        component: DashboardComponent
      },
      {
        path: 'users',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.Users',
          allowedRoles: [
            ROLE_MASTER,
            ROLE_ADMIN,
            ROLE_FINANCIAL_ADVISOR,
            ROLE_LEGAL
          ]
        },
        component: UsersComponent
      },
      {
        path: 'financial-advisors',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.FinancialAdvisors',
          allowedRoles: [ROLE_MASTER, ROLE_ADMIN]
        },
        component: FinancialAdvisorsComponent
      },
      {
        path: 'customers',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.Customers',
          allowedRoles: [
            ROLE_MASTER,
            ROLE_ADMIN,
            ROLE_FINANCIAL_ADVISOR,
            ROLE_LEGAL
          ]
        },
        component: CustomersComponent
      },
      {
        path: 'customers/:customerId',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Common.Details',
          allowedRoles: [
            ROLE_MASTER,
            ROLE_ADMIN,
            ROLE_FINANCIAL_ADVISOR,
            ROLE_LEGAL
          ]
        },
        component: CustomerComponent
      },
      {
        path: 'customers/:customerId/contract/:contractId',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Contracts.Contract',
          allowedRoles: [
            ROLE_MASTER,
            ROLE_ADMIN,
            ROLE_FINANCIAL_ADVISOR,
            ROLE_LEGAL
          ]
        },
        component: ContractComponent
      },
      {
        path: 'settings',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.Settings',
          allowedRoles: [ROLE_ADMIN, ROLE_MASTER]
        },
        component: SettingsComponent
      },
      {
        path: 'customers-investments',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.CustomersInvestments',
          allowedRoles: [ROLE_ADMIN, ROLE_MASTER]
        },
        component: CustomersInvestmentsComponent
      },
      {
        path: 'expenses',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.Expenses',
          allowedRoles: [ROLE_ADMIN, ROLE_MASTER]
        },
        component: ExpensesComponent
      },
      {
        path: 'playground',
        canActivate: [RoleGuard],
        data: {
          breadcrumb: 'Menu.Playground',
          allowedRoles: [ROLE_MASTER]
        },
        component: PlaygroundComponent
      },
      { path: '**', redirectTo: '' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled' // Set to enabledBlocking to use Angular Universal
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
