import { Component, Input } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';

@Component({
  selector: 'app-show-expense-note',
  standalone: true,
  imports: [PrimeNgModule],
  templateUrl: './show-expense-note.component.html',
  styleUrl: './show-expense-note.component.scss'
})
export class ShowExpenseNoteComponent {
  @Input() notes: string = '';
}
