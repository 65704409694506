import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { first } from 'rxjs';
import { AlertsService } from '@app/core/services/alerts.service';
import { ForbiddenError } from '@data/models/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterMatchMode } from 'primeng/api';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { AddCustomerComponent } from '../../components/add-customer/add-customer.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  CustomerDto,
  CustomerDtoSearchResponse
} from '@app/data/amn-api/models';
import { CustomerService } from '@app/data/amn-api/services';
import { getSeverityById } from '@app/core/helpers/roles.helper';

@Component({
  standalone: true,
  selector: 'app-customers',
  imports: [
    PrimeNgModule,
    AddCustomerComponent,
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements AfterViewInit, OnInit {
  @ViewChild('dt') table!: Table;

  customers!: CustomerDto[];
  totalRecords!: number;
  isLoadingCustomers: boolean = false;

  top: number = 10;
  skip: number = 0;
  searchCriteria: string = '';
  sortBy: string = '';
  sortDesc: boolean = false;
  showInactive: boolean = false;
  backClicked: boolean = false;

  constructor(
    private customerService: CustomerService,
    private alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    const searchData = localStorage.getItem('searchTermsCustomer');
    this.searchCriteria = searchData ? searchData : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchCriteria) {
        this.table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
      }
    });
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteria =
        (event.globalFilter as string) ?? this.searchCriteria;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'customersFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteria,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          ShowInactive: this.showInactive
        })
      );
      this.loadCustomers();
    }
  }

  getSeverityByRoleId = getSeverityById;

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsCustomer(searchTerm);
    table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
  }

  loadCustomers(): void {
    this.customerService
      .searchCustomers({
        SearchText: this.searchCriteria,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc,
        ShowInactive: this.showInactive
      })
      .pipe(first())
      .subscribe({
        next: (value: CustomerDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.CustomersNotFound',
              'Alerts.CustomersNotFoundDesc',
              true
            );
          } else {
            this.customers = value.data;
            this.totalRecords = value.totalRecords ?? 0;
          }
        },
        error: (error: unknown) => {
          if (error instanceof ForbiddenError) {
            this.alertsService.addWarning('Error', error.message, false);
          } else if (error instanceof HttpErrorResponse) {
            console.warn('Error getting users: ', error);
          } else {
            console.error('Unknown error', error);
          }
        }
      });
  }

  onAddUser(): void {
    this.loadCustomers();
  }

  getSearchTermsCustomer(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteria = searchTerm;
      localStorage.setItem('searchTermsCustomer', this.searchCriteria);
    } else {
      this.searchCriteria = '';
      localStorage.removeItem('searchTermsCustomer');
    }
  }

  onIsActiveChange(): void {
    this.loadCustomers();
  }
}
