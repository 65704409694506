import { Component, OnInit } from '@angular/core';
import { BrowserUtils } from '@azure/msal-browser';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'AMN Capital';
  isIframe = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    // WARNING: This is required in order to call the auth service constructor -> DON'T REMOVE
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.isIframe = BrowserUtils.isInIframe(); // Remove this line to use Angular Universal
    this.primengConfig.ripple = true;
  }
}
