import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ProfileService } from '@app/core/services';
import { first } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {
  initials: string = '';
  fullName: string | null = '';

  @ViewChild('menubutton') menuButton!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.profileService.userLoaded
      .pipe(first())
      .subscribe((loaded: boolean) => {
        this.fullName = loaded ? this.profileService.fullName : 'Unknown';
        this.initials = loaded ? this.profileService.initials : '?';
      });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }
  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }
}
