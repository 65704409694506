<div class="layout-topbar">
  <div class="topbar-start">
    <button
      title="Menu"
      #menubutton
      type="button"
      class="topbar-menubutton p-link p-trigger"
      (click)="onMenuButtonClick()">
      <i class="pi pi-bars"></i>
    </button>

    <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
  </div>

  <div class="topbar-end">
    <ul class="topbar-menu">
      <!-- <li class="topbar-search">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search" class="w-12rem sm:w-full">
                </span>
            </li> -->
      <li class="ml-3">
        <button
          title="Config"
          pButton
          type="button"
          icon="pi pi-cog"
          class="p-button-text p-button-secondary p-button-rounded flex-shrink-0"
          (click)="onConfigButtonClick()"></button>
      </li>
      <li class="ml-2">
        <button
          type="button"
          class="p-link"
          (click)="onProfileButtonClick()"
          title="{{ fullName }}">
          <app-avatar
            [displayName]="initials"
            size="large"
            shape="circle"></app-avatar>
        </button>
      </li>
    </ul>
  </div>
</div>
