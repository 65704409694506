import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/core/services';
import {
  BankDto,
  BankStatus,
  ContractDto,
  CustomerBeneficiariesDto,
  DocumentDto,
  TaxRegimeDto,
  UserDetailsDto,
  UserDetailsDtoApiResponse,
  UserLogin
} from '@app/data/amn-api/models';
import { ContractsComponent } from '@app/modules/customers/components/contracts/contracts.component';
import { TransactionsComponent } from '@app/modules/customers/components/transactions/transactions.component';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { first } from 'rxjs';
import { CustomerDocumentUploadComponent } from '../../components/customer-document-upload/customer-document-upload.component';
import { AddContractComponent } from '../../components/add-contract/add-contract.component';
import { Table, TableService } from 'primeng/table';
import { AddBanksComponent } from '../../components/add-banks/add-banks.component';
import { BanksGridComponent } from '../../components/banks-grid/banks-grid.component';
import {
  DocumentService,
  UserService,
  CustomerService
} from '@app/data/amn-api/services';
import { DocumentsListComponent } from '@app/shared/components/documents-list/documents-list.component';
import { TranslateService } from '@ngx-translate/core';
import { TaxRegimesComponent } from '../../components/tax-regimes/tax-regimes.component';
import { EditCustomerComponent } from '../../components/edit-customer/edit-customer.component';
import { TabViewModule } from 'primeng/tabview';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { Message, SelectItem } from 'primeng/api';
import { CustomerBeneficiariesComponent } from '../../components/customer-beneficiaries/customer-beneficiaries.component';
import { CustomerBeneficiariesModalComponent } from '../../components/customer-beneficiaries-modal/customer-beneficiaries-modal.component';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  standalone: true,
  selector: 'app-customer',
  imports: [
    EditCustomerComponent,
    ContractsComponent,
    TransactionsComponent,
    CustomerDocumentUploadComponent,
    DocumentsListComponent,
    AddContractComponent,
    PrimeNgModule,
    SharedModule,
    BanksGridComponent,
    AddBanksComponent,
    TaxRegimesComponent,
    TabViewModule,
    CustomerBeneficiariesComponent,
    CustomerBeneficiariesModalComponent
  ],
  providers: [Table, TableService],
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  customerId: number = 0;
  customerContracts!: ContractDto[];
  filteredContracts!: ContractDto[];
  customerBeneficiaries!: CustomerBeneficiariesDto[];
  customer!: UserDetailsDto;
  customerName: string = '';
  banks!: BankDto[];
  bankSelected!: BankDto;
  taxRegimes!: TaxRegimeDto[];
  random: number = 0;
  status!: SelectItem[];
  selectedStatus: string | null = null;
  isVisibleDeactivateModal = false;
  isVisibleActivateModal = false;
  modalDeactivateTitle!: string;
  modalActivateTitle!: string;
  messages!: Message[];
  deactivateBank: BankDto = {
    bankName: ''
  };
  userLogins!: UserLogin[];
  lastLoginDate!: string;

  constructor(
    private route: ActivatedRoute,
    private customerService: UserService,
    private alertsService: AlertsService,
    private documentService: DocumentService,
    public translateService: TranslateService,
    private clientService: CustomerService,
    private errorService: ErrorService
  ) {
    this.status = [
      { label: 'NEW', value: 'NEW' },
      { label: 'DRAFT', value: 'DRAFT' },
      { label: 'SENTTOSIGN', value: 'SENTTOSIGN' },
      { label: 'SIGNPENDING', value: 'SIGNPENDING' },
      { label: 'SIGNED', value: 'SIGNED' },
      { label: 'ACTIVE', value: 'ACTIVE' },
      { label: 'COMPLETED', value: 'COMPLETED' },
      { label: 'CANCELLED', value: 'CANCELLED' }
    ];
  }

  ngOnInit() {
    dayjs.extend(localizedFormat);
    this.route.paramMap.pipe(first()).subscribe((params) => {
      const idParam = params.get('customerId');
      this.customerId = idParam ? +idParam : 0;
      this.loadCustomerDetails(this.customerId);
    });
  }

  loadCustomerDetails(customerId: number): void {
    setTimeout(() => {
      this.customerService
        .getUserAsync({ id: customerId })
        .pipe(first())
        .subscribe({
          next: (value: UserDetailsDtoApiResponse) => {
            if (value && value.data) {
              let userLoginIndex = 0;
              let userLoginData!: UserLogin;
              this.modalActivateTitle = this.translateService.instant(
                'Banks.ActivateTitle'
              );
              this.modalDeactivateTitle = this.translateService.instant(
                'Banks.DeactivateTitle'
              );
              this.customer = value.data;
              this.customerContracts = value.data.contracts ?? [];
              this.banks = value.data.banks ?? [];
              this.customerName = `${this.customer.fullName}`;
              this.taxRegimes = value.data.taxRegimes ?? [];
              this.userLogins = value.data.userLogins ?? [];
              this.customerBeneficiaries =
                value.data.customerBeneficiaries ?? [];
              if (this.userLogins.length) {
                userLoginIndex = Math.max(
                  ...this.userLogins.map((o) => o.id ?? 0)
                );
                userLoginData = this.userLogins.filter(
                  (u) => u.id === userLoginIndex
                )[0];
                this.lastLoginDate = dayjs(userLoginData.createdDate).format(
                  'L LT'
                );
              }
              const totalPercentage = this.customerBeneficiaries.reduce(
                (sum, beneficiary) => {
                  const percentage = beneficiary.percentage ?? 0;
                  return sum + percentage;
                },
                0
              );
              if (totalPercentage < 1) {
                const message = this.translateService.instant(
                  'Beneficiaries.PercentageNotCompleted'
                );
                this.messages = [
                  {
                    severity: 'info',
                    detail: message
                  }
                ];
              }
            }
          },
          error: (error: unknown) => {
            this.errorService.handleError(error);
          }
        });
    }, 100);
  }

  handleNameChanged(fullName: string) {
    this.customerName = fullName;
  }

  contractsFilterApplied(selectedStatus: string): void {
    this.selectedStatus = selectedStatus;
    this.updateFilteredContracts();
  }

  updateFilteredContracts(): void {
    if (this.selectedStatus) {
      this.filteredContracts = this.customerContracts.filter(
        (contract) => contract.status === this.selectedStatus
      );
    } else {
      this.filteredContracts = [...this.customerContracts];
    }
  }

  deleteCustomerDocumentById(documentId: number): void {
    this.documentService
      .deleteDocumentAsync({ id: documentId })
      .pipe(first())
      .subscribe({
        next: () => {
          this.customer.documents = this.customer?.documents?.filter(
            (doc: DocumentDto) => doc.id !== documentId
          );
          this.loadCustomerDetails(this.customerId);
          this.alertsService.addSuccess(
            'Alerts.Success',
            'Alerts.DocumentDeletedSuccessfully',
            true
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error, {
            badRequest: 'Alerts.DocumentDeletedError'
          });
        }
      });
  }

  onDocumentsUploaded() {
    this.loadCustomerDetails(this.customerId);
  }

  onSubmitBeneficiary(): void {
    this.loadCustomerDetails(this.customerId);
  }

  downloadDocumentById(
    documentId: number,
    fileName?: string | null | undefined
  ): void {
    this.documentService
      .downloadDocumentAsync({ id: documentId })
      .pipe(first())
      .subscribe({
        next: (response) => {
          const blob = response;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName || `document-${documentId}`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  handleGetBankCreated(bankCreated: { bankId: number; bankData: BankDto }) {
    bankCreated.bankData.id = bankCreated.bankId;
    bankCreated.bankData.status = 'ACTIVE';

    if (bankCreated.bankData.isDefault === true) {
      this.banks.forEach((_bankItem: BankDto, index: number) => {
        this.banks[index].isDefault = false;
      });
    }

    this.banks.push({ ...bankCreated.bankData });
    this.alertsService.addSuccess('Alerts.Success', 'Alerts.BankAdded', true);
  }

  handleGetBankEdited(bank: BankDto) {
    this.banks.forEach((bankItem: BankDto, index: number) => {
      if (bank.isDefault) {
        this.banks[index].isDefault = false;
      }

      if (bankItem.id === bank.id) {
        this.banks[index].bankName = bank.bankName;
        this.banks[index].accountNumber = bank.accountNumber;
        this.banks[index].cardNumber = bank.cardNumber;
        this.banks[index].clabe = bank.clabe;
        this.banks[index].country = bank.country;
        this.banks[index].currency = bank.currency;
        this.banks[index].isDefault = bank.isDefault;
        this.banks[index].routingNumber = bank.routingNumber;
        this.banks[index].swift = bank.swift;
      }
    });
    this.alertsService.addSuccess('Alerts.Success', 'Alerts.BankUpdated', true);
  }

  handleGetBankSelected(bank: BankDto) {
    this.bankSelected = bank;
    this.random = Math.random();
  }

  setBankActive(bank: BankDto) {
    if (bank) {
      this.isVisibleActivateModal = true;
      this.deactivateBank = bank;
    }
  }

  setBankDeactive(bank: BankDto) {
    if (bank) {
      this.isVisibleDeactivateModal = true;
      this.deactivateBank = bank;
    }
  }

  handleStatusCustomerBank(bank: BankDto, bankStatus: string) {
    if (bank) {
      const status =
        bankStatus === 'INACTIVE' ? BankStatus.Inactive : BankStatus.Active;
      const payload = { id: this.customerId, bankId: bank.id ?? 0, status };
      this.clientService
        .updateStatusCustomerBankAsync(payload)
        .pipe(first())
        .subscribe({
          next: () => {
            this.banks.filter((b) => b.id === bank.id)[0].status = status;
            this.isVisibleDeactivateModal = false;
            this.isVisibleActivateModal = false;
          },
          error: (err) => console.error(err)
        });
    }
  }
}
