import { IEnvironment } from '@env/ienvironment';

export const environment: IEnvironment = {
  production: false,
  azureAD: {
    clientId: 'd1e75907-d899-4916-8e5d-7e2b56143689',
    tenantId: '39df2d19-6e19-460e-8a79-c5c73b4ce3b1',
    tenant: 'amncapitalb2cdev',
    redirectUri: 'https://dev-app.amncapital.mx',
    apiName: 'amn_capital_api',
    scopes: ['global_access']
  },
  api: {
    url: 'https://dev-as-amncapital-api.azurewebsites.net',
    apiKey: 'xmjk1yupQinuobC'
  },
  token: {
    expiresIn: 60,
    maxRefreshTime: 30
  }
};
