import { Component, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthenticationService, ProfileService } from '@app/core/services';
import { first } from 'rxjs';
import { RoleDto, UserLogin } from '@app/data/amn-api/models';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {
  userFullName: string = '';
  roles: RoleDto[] | null = [];
  userLogins: UserLogin[] = [];
  lastLoginDate!: string;

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(value: boolean) {
    this.layoutService.state.profileSidebarVisible = value;
  }

  constructor(
    public layoutService: LayoutService,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    dayjs.extend(localizedFormat);
    this.profileService.userLoaded
      .pipe(first())
      .subscribe((loaded: boolean) => {
        this.userFullName = loaded
          ? (this.profileService.fullName ?? 'Unknown')
          : 'Unknown';
        this.roles = loaded ? this.profileService.roles : [];
        this.userLogins = loaded ? this.profileService.userLogins : [];
        this.lastLoginDate = this.getLastLoginRecord(this.userLogins);
      });
  }

  getLastLoginRecord(userLogins: UserLogin[]): string {
    if (userLogins.length === 1) {
      return dayjs(userLogins[0].createdDate).format('L LT');
    } else {
      return dayjs(userLogins[userLogins.length - 2].createdDate).format(
        'L LT'
      );
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }
}
