import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TransactionDto, TransactionType } from '@app/data/amn-api/models';
import { CapitalizePipe } from '@app/shared/pipes';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { SharedModule } from '@app/shared/shared.module';
import { AddTransactionDocumentComponent } from '../add-transaction-document/add-transaction-document.component';

@Component({
  standalone: true,
  selector: 'app-transactions',
  imports: [
    PrimeNgModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    CapitalizePipe,
    SharedModule,
    AddTransactionDocumentComponent
  ],
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent {
  @Input() transactions!: TransactionDto[];
  @Output() typeSelected = new EventEmitter<string>();
  @Output() uploadDocument = new EventEmitter<void>();
  @Output() requestDownloadDocument = new EventEmitter<{
    documentId: number;
    filename?: string;
  }>();

  filteredTransactions!: TransactionDto[];

  allowedUploadTransactionTypes = [
    TransactionType.Initial,
    TransactionType.Deposit,
    TransactionType.Withdrawal,
    TransactionType.Settlement,
    TransactionType.Refund
  ];

  clear(table: Table) {
    table.clear();
  }

  onDownloadDocument(documentId: number): void {
    const transactions = this.transactions.find(
      (doc) => doc.document?.id === documentId
    );

    if (transactions) {
      this.requestDownloadDocument.emit({
        documentId,
        filename: transactions.document?.fileName ?? undefined
      });
    } else {
      console.error('Document not found for ID:', documentId);
    }
  }

  onUploadDocumentTransaction(): void {
    this.uploadDocument.emit();
  }
}
