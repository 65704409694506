/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { InputClass } from '@app/core/classes/input';

@Component({
  selector: 'app-amn-dropdown',
  templateUrl: './amn-dropdown.component.html',
  styleUrl: './amn-dropdown.component.scss'
})
export class AmnDropdownComponent
  extends InputClass
  implements ControlValueAccessor
{
  @Input() options: any[] = [];
  @Input() field: string = '';
  @Output() filterApplied = new EventEmitter<any>();
  @Input() descriptionPrefix: string = '';
  @Input() placeholder: string = '';

  constructor() {
    super();
  }

  onFilterChange(value: any) {
    this.filterApplied.emit(value);
    this.writeValue(value);
  }

  override writeValue(value: any): void {
    this.value = value;
  }
}
