<p-button
  [outlined]="true"
  [disabled]="isEs()"
  severity="danger"
  class="mr-2"
  (onClick)="switchLang('es')">
  <img
    alt="es"
    src="assets/images/flag/flag_placeholder.png"
    class="flag flag-small flag-mx" />
</p-button>
<p-button
  [outlined]="true"
  [disabled]="!isEs()"
  severity="info"
  (onClick)="switchLang('en')">
  <img
    alt="en"
    src="assets/images/flag/flag_placeholder.png"
    class="flag flag-small flag-us" />
</p-button>
