import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const numbers = value.split('-');
    const phone = `(${numbers[0]}) ${numbers[1]}-${numbers[2]}`;
    return phone;
  }
}
