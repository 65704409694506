// Review why with the latest validators I can't have a valid status in the form
import {
  Component,
  inject,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BankDto, Country, Currency } from '@app/data/amn-api/models';
import { CustomerService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { AlertsService } from '@app/core/services';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-add-banks',
  standalone: true,
  imports: [PrimeNgModule, TranslateModule, SharedModule, CheckboxModule],
  templateUrl: './add-banks.component.html',
  styleUrl: './add-banks.component.scss'
})
export class AddBanksComponent implements OnInit, OnDestroy, OnChanges {
  @Input() bank!: BankDto;
  @Input() random!: unknown;
  @Input() isEditing = false;

  @Output() bankCreated = new EventEmitter<{
    bankId: number;
    bankData: BankDto;
  }>();
  @Output() bankEdited = new EventEmitter<BankDto>();

  submitLabel: string = 'Common.Add';
  visible: boolean = false;
  userId!: string | null;
  countryOptions!: SelectItem[];
  currencyOptions!: SelectItem[];
  bankForm!: FormGroup;
  checkboxValue!: boolean;
  isToAddRecord = true;
  titleToAdd!: string;
  titleToEdit!: string;

  private subscription = new Subscription();
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private customerService = inject(CustomerService);
  private alertsService = inject(AlertsService);
  private translateService = inject(TranslateService);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (!!changes['bank'] && !!changes['bank'].currentValue) ||
      (!!changes['random'] && !!changes['random'].currentValue)
    ) {
      this.handleInitFormEdition();
      this.isToAddRecord = false;
      this.isEditing = false;
      this.setEditForm();
      this.submitLabel = 'Common.Save';
      this.visible = true;
    }
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('customerId') ?? '';

    this.bankForm = this.fb.group({
      userId: new FormControl<string>(this.userId ?? '', Validators.required),
      bankName: new FormControl<string>('', Validators.required),
      clabe: new FormControl<string>(''),
      accountNumber: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(/^(?:\d{9}|\d{18})(?:_{0}|_{9})$/)
      ]),
      cardNumber: new FormControl<string>('', [
        Validators.pattern(/^(\d{4}(\s)){3}\d{4}$/)
      ]),
      country: new FormControl<Country | null>(null, Validators.required),
      currency: new FormControl<Currency | null>(null, Validators.required),
      routingNumber: new FormControl<string>(''),
      SWIFT: new FormControl<string>(''),
      isDefault: new FormControl<boolean>(false)
    });

    this.countryOptions = [
      { label: 'Mexico', value: Country.Mx },
      { label: 'United States', value: Country.Us }
    ];

    this.currencyOptions = [
      { label: 'MXN', value: Currency.Mxn },
      { label: 'USD', value: Currency.Usd }
    ];

    setTimeout(() => {
      this.titleToAdd = this.translateService.instant('Banks.AddBank');
      this.titleToEdit = this.translateService.instant('Banks.EditBank');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleAddClick() {
    this.isToAddRecord = true;
    this.bankForm.reset();
    this.bankForm.enable();
    this.bankForm.controls['userId'].setValue(this.userId ?? '');
    this.isEditing = true;
    this.visible = true;
  }

  handleCancelClick() {
    if (this.isToAddRecord) {
      this.visible = false;
    } else {
      this.isEditing = false;
      this.setEditForm();
    }
  }

  handleEditClick() {
    this.subscription.add(
      this.customerService
        .editBankAsync({
          id: this.userId ? Number(this.userId) : 0,
          bankId: this.bank.id ?? 0,
          body: this.bankForm.value
        })
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.bankEdited.emit(response.data);
              this.visible = false;
              this.isToAddRecord = true;
            }
          },
          error: (error) => {
            error.log(error);
            this.alertsService.addError(
              'Alerts.BankUpdatedError',
              'Alerts.BankUpdatedErrorDesc',
              true
            );
          }
        })
    );
  }

  handleSubmit() {
    if (this.isToAddRecord) {
      if (this.bankForm.valid) {
        this.subscription.add(
          this.customerService
            .createBankAsync({
              id: this.userId ? Number(this.userId) : 0,
              body: this.bankForm.value
            })
            .subscribe({
              next: (response) => {
                if (response.status === 200 || response.status === 201) {
                  if (response.data && response.data.id)
                    this.bankCreated.emit({
                      bankId: response.data?.id,
                      bankData: response.data
                    });

                  this.visible = false;
                }
              },
              error: (error) => {
                error.log(error);
                this.alertsService.addError(
                  'Alerts.BankAddedError',
                  'Alerts.BankAddedErrorDesc',
                  true
                );
              }
            })
        );
      }
    } else {
      this.handleEditClick();
    }
  }

  handleChangeCountry(country: DropdownChangeEvent) {
    if (country.value === 'MX') {
      this.bankForm.controls['clabe'].setValidators([
        Validators.required,
        Validators.pattern(/^(\d{3}(\s)){2}\d{11}(\s)\d$/)
      ]);
      this.bankForm.controls['clabe'].updateValueAndValidity();

      this.bankForm.controls['routingNumber'].clearValidators();
      this.bankForm.controls['routingNumber'].updateValueAndValidity();
    } else {
      this.bankForm.controls['routingNumber'].setValidators([
        Validators.required,
        Validators.pattern(/^\d{9}$/)
      ]);
      this.bankForm.controls['routingNumber'].updateValueAndValidity();

      this.bankForm.controls['clabe'].clearValidators();
      this.bankForm.controls['clabe'].updateValueAndValidity();
    }
  }

  private setEditForm() {
    if (this.isEditing) {
      this.bankForm.enable();
    } else {
      this.handleInitFormEdition();
      this.bankForm.disable();
    }
  }

  onEditClick() {
    this.isEditing = true;
    this.setEditForm();
  }

  handleInitFormEdition(): void {
    if (this.bank) {
      let currency: Currency = Currency.Usd;
      let country: Country = Country.Us;

      if (!!this.bank.currency && this.bank.currency === 'MXN') {
        currency = Currency.Mxn;
      }

      if (!!this.bank.country && this.bank.country === 'MX') {
        country = Country.Mx;
      }

      this.bankForm = this.fb.group({
        id: new FormControl<number>(this.bank.id ?? 0),
        userId: new FormControl<string>(this.userId ?? '', Validators.required),
        bankName: new FormControl<string>(
          this.bank.bankName ?? '',
          Validators.required
        ),
        clabe: new FormControl<string>(this.bank.clabe ?? ''),
        accountNumber: new FormControl<string>(
          this.bank.accountNumber ?? '',
          Validators.required
        ),
        cardNumber: new FormControl<string>(this.bank.cardNumber ?? '', [
          Validators.pattern('^.{16}$')
        ]),
        country: new FormControl<Country>(country ?? '', Validators.required),
        currency: new FormControl<Currency>(
          currency ?? '',
          Validators.required
        ),
        routingNumber: new FormControl<string>(this.bank.routingNumber ?? ''),
        SWIFT: new FormControl<string>(this.bank.swift ?? ''),
        isDefault: new FormControl<boolean>(this.bank.isDefault ?? false)
      });
    }
  }
}
