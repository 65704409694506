<div class="grid">
  <div class="col-12">
    <div class="card">
      <h3>{{ 'Menu.Expenses' | translate }}</h3>
      <p-table
        #dt
        [value]="selectedCategory ? filteredExpenses : expenses"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name', 'description', 'category']"
        [scrollable]="true"
        styleClass="mt-3">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div class="flex flex-column sm:flex-row gap-2">
              <span class="p-input-icon-left w-full sm:w-25rem">
                <i class="pi pi-search"></i>
                <input
                  title="search"
                  pInputText
                  type="text"
                  [(ngModel)]="searchCriteria"
                  (input)="onGlobalFilter(dt, $event)"
                  class="w-full" />
              </span>
              <p-calendar
                [(ngModel)]="date"
                view="month"
                dateFormat="MM yy"
                [readonlyInput]="true"
                (onSelect)="onDateChange($event)"
                inputId="date"
                placeholder="{{
                  'Common.FilterDate' | translate
                }}"></p-calendar>
              <app-amn-dropdown
                class="w-full sm:w-auto"
                [options]="categoriesOptions"
                field="categoriesOptions.label"
                (filterApplied)="filterCallback($event)"
                placeholder="{{ 'Common.Category' | translate }}"
                [descriptionPrefix]="'Expenses.CategoryTypes.'">
              </app-amn-dropdown>
            </div>
            <div>
              <app-modal-add-expenses
                (addExpense)="onAddExpense()"></app-modal-add-expenses>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="date" style="width: 15%">
              {{ 'Common.Date' | translate
              }}<p-sortIcon field="date"></p-sortIcon>
            </th>
            <th pSortableColumn="description" style="width: 25%">
              {{ 'Common.Description' | translate
              }}<p-sortIcon field="description"></p-sortIcon>
            </th>
            <th pSortableColumn="amount" style="width: 15%">
              {{ 'Common.Amount' | translate
              }}<p-sortIcon field="amount"></p-sortIcon>
            </th>
            <th pSortableColumn="notes" style="width: 5%">
              {{ 'Common.Notes' | translate
              }}<p-sortIcon field="notes"></p-sortIcon>
            </th>
            <th
              class="text-center"
              pSortableColumn="category"
              style="width: 15%">
              {{ 'Common.Category' | translate
              }}<p-sortIcon field="category"></p-sortIcon>
            </th>
            <th class="text-center" style="width: 15%">
              {{ 'Transactions.File' | translate }}
            </th>
            <th style="width: 10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-expense>
          <tr>
            <td>{{ expense.date | date }}</td>
            <td>{{ expense.description }}</td>
            <td>{{ expense.amount | currency }} {{ expense.currency }}</td>

            <td>
              @if (expense.notes) {
                <app-show-expense-note
                  [notes]="expense.notes"></app-show-expense-note>
              }
            </td>

            <td class="text-center">
              <span
                [class]="
                  'expense-badge category-' + expense.category | lowercase
                ">
                {{
                  'Expenses.CategoryTypes.' + expense.category | translate
                }}</span
              >
            </td>
            <td class="text-center">
              @if (expense.document) {
                <p-button
                  (click)="downloadDocumentById(expense.document.id)"
                  icon="pi pi-paperclip"
                  [rounded]="true"
                  [text]="true"
                  severity="info">
                </p-button>
              }
              @if (expense.id) {
                <app-add-expense-document
                  (uploadDocument)="onUploadDocumentExpense()"
                  [expenseId]="expense.id"></app-add-expense-document>
              }
            </td>
            <td>
              @if (expense.id) {
                <app-modal-edit-expense
                  (updateExpense)="onUpdateExpense()"
                  [expenseId]="expense.id"></app-modal-edit-expense>
              }
              @if (expense.id) {
                <p-button
                  (click)="requestDeleteSetting(expense.id, $event)"
                  icon="pi pi-times"
                  [rounded]="true"
                  [text]="true"
                  severity="danger">
                </p-button>
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ 'Expenses.NotFound' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-confirmDialog></p-confirmDialog>
    </div>
  </div>
</div>
