import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiKey = environment.api.apiKey;
    if (apiKey) {
      const modifiedRequest = request.clone({
        setHeaders: {
          ApiKey: apiKey
        }
      });
      return next.handle(modifiedRequest);
    }
    return next.handle(request);
  }
}
