import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private backEvent = new Subject<boolean>();

  backEvent$ = this.backEvent.asObservable();

  onBackClicked(backClicked: boolean): void {
    this.backEvent.next(backClicked);
  }
}
