/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContractDto } from '../models/contract-dto';
import { simulateContractCommandAsync } from '../fn/simulate/simulate-contract-command-async';
import { SimulateContractCommandAsync$Params } from '../fn/simulate/simulate-contract-command-async';
import { simulateContractCommandAsync$Plain } from '../fn/simulate/simulate-contract-command-async-plain';
import { SimulateContractCommandAsync$Plain$Params } from '../fn/simulate/simulate-contract-command-async-plain';

@Injectable({ providedIn: 'root' })
export class SimulateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `simulateContractCommandAsync()` */
  static readonly SimulateContractCommandAsyncPath = '/api/simulate/contract';

  /**
   * Simulate contract transactions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateContractCommandAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  simulateContractCommandAsync$Plain$Response(params?: SimulateContractCommandAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return simulateContractCommandAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Simulate contract transactions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `simulateContractCommandAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  simulateContractCommandAsync$Plain(params?: SimulateContractCommandAsync$Plain$Params, context?: HttpContext): Observable<ContractDto> {
    return this.simulateContractCommandAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

  /**
   * Simulate contract transactions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `simulateContractCommandAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  simulateContractCommandAsync$Response(params?: SimulateContractCommandAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDto>> {
    return simulateContractCommandAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Simulate contract transactions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `simulateContractCommandAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  simulateContractCommandAsync(params?: SimulateContractCommandAsync$Params, context?: HttpContext): Observable<ContractDto> {
    return this.simulateContractCommandAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDto>): ContractDto => r.body)
    );
  }

}
