<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex flex-wrap align-items-center justify-content-between">
        <h3>{{ 'Menu.Users' | translate }}</h3>
      </div>
      <p-table
        #dt
        [value]="users"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name', 'email']"
        [scrollable]="true"
        styleClass="mt-3">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div class="flex flex-wrap">
              <div
                class="p-input-icon-left w-full sm:w-25rem flex-order-1 sm:flex-order-0">
                <i class="pi pi-search" id="icon-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="searchCriteria"
                  (input)="onGlobalFilter(dt, $event)"
                  placeholder="{{ 'Customers.Search' | translate }}"
                  class="w-full" />
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-start ml-3">
                <h6 class="mb-0" for="showCustomers">
                  {{
                    (showCustomers
                      ? 'Users.NotShowCustomersList'
                      : 'Users.ShowCustomersList'
                    ) | translate
                  }}
                </h6>
                <p-inputSwitch
                  class="pl-3"
                  inputId="showCustomers"
                  [(ngModel)]="showCustomers"
                  (onChange)="onIsActiveChange($event)"></p-inputSwitch>
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-start ml-3">
                <h6 class="mb-0" for="showInactives">
                  {{ 'Users.ShowInactives' | translate }}
                </h6>
                <p-inputSwitch
                  class="pl-3"
                  inputId="showInactives"
                  [(ngModel)]="showInactives"
                  (onChange)="
                    onShowInactivesUsersChange($event)
                  "></p-inputSwitch>
              </div>
            </div>
            <app-add-user (addNewUser)="onAddUser()" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id" class="text-center" style="width: 10%">
              Id<p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="name" style="width: 20%">
              {{ 'Common.Name' | translate
              }}<p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="email" style="width: 20%">
              {{ 'Customers.Email' | translate
              }}<p-sortIcon field="email"></p-sortIcon>
            </th>
            @if (showInactives) {
              <th pSortableColumn="isActive" style="width: 10%">
                {{ 'Users.IsActive' | translate
                }}<p-sortIcon field="isActive"></p-sortIcon>
              </th>
            } @else {
              <th style="width: 10%">{{ 'Users.IsActive' | translate }}</th>
            }
            <th class="text-center" style="width: 20%">Roles</th>
            <th style="width: 10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-users>
          <tr>
            <td class="text-center">{{ users.id }}</td>
            <td class="font-bold">{{ users.fullName }}</td>
            <td>{{ users.email }}</td>
            <td>
              @if (users.isActive) {
                <span>{{ 'Users.Active' | translate }}</span>
              } @else {
                <span>{{ 'Users.Inactive' | translate }}</span>
              }
            </td>
            <td class="text-center">
              @if (users.roles.length) {
                @for (item of users.roles; track item.id) {
                  <span
                    [class]="
                      'customer-badge status-' + item.description.toLowerCase()
                    "
                    >{{ item.description }}</span
                  >
                }
              } @else {
                <span class="customer-badge status-empty">{{
                  'Users.NoRole' | translate
                }}</span>
              }
            </td>
            <td class="text-center">
              <p-button
                icon="pi pi-user-edit"
                [rounded]="true"
                [text]="true"
                size="large"
                (onClick)="showDialogToEdit(users)" />
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ 'Customers.NotFound' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  id="dialog"
  header="{{ 'Users.Edit' | translate }}"
  [(visible)]="visible"
  [style]="{ width: '50vw', height: '500px' }"
  (onHide)="visible = false"
  [modal]="true">
  <app-add-user-form
    (showParentModal)="handleOnShowParentModal($event)"
    (addNewUser)="onAddUser()"
    [isToEdit]="true"
    [userDetails]="userToEdit"
    [random]="random"></app-add-user-form>
</p-dialog>
