@if (settingId) {
  <p-button
    icon="pi pi-search"
    (click)="onAddClick()"
    [rounded]="true"
    [text]="true"></p-button>
} @else {
  <p-button
    (click)="onAddClick()"
    label="{{ 'Common.AddNew' | translate }}"
    icon="pi pi-plus"
    [outlined]="true"></p-button>
}
<p-dialog
  header="{{ 'Contracts.AddContract' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-settings-form
    (submitSetting)="onSubmit()"
    (cancelSetting)="onCancelClick()"
    [settingId]="settingId">
  </app-settings-form>
</p-dialog>
