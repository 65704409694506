import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertsService } from '@app/core/services';
import { ExpenseDto, ExpenseDtoSearchResponse } from '@app/data/amn-api/models';
import { DocumentService, ExpenseService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ConfirmationService,
  FilterMatchMode,
  PrimeIcons,
  SelectItem
} from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { first } from 'rxjs';
import { ModalAddExpensesComponent } from '../../components/modal-add-expenses/modal-add-expenses.component';
import { AddExpenseDocumentComponent } from '../../components/add-expense-document/add-expense-document.component';
import { ShowExpenseNoteComponent } from '../../components/show-expense-note/show-expense-note.component';
import { ModalEditExpenseComponent } from '../../components/modal-edit-expense/modal-edit-expense.component';
import { transformDateToISO } from '@app/core/helpers/date.helper';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  selector: 'app-expenses',
  standalone: true,
  imports: [
    PrimeNgModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ModalAddExpensesComponent,
    AddExpenseDocumentComponent,
    ShowExpenseNoteComponent,
    SharedModule,
    ModalEditExpenseComponent
  ],
  templateUrl: './expenses.component.html',
  styleUrl: './expenses.component.scss'
})
export class ExpensesComponent implements AfterViewInit, OnInit {
  @ViewChild('dt') table!: Table;

  expenses!: ExpenseDto[];
  filteredExpenses!: ExpenseDto[];
  totalRecords!: number;
  isLoadingExpenses: boolean = false;
  @Output() uploadDocument = new EventEmitter<void>();

  top: number = 10;
  skip: number = 0;
  searchCriteria: string = '';
  date: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  sortBy: string = '';
  sortDesc: boolean = false;
  backClicked: boolean = false;
  categoriesOptions: SelectItem[] = [
    { label: 'FOOD', value: 'FOOD' },
    { label: 'FISCAL', value: 'FISCAL' },
    { label: 'GENERAL', value: 'GENERAL' },
    { label: 'TRANSPORTATION', value: 'TRANSPORTATION' },
    { label: 'UTILITIES', value: 'UTILITIES' },
    { label: 'TECHNOLOGY', value: 'TECHNOLOGY' },
    { label: 'CONTRIBUTOR', value: 'CONTRIBUTOR' },
    { label: 'THIRDPARTY', value: 'THIRDPARTY' },
    { label: 'CLIENT', value: 'CLIENT' },
    { label: 'EXTRA', value: 'EXTRA' }
  ];

  selectedCategory: string | null = null;

  constructor(
    private expenseService: ExpenseService,
    private documentService: DocumentService,
    private alertsService: AlertsService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const searchData = localStorage.getItem('searchTermsExpense');
    this.searchCriteria = searchData ? searchData : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchCriteria) {
        this.table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
      }
    });
  }

  requestDeleteSetting(settingId: number, event: Event): void {
    const message = this.translateService.instant('Expenses.DeleteConfirm');
    const header = this.translateService.instant('Common.ConfirmationDelete');
    const accept = this.translateService.instant('Common.Accept');
    const cancel = this.translateService.instant('Common.Cancel');

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-secondary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptIcon: PrimeIcons.CHECK + ' mr-2',
      rejectIcon: PrimeIcons.TIMES + ' mr-2',
      acceptLabel: accept,
      rejectLabel: cancel,

      accept: () => {
        this.deleteSettingById(settingId);
      }
    });
  }

  filterCallback(selectedCategory: string): void {
    this.selectedCategory = selectedCategory;
    this.updateFilteredTransactions();
  }

  updateFilteredTransactions(): void {
    if (this.selectedCategory) {
      this.filteredExpenses = this.expenses.filter(
        (expenses) => expenses.category === this.selectedCategory
      );
    } else {
      this.filteredExpenses = [...this.expenses];
    }
  }

  onAddExpense(): void {
    this.loadExpenses();
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteria =
        (event.globalFilter as string) ?? this.searchCriteria;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'expensesFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteria,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        })
      );
      this.loadExpenses();
    }
  }

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsExpense(searchTerm);
    table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
  }

  onDateChange(event: Date): void {
    this.date = event;
    this.loadExpenses();
  }

  loadExpenses(): void {
    this.expenseService
      .searchExpenseQuery({
        SearchText: this.searchCriteria,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc,
        Date: transformDateToISO(this.date)
      })
      .pipe(first())
      .subscribe({
        next: (value: ExpenseDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.ExpensesNotFound',
              'Alerts.ExpensesNotFoundDesc',
              true
            );
          } else {
            this.expenses = value.data;
            this.totalRecords = value.totalRecords ?? 0;
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  downloadDocumentById(documentId: number): void {
    const expenses = this.expenses.find(
      (doc) => doc.document?.id === documentId
    );
    const filename = expenses?.document?.fileName;
    this.documentService
      .downloadDocumentAsync({ id: documentId })
      .pipe(first())
      .subscribe({
        next: (response) => {
          const blob = response;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = filename || `document-${documentId}`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);

          window.URL.revokeObjectURL(url);
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  getSearchTermsExpense(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteria = searchTerm;
      localStorage.setItem('searchTermsExpense', this.searchCriteria);
    } else {
      this.searchCriteria = '';
      localStorage.removeItem('searchTermsExpense');
    }
  }

  onUploadDocumentExpense(): void {
    this.loadExpenses();
  }

  onUpdateExpense(): void {
    this.loadExpenses();
  }

  deleteSettingById(expenseId: number): void {
    this.expenseService.deleteExpenseAsync({ id: expenseId }).subscribe({
      next: () => {
        this.expenses = this.expenses?.filter(
          (sett: ExpenseDto) => sett.id !== expenseId
        );
        this.alertsService.addSuccess(
          'Alerts.Success',
          'Alerts.DocumentDeletedSuccessfully',
          true
        );
        this.loadExpenses();
      },
      error: (error: unknown) => {
        this.errorService.handleError(error, {
          badRequest: 'Alerts.DocumentDeletedError'
        });
      }
    });
  }
}
