/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/core/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ForbiddenError } from '@app/data/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { Subscription, first } from 'rxjs';
import { ContractDocumentUploadComponent } from '../contract-document-upload/contract-document-upload.component';
import { ContractService, UserService } from '@app/data/amn-api/services';
import { SelectItem } from 'primeng/api';
import { transformDateToISO } from '@app/core/helpers/date.helper';

interface TimeDate {
  time: string;
  months: number;
}

@Component({
  standalone: true,
  selector: 'app-contract-form',
  imports: [
    PrimeNgModule,
    SharedModule,
    ContractDocumentUploadComponent,
    TranslateModule
  ],
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent implements OnInit {
  @Input() isEditing: boolean = false;
  @Input() isEditable: boolean = false;
  @Input() contractId: number = 0;
  @Input() customerId: number = 0;
  @Input() contractDetails: any;

  @Output() cancelContract = new EventEmitter<void>();
  @Output() submitContract = new EventEmitter<void>();
  @Output() statusChange = new EventEmitter<string>();

  interestOptions!: SelectItem[];
  companyOptions!: SelectItem[];
  userId: number | null = null;

  timeDateOptions!: TimeDate[];

  ObjectId?: string = '';
  submitLabel: string = 'Common.Add';
  private initialFormValues: any = null;

  contractForm!: FormGroup;

  customerSubscription: Subscription = new Subscription();

  showReinvestSwitch: boolean = false;
  canEditReinvestSwitch: boolean = false;

  @ViewChild(ContractDocumentUploadComponent)
  editCustomerDocumentComponent!: ContractDocumentUploadComponent;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private customerService: UserService,
    private contractService: ContractService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  get isNew(): boolean {
    return this.contractId === 0;
  }

  ngOnInit() {
    this.userId = this.customerId;

    this.timeDateOptions = [
      { time: '6 months', months: 6 },
      { time: '1 year', months: 12 },
      { time: '2 years', months: 24 },
      { time: '3 years', months: 36 },
      { time: 'undefined', months: 1200 }
    ];

    this.interestOptions = [
      { label: 'SIMPLE', value: 'SIMPLE' },
      { label: 'COMPOUND', value: 'COMPOUND' }
    ];

    this.companyOptions = [
      { label: 'INVESTMENTS', value: 'INVESTMENTS' },
      { label: 'CAPITAL', value: 'CAPITAL' }
    ];

    this.contractForm = this.fb.group({
      contractNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(AMN - [A-Z0-9]{3}[ ]{0,1}([A-Z0-9]|_){0,1})$/)
        ]
      ],
      amount: [0, [Validators.required, Validators.min(3000)]],
      signDate: null,
      reInvest: [false],
      startDate: [null, Validators.required],
      selectedDuration: ['', this.isNew ? Validators.required : []],
      endDate: null,
      status: [''],
      interestType: ['', Validators.required],
      interestRate: [
        0,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ],
      company: ['', Validators.required]
    });

    this.contractForm.get('selectedDuration')?.valueChanges.subscribe(() => {
      this.updateEndDate();
    });

    this.contractForm.get('startDate')?.valueChanges.subscribe(() => {
      this.updateEndDate();
    });

    const idParamContract = this.route.snapshot.paramMap.get('contractId');
    this.contractId = idParamContract ? +idParamContract : 0;

    const idParamCustomer = this.route.snapshot.paramMap.get('customerId');
    this.userId = idParamCustomer ? +idParamCustomer : 0;

    if (!this.isNew) {
      this.submitLabel = 'Common.Save';
      this.GetContractDetailsForm();
    }
    this.updateFormState();
  }

  emitStatusChange() {
    this.statusChange.emit(this.contractForm.value.status);
  }

  closeDialog(): void {
    this.cancelContract.emit();
  }

  updateEndDate() {
    const selectedDuration = this.contractForm.get('selectedDuration')?.value;
    const startDateValue = this.contractForm.get('startDate')?.value;

    if (selectedDuration && startDateValue) {
      const durationEntry = this.timeDateOptions.find(
        (entry) => entry.months === Number(selectedDuration)
      );
      if (durationEntry) {
        const startDate = new Date(startDateValue);
        const endDate = new Date(startDate.getTime());
        endDate.setMonth(endDate.getMonth() + durationEntry.months);
        endDate.setDate(endDate.getDate() - 1); // Set endDate to the last day of the month (if the month has 31 days, 30 days, 28 days, etc.
        this.contractForm
          .get('endDate')
          ?.setValue(endDate.toISOString().split('T')[0]); // Set endDate in the format 'YYYY-MM-DD'.
      }
    }
  }

  private GetContractDetailsForm() {
    this.contractForm.setValue({
      contractNumber: this.contractDetails.contractNumber,
      amount: this.contractDetails.amount,
      signDate: this.contractDetails.signDate
        ? new Date(this.contractDetails.signDate)
        : null,
      reInvest: this.contractDetails.reInvest ?? false,
      startDate: this.contractDetails.startDate
        ? new Date(this.contractDetails.startDate)
        : null,
      selectedDuration: null,
      endDate: this.contractDetails.endDate
        ? new Date(this.contractDetails.endDate)
        : null,
      status: this.contractDetails.status,
      interestType: this.contractDetails.interestType,
      interestRate: this.contractDetails.interestRate
        ? this.contractDetails.interestRate * 100
        : 0,
      company: this.contractDetails.company
    });
    this.isEditable = this.contractDetails.status !== 'CANCELLED';
    this.initialFormValues = this.contractForm.value;
    this.updateFormState();
  }

  onEditClick() {
    this.isEditing = !this.isEditing;
    this.updateFormState();
  }

  onCancelClick() {
    if (!this.isNew) {
      this.isEditing = false;
      this.updateFormState();
      if (this.initialFormValues) {
        this.contractForm.setValue(this.initialFormValues);
      }
    } else {
      this.cleanForm();
    }
    this.cancelContract.emit();
  }

  public cleanForm() {
    this.contractForm.reset({
      interestType: ''
    });
  }

  private resetForm() {
    this.isEditing = false;
    this.updateFormState();
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.contractForm.get(formControl)?.hasError(validationName) &&
      this.contractForm.get(formControl)?.touched
    );
  }

  updateOrInsert() {
    if (this.contractForm?.valid) {
      this.contractForm.updateValueAndValidity();
      const interestRateDecimal = this.contractForm.get('interestRate')?.value
        ? this.contractForm.get('interestRate')?.value / 100
        : 0;
      const contractData = {
        ...this.contractForm.value,
        contractNumber: this.contractForm.get('contractNumber')?.value,
        userId: this.userId,
        signDate: this.isNew
          ? null
          : transformDateToISO(this.contractForm.get('signDate')?.value),
        startDate: transformDateToISO(
          this.contractForm.get('startDate')?.value
        ),
        endDate: transformDateToISO(this.contractForm.get('endDate')?.value),
        reInvest: this.isNew ? false : this.contractForm.get('reInvest')?.value,
        amount: Number(this.contractForm.get('amount')?.value),
        interestRate: Number(interestRateDecimal),
        status: this.contractForm.get('status')?.value
          ? this.contractForm.get('status')?.value
          : 'NEW',
        company: this.contractForm.get('company')?.value
      };

      if (this.isNew && this.userId) {
        this.customerService
          .createUserContractAsync({ id: this.userId, body: contractData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.ContractAdded',
                'Alerts.ContractAddedCorrectly',
                true
              );
              this.resetForm();
              this.submitContract.emit();
              this.isEditing = true;
              this.contractForm.enable();
            },
            error: (error: unknown) => {
              if (error instanceof ForbiddenError) {
                this.alertsService.addWarning('Error', error.message, false);
              } else if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                  // Conflict
                  try {
                    const errorMessage = this.alertsService.getErrorMessage(
                      error.error?.detail
                    );
                    this.alertsService.addWarning(
                      this.translateService.instant(
                        'Alerts.ErrorCreatingContract'
                      ),
                      errorMessage ||
                        this.translateService.instant('Alerts.UnknownError'),
                      false
                    );
                  } catch (e) {
                    this.alertsService.addWarning(
                      'Alerts.ErrorCreatingContract',
                      'Alerts.UnknownError',
                      true
                    );
                  }
                }
              } else {
                console.error(
                  this.translateService.instant('Alerts.UnknownError'),
                  error
                );
              }
            }
          });
      } else {
        this.contractService
          .editContractAsync({ id: this.contractId, body: contractData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.ContractEdited',
                'Alerts.ContractEditedCorrectly',
                true
              );
              this.submitContract.emit();
              this.isEditing = false;
              this.updateFormState();
            },
            error: (error: unknown) => {
              if (error instanceof ForbiddenError) {
                this.alertsService.addWarning('Error', error.message, false);
              } else if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                  // Conflict
                  try {
                    const errorMessage = this.alertsService.getErrorMessage(
                      error.error?.detail
                    );
                    this.alertsService.addWarning(
                      this.translateService.instant(
                        'Alerts.ErrorEditingContract'
                      ),
                      errorMessage ||
                        this.translateService.instant('Alerts.UnknownError'),
                      false
                    );
                  } catch (e) {
                    this.alertsService.addWarning(
                      'Alerts.ErrorEditingContract',
                      'Alerts.UnknownError',
                      true
                    );
                  }
                }
              } else {
                console.error(
                  this.translateService.instant('Alerts.UnknownError'),
                  error
                );
              }
            }
          });
      }
    }
  }

  private updateFormState() {
    const status = this.contractForm.get('status')?.value;
    const endDate = new Date(this.contractForm.get('endDate')?.value);
    const today = new Date();

    this.showReinvestSwitch = false;
    this.contractForm.disable();

    if (
      this.isNew ||
      status === 'NEW' ||
      (status === 'DRAFT' && this.isEditing)
    ) {
      this.contractForm.enable();
      this.showReinvestSwitch = false;
    } else if (
      ['ACTIVE', 'SENTTOSIGN', 'SIGNPENDING', 'SIGNED'].includes(status)
    ) {
      const lastMonth = new Date(endDate.getTime());
      lastMonth.setMonth(lastMonth.getMonth() - 1);

      if (today >= lastMonth && today <= endDate) {
        this.showReinvestSwitch = true;
        if (this.isEditing) {
          this.contractForm.get('reInvest')?.enable();
        }
      }
    } else if (status === 'COMPLETED') {
      const twentyDaysAfterEndDate = new Date(endDate.getTime());
      twentyDaysAfterEndDate.setDate(endDate.getDate() + 20);

      if (today <= twentyDaysAfterEndDate) {
        this.showReinvestSwitch = true;
        if (this.isEditing) {
          this.contractForm.get('reInvest')?.enable();
        }
      } else {
        this.isEditable = false;
      }
    }

    if (
      this.contractForm.get('status')?.value &&
      status !== 'NEW' &&
      status !== 'DRAFT'
    ) {
      this.contractForm.get('company')?.disable();
    }
  }
}
