<form
  (ngSubmit)="updateOrInsert()"
  #customerForm="ngForm"
  [formGroup]="userForm">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
      <app-amn-input
        id="name"
        label="{{ 'Common.Name' | translate }}"
        formControlName="name"
        [errorMessages]="{
          required: 'Name is required',
          maxlength: 'Name cannot be more than 100 characters'
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
      <app-amn-input
        id="lastName"
        label="{{ 'Customers.LastName' | translate }}"
        formControlName="lastName"
        [errorMessages]="{
          required: 'Last Name is required.',
          maxlength: 'Last name cannot be more than 100 characters'
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
      <app-amn-input
        id="taxId"
        label="{{ 'Customers.RFC' | translate }}"
        formControlName="taxId"
        [errorMessages]="{
          maxlength: 'RFC cannot be more than 13 characters',
          invalidRFC: 'Invalid RFC format'
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 lg:col-6 md:col-6 sm:col-12">
      <app-amn-input
        id="email"
        label="{{ 'Customers.Email' | translate }}"
        formControlName="email"
        [errorMessages]="{
          required: 'Email is required.',
          email: 'Email format is invalid',
          maxlength: 'Email cannot be more than 50 characters'
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 lg:col-6 md:col-6 sm:col-12">
      <app-amn-input-phone
        id="phone"
        label="{{ 'Customers.Phone' | translate }}"
        formControlName="phoneNumber"
        [errorMessages]="{ required: 'Phone is required.' }">
      </app-amn-input-phone>
    </div>

    <div class="field col-12 lg:col-8 md:col-8 sm:col-12">
      <label for="personalIdentificationNumber" class="font-medium text-900">{{
        'Customers.IdentificationType' | translate
      }}</label>
      <div class="flex align-items-center w-full">
        <p-dropdown
          [options]="personalIdentificationTypeOpts"
          placeholder="{{ 'Common.Select' | translate }}"
          formControlName="personalIdentificationType"
          class="mr-2 flex-grow-3"></p-dropdown>
        <input
          pInputText
          type="text"
          class="flex-grow-5"
          placeholder="{{ 'Customers.IdentificationNumber' | translate }}"
          formControlName="personalIdentificationNumber" />
      </div>
    </div>
    <div class="field col-12 lg:col-4 md:col-4 sm:col-12">
      <app-amn-input-date
        id="taxSituationCertificateDate"
        label="{{ 'Customers.TaxSituationCertificateDate' | translate }}"
        formControlName="taxSituationCertificateDate"
        view="date"
        [errorMessages]="{
          required: 'Tax situation certificate date date is required.'
        }">
      </app-amn-input-date>
    </div>

    <div class="field col-12">
      <span class="font-medium text-xl text-900 mt-3 mb-3 block">{{
        'Customers.Address' | translate
      }}</span>
    </div>

    <div class="flex w-full">
      <div class="field col-12 lg:col-6 md:col-12 sm:col-12">
        <label for="country" class="font-medium text-900">{{
          'Customers.Country' | translate
        }}</label>
        <p-dropdown
          id="country"
          [options]="countryOptions"
          optionValue="value"
          optionLabel="label"
          formControlName="country"
          placeholder="Select country"
          appendTo="body"
          (onChange)="handleCountrySelected($event)">
        </p-dropdown>
      </div>
    </div>
    <div class="field col-12 lg:col-6 md:col-12 sm:col-12">
      <app-amn-input
        id="street"
        label="{{ 'Customers.Street' | translate }}"
        formControlName="street">
      </app-amn-input>
    </div>
    <div class="field col-12 lg:col-3 md:col-12 sm:col-12">
      <app-amn-input
        id="exteriorNumber"
        label="{{ 'Customers.ExternalNumber' | translate }}"
        formControlName="exteriorNumber">
      </app-amn-input>
    </div>
    <div class="field col-12 lg:col-3 md:col-12 sm:col-12">
      <app-amn-input
        id="interiorNumber"
        label="{{ 'Customers.InternalNumber' | translate }}"
        formControlName="interiorNumber">
      </app-amn-input>
    </div>
    @if (showNeighborhood) {
      <div class="col-12 lg:col-6 md:col-12 sm:col-12">
        <app-amn-input
          id="neighborhood"
          label="{{ 'Customers.Neighborhood' | translate }}"
          formControlName="neighborhood">
        </app-amn-input>
      </div>
    }
    <div class="col-12 lg:col-6 md:col-12 sm:col-12">
      <app-amn-input
        id="zipCode"
        label="{{ 'Customers.ZipCode' | translate }}"
        formControlName="zipCode">
      </app-amn-input>
    </div>
    <div class="col-12 lg:col-6 md:col-12 sm:col-12">
      <app-amn-input
        id="city"
        label="{{ 'Customers.City' | translate }}"
        formControlName="city">
      </app-amn-input>
    </div>
    <div class="col-12 lg:col-6 md:col-12 sm:col-12">
      <app-amn-input
        id="state"
        label="{{ 'Customers.State' | translate }}"
        formControlName="state">
      </app-amn-input>
    </div>
    <div class="w-full flex justify-content-end flex-wrap">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"
        [style.display]="this.isEditing ? 'block' : 'none'"></p-button>
      <p-button
        type="submit"
        label="{{ submitLabel | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="!this.userForm.valid"
        [style.display]="isEditing ? 'block' : 'none'"></p-button>
      <p-button
        label="{{ 'Common.Edit' | translate }}"
        icon="pi pi-user-edit"
        class="w-auto mt-3 mr-3"
        (click)="onEditClick()"
        [style.display]="this.isEditing ? 'none' : 'block'"></p-button>
    </div>
  </div>
</form>
