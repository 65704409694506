<nav class="layout-breadcrumb">
  <ol>
    <ng-template
      ngFor
      let-item
      let-last="last"
      [ngForOf]="breadcrumbs$ | async">
      <li>{{ item.label | translate }}</li>
      @if (!last) {
        <li class="layout-breadcrumb-chevron">
          <i class="pi pi-angle-right text-500 line-height-3"></i>
        </li>
      }
    </ng-template>
  </ol>
</nav>
