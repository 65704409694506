<div class="field">
  <label for="{{ id }}" class="font-medium text-900">{{ label }}</label>
  <p-inputNumber
    [id]="id"
    mode="decimal"
    [minFractionDigits]="2"
    [maxFractionDigits]="3"
    prefix="$"
    [(ngModel)]="value"
    (onInput)="onValueChanged()"
    [placeholder]="placeholder"
    title="{{ label }}"
    [disabled]="isDisabled"
    [class.ng-invalid]="
      !ngControl || !ngControl.control
        ? false
        : ngControl.control.enabled &&
          !ngControl.control.valid &&
          !ngControl.control.pristine
    ">
  </p-inputNumber>
  <div *ngFor="let error of ngControl?.errors | keyvalue">
    @if (hasError(error.key)) {
      <small [id]="id + '-help' + error" class="p-error">
        {{ errorMessages[error.key] }}
      </small>
    }
  </div>
</div>
