import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export enum AlertSeverity {
  success = 'success',
  info = 'info',
  warn = 'warn',
  error = 'error'
}

export enum AlertIcon {
  success = 'pi pi-check',
  info = 'pi pi-info-circle',
  warn = 'pi pi-exclamation-triangle',
  error = 'pi pi-times-circle'
}

export enum AlertPosition {
  center = 'center',
  topRight = 'top-right',
  topLeft = 'top-left',
  topCenter = 'top-center',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
  bottomCenter = 'bottom-center'
}

export interface CustomMessage {
  severity?: string;
  summary?: string;
  detail?: string;
  icon?: string;
  closable?: boolean;
  position?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private translateService = inject(TranslateService);

  messageAlertsChange: Subject<CustomMessage[]> = new Subject<
    CustomMessage[]
  >();

  private messages: CustomMessage[] = [];

  /**
   * Triggers when there are new changes on the messages array to show them in the alerts component
   */
  private notifyNow(): void {
    this.messageAlertsChange.next(this.messages);
    this.messages = [];
  }

  /**
   * Empty the messages array and triggers the messageNotifyNow function
   */
  public alertsClear(): void {
    this.messages = [];
    this.notifyNow();
  }

  /**
   * Add the alert message to the messages array and triggers the messageNotifyNow function
   */
  private addAlert(
    severity: string,
    title: string,
    detail: string,
    translate: boolean,
    closable: boolean = false,
    icon: string = AlertIcon.info,
    position: string = AlertPosition.topRight
  ): void {
    // Translate the title and detail
    if (translate) {
      title = this.translateService.instant(title);
      detail = this.translateService.instant(detail);
    }

    this.messages.push({
      severity,
      summary: title,
      detail,
      closable,
      icon,
      position
    });
    this.notifyNow();
  }

  /**
   * Add a success alert
   */
  public addSuccess(
    title: string,
    detail: string,
    translate: boolean,
    closable: boolean = false,
    position: string = AlertPosition.topRight
  ): void {
    const severity: string = AlertSeverity.success;
    const icon: string = AlertIcon.success;
    this.addAlert(severity, title, detail, translate, closable, icon, position);
  }

  /**
   * Add an information alert
   */
  public addInfo(
    title: string,
    detail: string,
    translate: boolean,
    closable: boolean = false,
    position: string = AlertPosition.topRight
  ): void {
    const severity: string = AlertSeverity.info;
    const icon: string = AlertIcon.info;
    this.addAlert(severity, title, detail, translate, closable, icon, position);
  }

  /**
   * Add an warning alert
   */
  public addWarning(
    title: string,
    detail: string,
    translate: boolean,
    closable: boolean = false,
    position: string = AlertPosition.topRight
  ): void {
    const severity: string = AlertSeverity.warn;
    const icon: string = AlertIcon.warn;
    this.addAlert(severity, title, detail, translate, closable, icon, position);
  }

  /**
   * Add an error alert
   */
  public addError(
    title: string,
    detail: string,
    translate: boolean,
    closable: boolean = false,
    position: string = AlertPosition.topRight
  ): void {
    const severity: string = AlertSeverity.error;
    const icon: string = AlertIcon.error;
    this.addAlert(severity, title, detail, translate, closable, icon, position);
  }

  /**
   * Retrieves the error message from the given comma-separated list of error codes,
   * translates each error code to its corresponding error message using the "Alerts" object,
   * and concatenates all the translated error messages into a single string.
   *
   * @param {string} errors - A comma-separated list of error codes.
   * @return {string} The concatenated error message.
   */
  public getErrorMessage(errors: string) {
    const errorsArray: string[] = errors.split(',');
    let errorMessage: string = '';
    errorsArray.forEach((error: string) => {
      // Get error message from the errors array translating the error code and adding it to the error message
      errorMessage += `${this.translateService.instant('Alerts.' + error.trim())} \n`;
    });

    return errorMessage;
  }
}
