/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteDocumentAsync } from '../fn/document/delete-document-async';
import { DeleteDocumentAsync$Params } from '../fn/document/delete-document-async';
import { deleteDocumentAsync$Plain } from '../fn/document/delete-document-async-plain';
import { DeleteDocumentAsync$Plain$Params } from '../fn/document/delete-document-async-plain';
import { downloadDocumentAsync } from '../fn/document/download-document-async';
import { DownloadDocumentAsync$Params } from '../fn/document/download-document-async';
import { SuccessApiResponse } from '../models/success-api-response';

@Injectable({ providedIn: 'root' })
export class DocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `downloadDocumentAsync()` */
  static readonly DownloadDocumentAsyncPath = '/api/document/{id}';

  /**
   * Download document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDocumentAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocumentAsync$Response(params: DownloadDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Download document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadDocumentAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDocumentAsync(params: DownloadDocumentAsync$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `deleteDocumentAsync()` */
  static readonly DeleteDocumentAsyncPath = '/api/document/{id}';

  /**
   * Delete document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocumentAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Plain$Response(params: DeleteDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocumentAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Plain(params: DeleteDocumentAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Delete document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocumentAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync$Response(params: DeleteDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocumentAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocumentAsync(params: DeleteDocumentAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
