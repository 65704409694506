<div class="field">
  <label for="{{ id }}" class="font-medium text-900">{{ label }}</label>
  <div class="flex align-items-center w-full">
    <p-dropdown
      [options]="countriesCodes"
      [(ngModel)]="countryCode"
      (onChange)="onCountryCodeChange()"
      placeholder="Select a Country"
      [disabled]="isDisabled"
      class="mr-2 flex-shrink-0">
      <ng-template pTemplate="selectedItem">
        @if (countryCode) {
          <div class="flex align-items-center gap-2">
            <img
              src="assets/images/flag/flag_placeholder.png"
              title="{{ countryCode }}"
              [class]="'flag flag-medium flag-' + getCountryLabel(countryCode)"
              alt="country code" />
            <div>{{ countryCode }}</div>
          </div>
        }
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img
            src="assets/images/flag/flag_placeholder.png"
            title="{{ country.label }}"
            [class]="'flag flag-medium flag-' + country.label.toLowerCase()"
            alt="country" />
          <div>{{ country.label }} {{ country.value }}</div>
        </div>
      </ng-template>
    </p-dropdown>

    <p-inputMask
      [id]="id"
      mask="(99) 9999-9999"
      placeholder="(##) ####-####"
      class="flex-grow-1"
      [disabled]="isDisabled"
      [(ngModel)]="phoneNumber"
      (onComplete)="onPhoneNumberChange()"
      [class.ng-invalid]="
        !ngControl || !ngControl.control
          ? false
          : ngControl.control.enabled &&
            !ngControl.control.valid &&
            !ngControl.control.pristine
      "></p-inputMask>
  </div>
</div>
