import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { ExpensesFormComponent } from '../expenses-form/expenses-form.component';

@Component({
  selector: 'app-add-expense-document',
  standalone: true,
  imports: [PrimeNgModule, ExpensesFormComponent, TranslateModule],
  templateUrl: './add-expense-document.component.html',
  styleUrl: './add-expense-document.component.scss'
})
export class AddExpenseDocumentComponent {
  visible: boolean = false;
  @Input() expenseId: number = 0;

  @Output() cancelExpense = new EventEmitter<void>();
  @Output() uploadDocument = new EventEmitter<void>();

  @ViewChild(ExpensesFormComponent) addExpense!: ExpensesFormComponent;

  onAddClick(): void {
    this.visible = true;
    this.addExpense.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelExpense.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.uploadDocument.emit();
  }
}
