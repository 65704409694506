/* tslint:disable */
/* eslint-disable */
export enum RelationshipType {
  Husband = 'HUSBAND',
  Wife = 'WIFE',
  Son = 'SON',
  Daughter = 'DAUGHTER',
  Brother = 'BROTHER',
  Sister = 'SISTER',
  Father = 'FATHER',
  Mother = 'MOTHER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Aunt = 'AUNT',
  Uncle = 'UNCLE',
  Cousin = 'COUSIN',
  Other = 'OTHER'
}
