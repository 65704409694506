/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SendPaymentsMonthlyFromInvestmentsCompanyCommand } from '../../models/send-payments-monthly-from-investments-company-command';
import { Success } from '../../models/success';

export interface SendContractsFromInvestmentsCompanyToAdminAsync$Params {
      body?: SendPaymentsMonthlyFromInvestmentsCompanyCommand
}

export function sendContractsFromInvestmentsCompanyToAdminAsync(http: HttpClient, rootUrl: string, params?: SendContractsFromInvestmentsCompanyToAdminAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
  const rb = new RequestBuilder(rootUrl, sendContractsFromInvestmentsCompanyToAdminAsync.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Success>;
    })
  );
}

sendContractsFromInvestmentsCompanyToAdminAsync.PATH = '/api/report/admin/contracts-from-investments-company';
