export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

export class AuthenticationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export class ForbiddenError extends Error {
  public status: number = 0;

  constructor(message?: string) {
    super(message);
    this.name = 'ForbiddenError';
    this.status = 403;
  }
}
