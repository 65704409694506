/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getTaxRegimesAsync } from '../fn/tax-regime/get-tax-regimes-async';
import { GetTaxRegimesAsync$Params } from '../fn/tax-regime/get-tax-regimes-async';
import { getTaxRegimesAsync$Plain } from '../fn/tax-regime/get-tax-regimes-async-plain';
import { GetTaxRegimesAsync$Plain$Params } from '../fn/tax-regime/get-tax-regimes-async-plain';
import { TaxRegimeDtoSearchResponse } from '../models/tax-regime-dto-search-response';

@Injectable({ providedIn: 'root' })
export class TaxRegimeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTaxRegimesAsync()` */
  static readonly GetTaxRegimesAsyncPath = '/api/tax-regime';

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxRegimesAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegimesAsync$Plain$Response(params?: GetTaxRegimesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRegimeDtoSearchResponse>> {
    return getTaxRegimesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaxRegimesAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegimesAsync$Plain(params?: GetTaxRegimesAsync$Plain$Params, context?: HttpContext): Observable<TaxRegimeDtoSearchResponse> {
    return this.getTaxRegimesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRegimeDtoSearchResponse>): TaxRegimeDtoSearchResponse => r.body)
    );
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxRegimesAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegimesAsync$Response(params?: GetTaxRegimesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRegimeDtoSearchResponse>> {
    return getTaxRegimesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaxRegimesAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxRegimesAsync(params?: GetTaxRegimesAsync$Params, context?: HttpContext): Observable<TaxRegimeDtoSearchResponse> {
    return this.getTaxRegimesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRegimeDtoSearchResponse>): TaxRegimeDtoSearchResponse => r.body)
    );
  }

}
