<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex flex-wrap align-items-center justify-content-between">
        <h3>{{ 'Menu.Customers' | translate }}</h3>
      </div>
      <p-table
        #dt
        [value]="customers"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name', 'email']"
        [scrollable]="true"
        styleClass="mt-3">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div class="flex flex-wrap">
              <div
                class="p-input-icon-left w-full sm:w-25rem flex-order-1 sm:flex-order-0">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="searchCriteria"
                  (input)="onGlobalFilter(dt, $event)"
                  placeholder="{{ 'Customers.Search' | translate }}"
                  class="w-full" />
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-start ml-3">
                <h6 class="mb-0" for="showInactive">
                  {{
                    (showInactive
                      ? 'Customers.ShowActiveOnly'
                      : 'Customers.ShowInactive'
                    ) | translate
                  }}
                </h6>
                <p-inputSwitch
                  class="pl-3"
                  inputId="showInactive"
                  [(ngModel)]="showInactive"
                  (onChange)="onIsActiveChange()"></p-inputSwitch>
              </div>
            </div>
            <app-add-customer (addUser)="onAddUser()" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id" class="text-center" style="width: 10%">
              Id<p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="name" style="width: 20%">
              {{ 'Common.Name' | translate
              }}<p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="email" style="width: 20%">
              {{ 'Customers.Email' | translate
              }}<p-sortIcon field="email"></p-sortIcon>
            </th>
            <th class="text-center" style="width: 10%">
              {{ 'Customers.Phone' | translate }}
            </th>
            <th class="text-right" style="width: 15%">
              {{ 'Customers.Capital' | translate }} USD
            </th>
            <th class="text-right" style="width: 15%">
              {{ 'Customers.Capital' | translate }} MXN
            </th>
            <th style="width: 10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td class="text-center">{{ customer.id }}</td>
            <td class="font-bold">{{ customer.fullName }}</td>
            <td>{{ customer.email }}</td>
            <td class="text-center">{{ customer.phoneNumber ?? 0 }}</td>
            <td class="font-bold text-right">
              {{ customer.investedAmountUSD ?? 0 | currency }}
            </td>
            <td class="font-bold text-right">
              {{ customer.investedAmountMXN ?? 0 | currency }}
            </td>
            <td class="text-center">
              <a
                [routerLink]="['/customers', customer.id]"
                routerLinkActive="active"
                >{{ 'Common.Details' | translate }}</a
              >
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ 'Customers.NotFound' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
