import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AlertsService, CustomMessage } from '@core/services';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: [MessageService]
})
export class AlertsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.subscribeToMessageAlerts();
  }

  subscribeToMessageAlerts(): void {
    // Subscribe to messageAlertsChange from service to display them when there are new items
    this.alertsService.messageAlertsChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((messages: CustomMessage[]) => {
        if (messages !== undefined) {
          messages.forEach((message) => {
            this.messageService.add(message);
          });
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
