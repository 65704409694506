import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  inject,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsService } from '@app/core/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateCustomerTaxRegimeCommand,
  TaxRegimeDto
} from '@app/data/amn-api/models';
import { Subscription } from 'rxjs';
import { TaxRegimeService, CustomerService } from '@app/data/amn-api/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-tax-regimes',
  standalone: true,
  imports: [TranslateModule, PrimeNgModule, FormsModule],
  templateUrl: './add-tax-regimes.component.html',
  styleUrl: './add-tax-regimes.component.scss'
})
export class AddTaxRegimesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemsToAdd: TaxRegimeDto[] = [];
  @Output() updatedRegimes = new EventEmitter<TaxRegimeDto[]>();
  private subscription = new Subscription();

  userId = '';
  isDisabled = true;
  currentRegimes: TaxRegimeDto[] = [];
  visible = false;
  taxRegimes: TaxRegimeDto[] = [];
  taxRegimesSelected!: TaxRegimeDto | undefined;
  isLoading = false;

  private taxRegimeService = inject(TaxRegimeService);
  private customerService = inject(CustomerService);
  private route = inject(ActivatedRoute);
  private translateService = inject(TranslateService);
  private alertsService = inject(AlertsService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemsToAdd'] && changes['itemsToAdd'].currentValue) {
      if (changes['itemsToAdd'].currentValue) {
        changes['itemsToAdd'].currentValue.forEach(
          (currentTaxes: TaxRegimeDto) => {
            const index = this.currentRegimes.findIndex(
              (t) => t.id === currentTaxes.id
            );
            if (index === -1) {
              this.currentRegimes.push(currentTaxes);
              this.isDisabled = false;
            }
          }
        );
      }
    } else if (!changes['itemsToAdd'].currentValue) {
      this.isDisabled = false;
    }
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('customerId') ?? '';

    this.subscription.add(
      this.taxRegimeService.getTaxRegimesAsync().subscribe({
        next: (response) =>
          (this.taxRegimes = response.data ? response.data : [])
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showDialog() {
    this.visible = true;
    this.taxRegimesSelected = undefined;

    if (
      !!this.itemsToAdd &&
      this.itemsToAdd.length &&
      !this.currentRegimes.length
    ) {
      this.itemsToAdd.forEach((currentTaxes: TaxRegimeDto) => {
        this.currentRegimes.push(currentTaxes);
      });
    }
  }

  handleAddTaxRegime() {
    if (this.taxRegimesSelected) {
      const taxSelectedId = this.taxRegimesSelected.id
        ? this.taxRegimesSelected.id
        : 0;
      const regime = this.currentRegimes.find((t) => t.id === taxSelectedId);
      if (!regime) {
        this.currentRegimes.push(this.taxRegimesSelected);
      }
    }
  }

  handleRemoveTaxRegime(taxRegime: TaxRegimeDto) {
    const index = this.currentRegimes.findIndex((t) => t.id === taxRegime.id);
    this.currentRegimes.splice(index, 1);
  }

  handleCancel() {
    this.currentRegimes = [];
    this.visible = false;
  }

  handleSubmit() {
    this.isLoading = true;
    const taxesIds: number[] = [];

    this.currentRegimes.forEach((taxDetails: TaxRegimeDto) => {
      if (!!taxDetails && !!taxDetails.id) {
        taxesIds.push(taxDetails.id);
      }
    });

    const body: CreateCustomerTaxRegimeCommand = {
      userId: Number(this.userId),
      taxRegimes: taxesIds
    };

    this.subscription.add(
      this.customerService
        .createTaxRegimeCustomerAsync({ id: Number(this.userId), body })
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.alertsService.addSuccess(
                'TaxRegime.Success.Title',
                'TaxRegime.Success.CreateTaxRegime',
                true
              );
              this.updatedRegimes.emit(this.currentRegimes);
              this.isLoading = false;
              this.handleCancel();
            }
          },
          error: (err) => {
            console.error(err);
            this.alertsService.addError(
              'TaxRegime.Errors.Title',
              'TaxRegime.Errors.CreateTaxRegime',
              true
            );
          }
        })
    );
  }
}
