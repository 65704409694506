<p-button
  (click)="onAddClick()"
  icon="pi pi-pencil"
  [rounded]="true"
  [text]="true"
  severity="info">
</p-button>
<p-dialog
  header="{{ 'Expenses.EditExpense' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '55rem' }"
  [draggable]="false"
  [resizable]="false">
  <app-edit-expense-form
    [expenseId]="expenseId"
    (submitExpense)="onSubmit()"
    (cancelExpense)="onCancelClick()"></app-edit-expense-form>
</p-dialog>
