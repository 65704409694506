import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize', standalone: true })
export class CapitalizePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const splitString = value
      .split(' ')
      .map((s: string) => `${s[0].toUpperCase()}${s.slice(1)}`);
    return splitString.join(' ');
  }
}
