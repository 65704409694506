import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomerBeneficiaryFormComponent } from '../customer-beneficiary-form/customer-beneficiary-form.component';
import { CustomerBeneficiariesDto } from '@app/data/amn-api/models';
import { AlertsService } from '@app/core/services';

@Component({
  selector: 'app-customer-beneficiaries-modal',
  standalone: true,
  imports: [PrimeNgModule, CustomerBeneficiaryFormComponent, TranslateModule],
  templateUrl: './customer-beneficiaries-modal.component.html',
  styleUrl: './customer-beneficiaries-modal.component.scss'
})
export class CustomerBeneficiariesModalComponent {
  @Input() beneficiaryId: number = 0;
  @Input() customerId: number = 0;
  @Input() beneficiaries!: CustomerBeneficiariesDto[];
  visible: boolean = false;
  titleToAdd!: string;
  titleToEdit!: string;

  @Output() cancelBeneficiary = new EventEmitter<void>();
  @Output() submitBeneficiary = new EventEmitter<void>();

  @ViewChild(CustomerBeneficiaryFormComponent)
  beneficiaryComponent!: CustomerBeneficiaryFormComponent;
  private translateService = inject(TranslateService);
  private alertsService = inject(AlertsService);

  onAddClick(): void {
    if (this.beneficiaries.length < 5) {
      this.titleToAdd = this.translateService.instant(
        'Beneficiaries.AddBeneficiary'
      );
      this.titleToEdit = this.translateService.instant(
        'Beneficiaries.EditBeneficiary'
      );
      this.visible = true;
      if (this.beneficiaryId) {
        this.beneficiaryComponent.loadBeneficiaryDetails(this.beneficiaryId);
      }
      this.beneficiaryComponent.cleanForm();
    } else {
      this.alertsService.addWarning(
        'Alerts.ManyBeneficiaries',
        'Alerts.ManyBeneficiariesDesc',
        true
      );
    }
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelBeneficiary.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.submitBeneficiary.emit();
  }
}
