<p-button
  (click)="onAddClick()"
  icon="pi pi-upload"
  [rounded]="true"
  [text]="true"
  severity="info">
</p-button>
<p-dialog
  header="{{ 'Transactions.UploadFile' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '35rem' }"
  [draggable]="false"
  [resizable]="false">
  <app-expenses-form
    [isEditing]="true"
    [expenseId]="expenseId"
    (submitExpense)="onSubmit()"
    (cancelExpense)="onCancelClick()"></app-expenses-form>
</p-dialog>
