<div>
  <p-toast></p-toast>
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-4">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        <app-avatar
          [displayName]="customerName"
          size="xlarge"
          shape="circle"></app-avatar>
        <div class="flex flex-column align-items-center sm:align-items-start">
          <span class="text-900 font-bold text-4xl">{{ customerName }}</span>
          @if (!!lastLoginDate) {
            <p class="text-600 m-0">
              {{ 'Customers.LastLoginDate' | translate }} {{ lastLoginDate }}
            </p>
          } @else {
            <p class="text-600 m-0">
              {{ 'Customers.NoLastLoginDate' | translate }}
            </p>
          }
        </div>
      </div>
    </div>
  </div>
  <p-tabView>
    <p-tabPanel header="{{ 'Customers.AccountSummary' | translate }}">
      <div class="grid flex-wrap gap-3 pt-3 p-fluid">
        <div class="col-12 card mb-3 p-5">
          <div
            class="flex flex-column sm:flex-row align-items-center gap-4 mb-3">
            <div class="flex flex-column sm:flex-row align-items-center gap-3">
              <div class="text-900 text-xl font-semibold">
                {{ 'Customers.Contracts' | translate }}
              </div>
            </div>
            <div class="flex gap-3 sm:ml-auto">
              <app-amn-dropdown
                [options]="status"
                field="status.label"
                [placeholder]="translateService.instant('Contracts.Status')"
                (filterApplied)="contractsFilterApplied($event)"
                [descriptionPrefix]="'Contracts.StatusDescriptions.'">
              </app-amn-dropdown>
              <app-add-contract
                (addContract)="
                  loadCustomerDetails(customerId)
                "></app-add-contract>
            </div>
          </div>
          <div class="flex flex-column row-gap-3">
            <app-contracts
              [contracts]="
                selectedStatus ? filteredContracts : customerContracts
              "></app-contracts>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'Customers.PersonalInformation' | translate }}">
      <div class="grid grid-nogutter flex-wrap gap-3 p-fluid">
        <div class="col-12 lg:col-8">
          <div class="grid formgrid">
            <div class="card mb-3">
              <div class="field">
                <app-edit-customer
                  [customerId]="customerId"
                  [customer]="customer"
                  (nameChanged)="handleNameChanged($event)">
                </app-edit-customer>
              </div>
            </div>
            <div class="col-12 card pt-5 mb-3">
              <div
                class="flex flex-column sm:flex-row align-items-center gap-4 mb-3">
                <div
                  class="flex flex-column sm:flex-row align-items-center gap-3">
                  <div class="text-900 text-xl font-semibold">
                    {{ 'Customers.Beneficiaries' | translate }}
                  </div>
                </div>
                <div class="flex gap-3 sm:ml-auto">
                  <app-customer-beneficiaries-modal
                    (submitBeneficiary)="onSubmitBeneficiary()"
                    [customerId]="customerId"
                    [beneficiaries]="customerBeneficiaries">
                  </app-customer-beneficiaries-modal>
                </div>
              </div>
              <div class="flex flex-column row-gap-3">
                <app-customer-beneficiaries
                  (editBeneficiary)="onSubmitBeneficiary()"
                  [beneficiaries]="
                    customerBeneficiaries
                  "></app-customer-beneficiaries>
                <p-messages
                  [(value)]="messages"
                  [enableService]="false"
                  [closable]="false" />
              </div>
            </div>
            <div class="col-12 card p-5">
              <div
                class="flex flex-column sm:flex-row align-items-center gap-4 mb-3">
                <div
                  class="flex flex-column sm:flex-row align-items-center gap-3">
                  <div class="text-900 text-xl font-semibold">
                    {{ 'Banks.Banks' | translate }}
                  </div>
                </div>
                <div class="flex gap-3 sm:ml-auto">
                  <app-add-banks
                    (bankCreated)="handleGetBankCreated($event)"
                    (bankEdited)="handleGetBankEdited($event)"
                    [bank]="bankSelected"
                    [random]="random"
                    [isEditing]="true">
                  </app-add-banks>
                </div>
              </div>
              <div class="flex flex-column row-gap-3">
                <app-banks-grid
                  [banks]="banks"
                  (bankSelectedData)="handleGetBankSelected($event)"
                  (bankSelectedActivate)="setBankActive($event)"
                  (bankSelectedDeactivate)="setBankDeactive($event)">
                </app-banks-grid>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1 lg:col-3 xl:col-3 flex flex-column">
          <div class="grid formgrid h-full">
            <div class="col-12 lg:col-11 card p-5 mb-3 lg:ml-5">
              <app-tax-regimes
                [taxRegimesByUser]="taxRegimes"></app-tax-regimes>
            </div>
            <div class="col-12 lg:col-11 card p-5 mb-3 lg:ml-5">
              @if (customer) {
                <app-documents-list
                  [documents]="customer.documents"
                  (deleteDocumentRequest)="deleteCustomerDocumentById($event)"
                  (requestDownloadDocument)="
                    downloadDocumentById($event.documentId, $event.filename)
                  "
                  >>
                </app-documents-list>
              }
            </div>
            <div class="col-12 lg:col-11 card p-5 lg:ml-5">
              @if (customerId) {
                <app-customer-document-upload
                  [customerId]="customerId"
                  (documentsUploaded)="onDocumentsUploaded()">
                </app-customer-document-upload>
              }
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog
  [header]="modalDeactivateTitle"
  [(visible)]="isVisibleDeactivateModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <p>{{ 'Banks.DeactivateBody' | translate }}</p>
  <p>
    <i class="pi pi-building text-primary"></i> Bank:
    {{ deactivateBank.bankName }}
  </p>
  @if (deactivateBank.accountNumber) {
    <p>
      <i class="pi pi-hashtag text-primary"></i> Account number:
      {{ deactivateBank.accountNumber }}
    </p>
  }
  @if (deactivateBank.clabe) {
    <p>
      <i class="pi pi-th-large text-primary"></i> Clabe:
      {{ deactivateBank.clabe }}
    </p>
  }
  <ng-template pTemplate="footer">
    <p-button
      label="{{ 'Common.Cancel' | translate }}"
      (click)="isVisibleDeactivateModal = false"
      icon="pi pi-times"
      class="w-auto mr-3 mt-3"
      [outlined]="true"
      severity="secondary"></p-button>
    <p-button
      icon="pi pi-check"
      (click)="handleStatusCustomerBank(deactivateBank, 'INACTIVE')"
      label="{{ 'Common.Accept' | translate }}"
      [outlined]="true"
      pAutoFocus
      [autofocus]="true"></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  [header]="modalActivateTitle"
  [(visible)]="isVisibleActivateModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <p>{{ 'Banks.ActivateBody' | translate }}</p>
  <p>
    <i class="pi pi-building text-primary"></i> Bank:
    {{ deactivateBank.bankName }}
  </p>
  @if (deactivateBank.accountNumber) {
    <p>
      <i class="pi pi-hashtag text-primary"></i> Account number:
      {{ deactivateBank.accountNumber }}
    </p>
  }
  @if (deactivateBank.clabe) {
    <p>
      <i class="pi pi-th-large text-primary"></i> Clabe:
      {{ deactivateBank.clabe }}
    </p>
  }
  <ng-template pTemplate="footer">
    <p-button
      label="{{ 'Common.Cancel' | translate }}"
      (click)="isVisibleActivateModal = false"
      icon="pi pi-times"
      class="w-auto mr-3 mt-3"
      [outlined]="true"
      severity="secondary"></p-button>
    <p-button
      icon="pi pi-check"
      (click)="handleStatusCustomerBank(deactivateBank, 'ACTIVE')"
      label="{{ 'Common.Accept' | translate }}"
      [outlined]="true"
      pAutoFocus
      [autofocus]="true"></p-button>
  </ng-template>
</p-dialog>
