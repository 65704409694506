<p-dropdown
  [ngModel]="value"
  [options]="options"
  (onChange)="onFilterChange($event.value)"
  [showClear]="true"
  [placeholder]="placeholder"
  appendTo="body">
  <ng-template pTemplate="selectedItem">
    @if (value) {
      {{ descriptionPrefix + value | translate }}
    } @else {
      {{ 'Common.FilterPlaceholder' | translate }}
    }
  </ng-template>
  <ng-template let-option pTemplate="item">
    <span>{{ descriptionPrefix + option.label | translate }}</span>
  </ng-template>
</p-dropdown>
