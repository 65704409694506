<p-button
  (click)="onAddClick()"
  label="{{ 'Common.AddNew' | translate }}"
  icon="pi pi-user-plus"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'Customers.AddCustomer' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <form (ngSubmit)="onSubmit()" [formGroup]="userForm">
    <div class="grid formgrid p-fluid">
      <div class="field col-6">
        <app-amn-input
          id="name"
          label="{{ 'Common.Name' | translate }}"
          formControlName="name"
          [errorMessages]="{
            required: 'Name is required',
            maxlength: 'Name cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-6">
        <app-amn-input
          id="lastName"
          label="{{ 'Customers.LastName' | translate }}"
          formControlName="lastName"
          [errorMessages]="{
            required: 'Last Name is required.',
            maxlength: 'Last name cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-12 md:col-12">
        <app-amn-input
          id="email"
          label="{{ 'Customers.Email' | translate }}"
          formControlName="email"
          [errorMessages]="{
            required: 'Email is required.',
            email: 'Email format is invalid',
            maxlength: 'Email cannot be more than 50 characters'
          }">
        </app-amn-input>
      </div>
      <div class="w-full flex justify-content-end flex-wrap">
        <p-button
          label="{{ 'Common.Cancel' | translate }}"
          icon="pi pi-times"
          class="w-auto mr-3 mt-3"
          [outlined]="true"
          severity="secondary"
          (click)="onCancelClick()"></p-button>
        <p-button
          type="submit"
          label="{{ 'Common.Save' | translate }}"
          icon="pi pi-check"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="!this.userForm.valid"></p-button>
      </div>
    </div>
  </form>
</p-dialog>
