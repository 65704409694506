/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankDtoApiResponse } from '../models/bank-dto-api-response';
import { ContractDetailsDtoApiResponse } from '../models/contract-details-dto-api-response';
import { createBankAsync } from '../fn/customer/create-bank-async';
import { CreateBankAsync$Params } from '../fn/customer/create-bank-async';
import { createBankAsync$Plain } from '../fn/customer/create-bank-async-plain';
import { CreateBankAsync$Plain$Params } from '../fn/customer/create-bank-async-plain';
import { createCustomerBeneficiariesAsync } from '../fn/customer/create-customer-beneficiaries-async';
import { CreateCustomerBeneficiariesAsync$Params } from '../fn/customer/create-customer-beneficiaries-async';
import { createCustomerBeneficiariesAsync$Plain } from '../fn/customer/create-customer-beneficiaries-async-plain';
import { CreateCustomerBeneficiariesAsync$Plain$Params } from '../fn/customer/create-customer-beneficiaries-async-plain';
import { createTaxRegimeCustomerAsync } from '../fn/customer/create-tax-regime-customer-async';
import { CreateTaxRegimeCustomerAsync$Params } from '../fn/customer/create-tax-regime-customer-async';
import { createTaxRegimeCustomerAsync$Plain } from '../fn/customer/create-tax-regime-customer-async-plain';
import { CreateTaxRegimeCustomerAsync$Plain$Params } from '../fn/customer/create-tax-regime-customer-async-plain';
import { CustomerBeneficiariesDtoApiResponse } from '../models/customer-beneficiaries-dto-api-response';
import { CustomerDtoSearchResponse } from '../models/customer-dto-search-response';
import { deleteBankAsync } from '../fn/customer/delete-bank-async';
import { DeleteBankAsync$Params } from '../fn/customer/delete-bank-async';
import { deleteBankAsync$Plain } from '../fn/customer/delete-bank-async-plain';
import { DeleteBankAsync$Plain$Params } from '../fn/customer/delete-bank-async-plain';
import { DocumentDtoListApiResponse } from '../models/document-dto-list-api-response';
import { editBankAsync } from '../fn/customer/edit-bank-async';
import { EditBankAsync$Params } from '../fn/customer/edit-bank-async';
import { editBankAsync$Plain } from '../fn/customer/edit-bank-async-plain';
import { EditBankAsync$Plain$Params } from '../fn/customer/edit-bank-async-plain';
import { editCustomerBeneficiariesAsync } from '../fn/customer/edit-customer-beneficiaries-async';
import { EditCustomerBeneficiariesAsync$Params } from '../fn/customer/edit-customer-beneficiaries-async';
import { editCustomerBeneficiariesAsync$Plain } from '../fn/customer/edit-customer-beneficiaries-async-plain';
import { EditCustomerBeneficiariesAsync$Plain$Params } from '../fn/customer/edit-customer-beneficiaries-async-plain';
import { getCustomerBeneficiariesAsync } from '../fn/customer/get-customer-beneficiaries-async';
import { GetCustomerBeneficiariesAsync$Params } from '../fn/customer/get-customer-beneficiaries-async';
import { getCustomerBeneficiariesAsync$Plain } from '../fn/customer/get-customer-beneficiaries-async-plain';
import { GetCustomerBeneficiariesAsync$Plain$Params } from '../fn/customer/get-customer-beneficiaries-async-plain';
import { searchCustomers } from '../fn/customer/search-customers';
import { SearchCustomers$Params } from '../fn/customer/search-customers';
import { searchCustomers$Plain } from '../fn/customer/search-customers-plain';
import { SearchCustomers$Plain$Params } from '../fn/customer/search-customers-plain';
import { updateStatusCustomerBankAsync } from '../fn/customer/update-status-customer-bank-async';
import { UpdateStatusCustomerBankAsync$Params } from '../fn/customer/update-status-customer-bank-async';
import { updateStatusCustomerBankAsync$Plain } from '../fn/customer/update-status-customer-bank-async-plain';
import { UpdateStatusCustomerBankAsync$Plain$Params } from '../fn/customer/update-status-customer-bank-async-plain';
import { uploadCustomerDocumentAsync } from '../fn/customer/upload-customer-document-async';
import { UploadCustomerDocumentAsync$Params } from '../fn/customer/upload-customer-document-async';
import { uploadCustomerDocumentAsync$Plain } from '../fn/customer/upload-customer-document-async-plain';
import { UploadCustomerDocumentAsync$Plain$Params } from '../fn/customer/upload-customer-document-async-plain';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchCustomers()` */
  static readonly SearchCustomersPath = '/api/customer';

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Plain$Response(params?: SearchCustomers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDtoSearchResponse>> {
    return searchCustomers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Plain(params?: SearchCustomers$Plain$Params, context?: HttpContext): Observable<CustomerDtoSearchResponse> {
    return this.searchCustomers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDtoSearchResponse>): CustomerDtoSearchResponse => r.body)
    );
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Response(params?: SearchCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDtoSearchResponse>> {
    return searchCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers(params?: SearchCustomers$Params, context?: HttpContext): Observable<CustomerDtoSearchResponse> {
    return this.searchCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDtoSearchResponse>): CustomerDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `uploadCustomerDocumentAsync()` */
  static readonly UploadCustomerDocumentAsyncPath = '/api/customer/{id}/document';

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCustomerDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Plain$Response(params: UploadCustomerDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadCustomerDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCustomerDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Plain(params: UploadCustomerDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadCustomerDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCustomerDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Response(params: UploadCustomerDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadCustomerDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCustomerDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync(params: UploadCustomerDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadCustomerDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `createBankAsync()` */
  static readonly CreateBankAsyncPath = '/api/customer/{id}/bank';

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Plain$Response(params: CreateBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return createBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Plain(params: CreateBankAsync$Plain$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.createBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Response(params: CreateBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return createBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync(params: CreateBankAsync$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.createBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editBankAsync()` */
  static readonly EditBankAsyncPath = '/api/customer/{id}/bank/{bankId}';

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editBankAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Plain$Response(params: EditBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return editBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editBankAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Plain(params: EditBankAsync$Plain$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.editBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editBankAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Response(params: EditBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return editBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editBankAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync(params: EditBankAsync$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.editBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteBankAsync()` */
  static readonly DeleteBankAsyncPath = '/api/customer/{id}/bank/{bankId}';

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Plain$Response(params: DeleteBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return deleteBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Plain(params: DeleteBankAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.deleteBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Response(params: DeleteBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return deleteBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync(params: DeleteBankAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.deleteBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `updateStatusCustomerBankAsync()` */
  static readonly UpdateStatusCustomerBankAsyncPath = '/api/customer/{id}/bank/{bankId}/status/{status}';

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStatusCustomerBankAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Plain$Response(params: UpdateStatusCustomerBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return updateStatusCustomerBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStatusCustomerBankAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Plain(params: UpdateStatusCustomerBankAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.updateStatusCustomerBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStatusCustomerBankAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Response(params: UpdateStatusCustomerBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return updateStatusCustomerBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStatusCustomerBankAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync(params: UpdateStatusCustomerBankAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.updateStatusCustomerBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `createTaxRegimeCustomerAsync()` */
  static readonly CreateTaxRegimeCustomerAsyncPath = '/api/customer/{id}/taxregime';

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaxRegimeCustomerAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Plain$Response(params: CreateTaxRegimeCustomerAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return createTaxRegimeCustomerAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTaxRegimeCustomerAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Plain(params: CreateTaxRegimeCustomerAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.createTaxRegimeCustomerAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaxRegimeCustomerAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Response(params: CreateTaxRegimeCustomerAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return createTaxRegimeCustomerAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTaxRegimeCustomerAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync(params: CreateTaxRegimeCustomerAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.createTaxRegimeCustomerAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `createCustomerBeneficiariesAsync()` */
  static readonly CreateCustomerBeneficiariesAsyncPath = '/api/customer/{id}/customer-beneficiaries';

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiariesAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Plain$Response(params: CreateCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiariesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiariesAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Plain(params: CreateCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiariesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiariesAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Response(params: CreateCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiariesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiariesAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync(params: CreateCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiariesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `getCustomerBeneficiariesAsync()` */
  static readonly GetCustomerBeneficiariesAsyncPath = '/api/customer/customer-beneficiaries/{customerBeneficiaryId}';

  /**
   * Get Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBeneficiariesAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiariesAsync$Plain$Response(params: GetCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return getCustomerBeneficiariesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBeneficiariesAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiariesAsync$Plain(params: GetCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.getCustomerBeneficiariesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Get Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBeneficiariesAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiariesAsync$Response(params: GetCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return getCustomerBeneficiariesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBeneficiariesAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiariesAsync(params: GetCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.getCustomerBeneficiariesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editCustomerBeneficiariesAsync()` */
  static readonly EditCustomerBeneficiariesAsyncPath = '/api/customer/{id}/customer-beneficiaries/{customerBeneficiaryId}';

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCustomerBeneficiariesAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiariesAsync$Plain$Response(params: EditCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return editCustomerBeneficiariesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editCustomerBeneficiariesAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiariesAsync$Plain(params: EditCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.editCustomerBeneficiariesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCustomerBeneficiariesAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiariesAsync$Response(params: EditCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return editCustomerBeneficiariesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editCustomerBeneficiariesAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiariesAsync(params: EditCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.editCustomerBeneficiariesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

}
