import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from '@app/core/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InterestType } from '@app/data/amn-api/models';
import { ContractService } from '@app/data/amn-api/services';
import { ForbiddenError } from '@app/data/models';
import { AmnSingleFileUploadComponent } from '@app/shared/components';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { Subscription, first } from 'rxjs';
import { transformDateToISO } from '@app/core/helpers/date.helper';

@Component({
  standalone: true,
  selector: 'app-contract-deposit-transaction-form',
  imports: [
    PrimeNgModule,
    SharedModule,
    AmnSingleFileUploadComponent,
    TranslateModule
  ],
  templateUrl: './contract-deposit-transaction-form.component.html',
  styleUrls: ['./contract-deposit-transaction-form.component.scss']
})
export class ContractDepositTransactionFormComponent implements OnInit {
  @Input() isEditing: boolean = false;
  @Input() contractId: number = 0;
  @Input() transactionId: number = 0;
  @Input() customerId: number = 0;

  @Output() cancelDeposit = new EventEmitter<void>();
  @Output() submitDeposit = new EventEmitter<void>();
  @Output() documentsUploaded = new EventEmitter<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile!: any;

  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
  @ViewChild('amnFileUploader') fileUploader!: AmnSingleFileUploadComponent;

  ObjectId?: string = '';
  typeOfInterest: InterestType[] = [];
  submitLabel: string = 'Common.Add';

  transactionForm!: FormGroup;

  customerSubscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  get isNew(): boolean {
    return this.transactionId === 0;
  }

  ngOnInit() {
    this.transactionForm = this.fb.group({
      amount: [0, [Validators.required, Validators.min(999)]],
      transferedDate: ['', Validators.required],
      file: ['']
    });

    const idParamContract = this.route.snapshot.paramMap.get('contractId');
    this.contractId = idParamContract ? +idParamContract : 0;

    this.setEditForm();
  }

  closeDialog(): void {
    this.cancelDeposit.emit();
  }

  onEditClick() {
    this.isEditing = !this.isEditing;
    this.setEditForm();
  }

  private setEditForm() {
    if (this.isEditing) {
      this.transactionForm.enable();
    } else {
      this.transactionForm.disable();
    }
  }

  onCancelClick() {
    this.fileUploader.clearFileUpload();
    this.cleanForm();
    this.selectedFile = null;
    this.cancelDeposit.emit();
  }

  public cleanForm() {
    this.transactionForm.reset({
      interestType: ''
    });
  }

  private resetForm() {
    this.isEditing = false;
    this.setEditForm();
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.transactionForm.get(formControl)?.hasError(validationName) &&
      this.transactionForm.get(formControl)?.touched
    );
  }

  onUpload(file: Blob) {
    if (file) {
      this.selectedFile = file;
      this.documentsUploaded.emit();
    }
  }

  CreateDepositTransaction() {
    const contractData = {
      ...this.transactionForm.value,
      contractId: this.contractId,
      amount: Number(this.transactionForm.value.amount),
      transferedDate: transformDateToISO(
        this.transactionForm.value.transferedDate
      ),
      file: this.selectedFile
    };
    const documentTransaction = {
      File: this.selectedFile
    };
    if (this.isNew) {
      if (this.transactionForm?.valid) {
        this.contractService
          .createDepositContractAsync({
            id: this.contractId,
            body: contractData
          })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.DepositAdded',
                'Alerts.DepositAddedCorrectly',
                true
              );
              this.resetForm();
              this.submitDeposit.emit();
              this.isEditing = true;
              this.transactionForm.enable();
              this.fileUploader.clearFileUpload();
              this.selectedFile = null;
            },
            error: (error: unknown) => {
              if (error instanceof ForbiddenError) {
                this.alertsService.addWarning('Error', error.message, false);
              } else if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                  // Conflict
                  try {
                    const errorDetail = JSON.parse(error.error?.detail);
                    let errorMessage = '';
                    for (const key in errorDetail) {
                      if (
                        Object.prototype.hasOwnProperty.call(errorDetail, key)
                      ) {
                        errorMessage +=
                          (errorMessage ? ' ' : '') + errorDetail[key];
                      }
                    }
                    this.alertsService.addWarning(
                      this.translateService.instant(error.error.title),
                      errorMessage ||
                        this.translateService.instant('Alerts.UnknownError'),
                      false
                    );
                  } catch (e) {
                    this.alertsService.addWarning(
                      error.error.title,
                      'Alerts.DepositError',
                      true
                    );
                  }
                }
              } else {
                console.error(
                  this.translateService.instant('Alerts.UnknownError'),
                  error
                );
              }
            }
          });
      }
    } else {
      this.contractService
        .uploadTransactionDocumentAsync({
          contractId: this.contractId,
          transactionId: this.transactionId,
          body: documentTransaction
        })
        .pipe(first())
        .subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.DocumentAdded',
              'Alerts.DocumentAddedDesc',
              true
            );
            this.submitDeposit.emit();
            this.fileUploader.clearFileUpload();
          },
          error: (error: unknown) => {
            if (error instanceof ForbiddenError) {
              this.alertsService.addWarning('Error', error.message, false);
            } else if (error instanceof HttpErrorResponse) {
              if (error.status === 400) {
                // Conflict
                try {
                  const errorDetail = JSON.parse(error.error?.detail);
                  let errorMessage = '';
                  for (const key in errorDetail) {
                    if (
                      Object.prototype.hasOwnProperty.call(errorDetail, key)
                    ) {
                      errorMessage +=
                        (errorMessage ? ' ' : '') + errorDetail[key];
                    }
                  }
                  this.alertsService.addWarning(
                    this.translateService.instant(
                      'Alerts.ErrorUploadingContract'
                    ),
                    errorMessage ||
                      this.translateService.instant('Alerts.UnknownError'),
                    false
                  );
                } catch (e) {
                  this.alertsService.addWarning(
                    'Alerts.ErrorUploadingContract',
                    'Alerts.UnknownError',
                    true
                  );
                }
              }
            } else {
              console.error(
                this.translateService.instant('Alerts.UnknownError'),
                error
              );
            }
          }
        });
    }
  }
}
