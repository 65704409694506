import { NgModule } from '@angular/core';
import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient
} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Msal
import { PublicClientApplication } from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';

// Shared
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { AuthenticationService, LanguageService } from '@core/services';
import { LoaderModule } from '@core/loader.module';
import { AuthenticationInterceptor } from '@core/interceptors';
import {
  auth,
  cache,
  guardConfig,
  interceptorConfig,
  system
} from '@core/constants';
import { AppLayoutModule } from '@layout/app.layout.module';
import { DataModule } from '@data/data.module';
import { environment } from '@env/environment';
import { ApiModule } from '@data/amn-api/api.module';
import { ApiKeyInterceptor } from '@app/core/interceptors/api-key.interceptor';
import localeEn from '@angular/common/locales/en';
import localeEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMx, 'es-MX');
registerLocaleData(localeEn, 'en-US');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    LoaderModule,
    AppLayoutModule,
    DataModule,
    ApiModule.forRoot({
      rootUrl: environment.api.url
    }),
    MsalModule.forRoot(
      new PublicClientApplication({ auth, cache, system }),
      guardConfig,
      interceptorConfig
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend]
      },
      isolate: true
    }),
    SharedModule.forRoot()
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthenticationService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  languages = ['es', 'en'];

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    this.translateService.addLangs(this.languages);

    // Define default language
    let language = 'es';

    const browserLang = window.navigator.language.substring(0, 2);
    const localLang = this.languageService.language;

    if (localLang != null && this.languages.includes(localLang))
      language = localLang;
    else if (this.languages.includes(browserLang)) language = browserLang;

    this.languageService.language = language;
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);

    registerLocaleData(localeEs, 'es');
  }
}

export function translateHttpLoaderFactory(
  httpBackend: HttpBackend
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    new HttpClient(httpBackend),
    'assets/i18n/',
    '.json'
  );
}
