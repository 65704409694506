import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { AddCustomerComponent } from '@app/modules/customers/components/add-customer/add-customer.component';
import { DropdownModule } from 'primeng/dropdown';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { AlertsService } from '@app/core/services/alerts.service';
import {
  FinancialAdvisorDto,
  FinancialAdvisorDtoSearchResponse
} from '@app/data/amn-api/models';
import { FinancialAdvisorService } from '@app/data/amn-api/services';
import { first } from 'rxjs';
import { ForbiddenError } from '../../../../data/models/errors';
import { FilterMatchMode } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedModule } from '../../../../shared/shared.module';
import { AvatarComponent } from '../../../../shared/components/avatar/avatar.component';

@Component({
  standalone: true,
  selector: 'app-financial-advisors',
  imports: [
    CommonModule,
    FormsModule,
    PrimeNgModule,
    TranslateModule,
    AddCustomerComponent,
    DropdownModule,
    SharedModule,
    AvatarComponent
  ],
  templateUrl: './financial-advisors.component.html',
  styleUrl: './financial-advisors.component.scss'
})
export class FinancialAdvisorsComponent implements OnInit {
  @ViewChild('dt') table!: Table;

  totalRecords!: number;
  financialAdvisors: FinancialAdvisorDto[] = [];
  financialAdvisorToEdit!: FinancialAdvisorDto;
  visible = false;
  random = Math.random();

  searchCriteria = '';
  top: number = 10;
  skip: number = 0;
  sortBy: string = '';
  sortDesc: boolean = false;

  constructor(
    private financialAdvisorService: FinancialAdvisorService,
    private alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.financialAdvisorService
      .searchFinancialAdvisors()
      .pipe(first())
      .subscribe({
        next: (response: FinancialAdvisorDtoSearchResponse) => {
          this.totalRecords = response.totalRecords ?? 0;
          this.financialAdvisors = response.data ?? [];
        }
      });
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteria =
        (event.globalFilter as string) ?? this.searchCriteria;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'financialAdvisorsFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteria,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        })
      );
      this.loadFinancialAdvisors();
    }
  }

  loadFinancialAdvisors(): void {
    this.financialAdvisorService
      .searchFinancialAdvisors({
        SearchText: this.searchCriteria,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc
      })
      .pipe(first())
      .subscribe({
        next: (value: FinancialAdvisorDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.FinancialAdvisorsNotFound',
              'Alerts.FinancialAdvisorsNotFoundDesc',
              true
            );
          } else {
            this.financialAdvisors = value.data;
            this.totalRecords = value.totalRecords ?? 0;
          }
        },
        error: (error: unknown) => {
          if (error instanceof ForbiddenError) {
            this.alertsService.addWarning('Error', error.message, false);
          } else if (error instanceof HttpErrorResponse) {
            console.warn('Error getting financial advisors: ', error);
          } else {
            console.error('Unknown error', error);
          }
        }
      });
  }

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsFinancialAdvisors(searchTerm);
    table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
  }

  getSearchTermsFinancialAdvisors(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteria = searchTerm;
      localStorage.setItem('searchTermsFinancialAdvisor', this.searchCriteria);
    } else {
      this.searchCriteria = '';
      localStorage.removeItem('searchTermsFinancialAdvisor');
    }
  }

  onAddFinancialAdvisor(): void {
    this.loadFinancialAdvisors();
  }

  showDialogToEdit(financialAdvisor: FinancialAdvisorDto) {
    this.random = Math.random();
    this.financialAdvisorToEdit = financialAdvisor;
    return (this.visible = true);
  }

  handleOnShowParentModal($event: boolean) {
    this.visible = $event;
  }
}
