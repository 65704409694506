/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cancelContractAsync } from '../fn/contract/cancel-contract-async';
import { CancelContractAsync$Params } from '../fn/contract/cancel-contract-async';
import { ContractDetailsDtoApiResponse } from '../models/contract-details-dto-api-response';
import { createDepositContractAsync } from '../fn/contract/create-deposit-contract-async';
import { CreateDepositContractAsync$Params } from '../fn/contract/create-deposit-contract-async';
import { createDepositContractAsync$Plain } from '../fn/contract/create-deposit-contract-async-plain';
import { CreateDepositContractAsync$Plain$Params } from '../fn/contract/create-deposit-contract-async-plain';
import { DocumentDtoListApiResponse } from '../models/document-dto-list-api-response';
import { downloadContractDraft } from '../fn/contract/download-contract-draft';
import { DownloadContractDraft$Params } from '../fn/contract/download-contract-draft';
import { editContractAsync } from '../fn/contract/edit-contract-async';
import { EditContractAsync$Params } from '../fn/contract/edit-contract-async';
import { editContractAsync$Plain } from '../fn/contract/edit-contract-async-plain';
import { EditContractAsync$Plain$Params } from '../fn/contract/edit-contract-async-plain';
import { getContractAsync } from '../fn/contract/get-contract-async';
import { GetContractAsync$Params } from '../fn/contract/get-contract-async';
import { getContractAsync$Plain } from '../fn/contract/get-contract-async-plain';
import { GetContractAsync$Plain$Params } from '../fn/contract/get-contract-async-plain';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { sendCustomerContractBalance } from '../fn/contract/send-customer-contract-balance';
import { SendCustomerContractBalance$Params } from '../fn/contract/send-customer-contract-balance';
import { sendCustomerContractBalance$Plain } from '../fn/contract/send-customer-contract-balance-plain';
import { SendCustomerContractBalance$Plain$Params } from '../fn/contract/send-customer-contract-balance-plain';
import { Success } from '../models/success';
import { uploadContractDocumentAsync } from '../fn/contract/upload-contract-document-async';
import { UploadContractDocumentAsync$Params } from '../fn/contract/upload-contract-document-async';
import { uploadContractDocumentAsync$Plain } from '../fn/contract/upload-contract-document-async-plain';
import { UploadContractDocumentAsync$Plain$Params } from '../fn/contract/upload-contract-document-async-plain';
import { uploadTransactionDocumentAsync } from '../fn/contract/upload-transaction-document-async';
import { UploadTransactionDocumentAsync$Params } from '../fn/contract/upload-transaction-document-async';
import { uploadTransactionDocumentAsync$Plain } from '../fn/contract/upload-transaction-document-async-plain';
import { UploadTransactionDocumentAsync$Plain$Params } from '../fn/contract/upload-transaction-document-async-plain';

@Injectable({ providedIn: 'root' })
export class ContractService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getContractAsync()` */
  static readonly GetContractAsyncPath = '/api/contract/{id}';

  /**
   * Get contract by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractAsync$Plain$Response(params: GetContractAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return getContractAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get contract by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractAsync$Plain(params: GetContractAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.getContractAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Get contract by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContractAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractAsync$Response(params: GetContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return getContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get contract by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContractAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContractAsync(params: GetContractAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.getContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editContractAsync()` */
  static readonly EditContractAsyncPath = '/api/contract/{id}';

  /**
   * Edit contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editContractAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editContractAsync$Plain$Response(params: EditContractAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return editContractAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editContractAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editContractAsync$Plain(params: EditContractAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.editContractAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Edit contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editContractAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editContractAsync$Response(params: EditContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return editContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editContractAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editContractAsync(params: EditContractAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.editContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `cancelContractAsync()` */
  static readonly CancelContractAsyncPath = '/api/contract/{id}';

  /**
   * Cancel contract by contract id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelContractAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelContractAsync$Response(params: CancelContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cancelContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancel contract by contract id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelContractAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelContractAsync(params: CancelContractAsync$Params, context?: HttpContext): Observable<void> {
    return this.cancelContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `uploadContractDocumentAsync()` */
  static readonly UploadContractDocumentAsyncPath = '/api/contract/{id}/document';

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadContractDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadContractDocumentAsync$Plain$Response(params: UploadContractDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadContractDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadContractDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadContractDocumentAsync$Plain(params: UploadContractDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadContractDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadContractDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadContractDocumentAsync$Response(params: UploadContractDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadContractDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadContractDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadContractDocumentAsync(params: UploadContractDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadContractDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `uploadTransactionDocumentAsync()` */
  static readonly UploadTransactionDocumentAsyncPath = '/api/contract/{contractId}/transaction/{transactionId}/document';

  /**
   * Upload transaction document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTransactionDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTransactionDocumentAsync$Plain$Response(params: UploadTransactionDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadTransactionDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload transaction document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadTransactionDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTransactionDocumentAsync$Plain(params: UploadTransactionDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadTransactionDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload transaction document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTransactionDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTransactionDocumentAsync$Response(params: UploadTransactionDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadTransactionDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload transaction document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadTransactionDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadTransactionDocumentAsync(params: UploadTransactionDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadTransactionDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `createDepositContractAsync()` */
  static readonly CreateDepositContractAsyncPath = '/api/contract/{id}/deposit';

  /**
   * Create Deposit Transaction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDepositContractAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDepositContractAsync$Plain$Response(params: CreateDepositContractAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createDepositContractAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Deposit Transaction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDepositContractAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDepositContractAsync$Plain(params: CreateDepositContractAsync$Plain$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createDepositContractAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /**
   * Create Deposit Transaction.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDepositContractAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDepositContractAsync$Response(params: CreateDepositContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createDepositContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Deposit Transaction.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDepositContractAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createDepositContractAsync(params: CreateDepositContractAsync$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createDepositContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /** Path part for operation `downloadContractDraft()` */
  static readonly DownloadContractDraftPath = '/api/contract/{id}/draft';

  /**
   * Get Draft Contract Document as PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadContractDraft()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadContractDraft$Response(params: DownloadContractDraft$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return downloadContractDraft(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Draft Contract Document as PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadContractDraft$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadContractDraft(params: DownloadContractDraft$Params, context?: HttpContext): Observable<Blob> {
    return this.downloadContractDraft$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `sendCustomerContractBalance()` */
  static readonly SendCustomerContractBalancePath = '/api/contract/{id}/email-balance';

  /**
   * Send Email Contract Balance Document as PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerContractBalance$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendCustomerContractBalance$Plain$Response(params: SendCustomerContractBalance$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendCustomerContractBalance$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Email Contract Balance Document as PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCustomerContractBalance$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendCustomerContractBalance$Plain(params: SendCustomerContractBalance$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendCustomerContractBalance$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send Email Contract Balance Document as PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerContractBalance()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendCustomerContractBalance$Response(params: SendCustomerContractBalance$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendCustomerContractBalance(this.http, this.rootUrl, params, context);
  }

  /**
   * Send Email Contract Balance Document as PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCustomerContractBalance$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendCustomerContractBalance(params: SendCustomerContractBalance$Params, context?: HttpContext): Observable<Success> {
    return this.sendCustomerContractBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

}
