import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { first, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private storageService: StorageService,
    private primengConfig: PrimeNGConfig,
    public translateService: TranslateService
  ) {}

  currentLang$ = new Subject<string>();

  setLanguage(lang: string) {
    this.currentLang$.next(lang);
  }

  getLanguage() {
    return this.currentLang$.asObservable();
  }

  get language(): string | null {
    return this.storageService.language;
  }

  set language(lang: string) {
    this.storageService.language = lang;
  }

  switchLang(lang: string): void {
    this.language = lang;
    this.setLanguage(lang);
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .pipe(first())
      .subscribe((res) => {
        this.primengConfig.setTranslation(res);
      });
  }
}
