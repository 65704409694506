<p-table
  #dt
  dataKey="id"
  styleClass="p-datatable-striped"
  [tableStyle]="{ 'min-width': '50rem' }"
  [value]="banks"
  [scrollable]="true"
  scrollHeight="400px">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 14%" pSortableColumn="bankName">
        {{ 'Banks.BankName' | translate
        }}<p-sortIcon field="bankName"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="accountNumber">
        {{ 'Banks.AccountNumber' | translate
        }}<p-sortIcon field="accountNumber"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="country">
        {{ 'Banks.Country' | translate
        }}<p-sortIcon field="country"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="currency">
        {{ 'Banks.Currency' | translate
        }}<p-sortIcon field="currency"></p-sortIcon>
      </th>
      <th style="width: 14%">{{ 'Banks.IsDefault' | translate }}</th>
      <th style="width: 14%">{{ 'Banks.Status' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-banks>
    <tr class="cursor-pointer">
      <td class="text-center" (click)="getCustomerBankData(banks)">
        {{ banks.bankName }}
      </td>
      <td (click)="getCustomerBankData(banks)">{{ banks.accountNumber }}</td>
      <td (click)="getCustomerBankData(banks)">{{ banks.country }}</td>
      <td (click)="getCustomerBankData(banks)">{{ banks.currency }}</td>
      @if (banks.isDefault) {
        <td (click)="getCustomerBankData(banks)">
          <i class="pi pi-check text-green-500"></i>
        </td>
      } @else {
        <td (click)="getCustomerBankData(banks)"></td>
      }
      @if (banks.status === 'ACTIVE') {
        <td>
          <i
            class="pi pi-check text-green-500"
            (click)="setCustomerBankDeactivate(banks)"
            role="presentation"></i>
        </td>
      } @else {
        <td>
          <i
            class="pi pi-times text-red-500"
            (click)="setCustomerBankActivate(banks)"
            role="presentation"></i>
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">{{ 'Banks.NotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
