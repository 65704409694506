import { Component, Injector, Input, forwardRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
  FormControl
} from '@angular/forms';
import { InputClass } from '@app/core/classes/input';

const INPUT_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AmnInputMoneyComponent),
  multi: true
};

@Component({
  selector: 'app-amn-input-money',
  templateUrl: './amn-input-money.component.html',
  styleUrls: ['./amn-input-money.component.scss'],
  providers: [INPUT_CONTROL_ACCESSOR]
})
export class AmnInputMoneyComponent
  extends InputClass
  implements ControlValueAccessor, OnInit
{
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() type: string = 'decimal';
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() placeholder: string = '';

  public ngControl!: NgControl;
  public isDisabled: boolean = false;

  constructor(private injector: Injector) {
    super();
  }

  ngOnInit() {
    Promise.resolve().then(() => {
      this.ngControl = this.injector.get(NgControl);
      this.ngControl.control?.setValidators([
        Validators.required,
        Validators.min(1),
        this.decimalValidator
      ]);
      this.ngControl.control?.updateValueAndValidity();
    });
  }

  decimalValidator(control: FormControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && !/^\d+(\.\d{1,2})?$/.test(value.toString())) {
      return { decimalInvalid: true };
    }
    return null;
  }

  onValueChanged() {
    this.updateValue(this.value);
  }

  hasError(errorCode: string): boolean | null {
    return (
      this.ngControl.enabled &&
      this.ngControl.hasError(errorCode) &&
      this.ngControl.dirty
    );
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
