import { environment } from '@env/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';
import {
  BrowserAuthOptions,
  BrowserCacheLocation,
  BrowserSystemOptions,
  CacheOptions,
  InteractionType,
  LogLevel
} from '@azure/msal-browser';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const amnScopes: string[] = [
  ...environment.azureAD.scopes.map(
    (scope) =>
      `https://${environment.azureAD.tenant}.onmicrosoft.com/${environment.azureAD.apiName}/${scope}`
  )
];

export const authScopes: string[] = [
  'openid',
  'email',
  'profile',
  ...amnScopes
];

export const auth: BrowserAuthOptions = {
  clientId: environment.azureAD.clientId, // Application (client) ID from the app registration
  authority: `https://${environment.azureAD.tenant}.b2clogin.com/${environment.azureAD.tenant}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
  knownAuthorities: [`${environment.azureAD.tenant}.b2clogin.com`], // array of domains that are known to be trusted
  redirectUri: environment.azureAD.redirectUri, // This is your redirect URI
  navigateToLoginRequestUrl: true
};

export const cache: CacheOptions = {
  cacheLocation: BrowserCacheLocation.LocalStorage,
  storeAuthStateInCookie: !isIE // Set to true for Internet Explorer 11
};

export const system: BrowserSystemOptions = {
  allowNativeBroker: false, // Disables WAM Broker
  loggerOptions: {
    logLevel: LogLevel.Info,
    piiLoggingEnabled: false
  }
};

export const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: authScopes
  }
};

export const interceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([[environment.api.url, amnScopes]])
};
