/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BooleanApiResponse } from '../models/boolean-api-response';
import { checkAdminAccessAsync } from '../fn/health-check/check-admin-access-async';
import { CheckAdminAccessAsync$Params } from '../fn/health-check/check-admin-access-async';
import { checkAdminAccessAsync$Plain } from '../fn/health-check/check-admin-access-async-plain';
import { CheckAdminAccessAsync$Plain$Params } from '../fn/health-check/check-admin-access-async-plain';
import { checkApiWorkingAsync } from '../fn/health-check/check-api-working-async';
import { CheckApiWorkingAsync$Params } from '../fn/health-check/check-api-working-async';
import { checkApiWorkingAsync$Plain } from '../fn/health-check/check-api-working-async-plain';
import { CheckApiWorkingAsync$Plain$Params } from '../fn/health-check/check-api-working-async-plain';
import { checkErrorAsync } from '../fn/health-check/check-error-async';
import { CheckErrorAsync$Params } from '../fn/health-check/check-error-async';
import { checkErrorAsync$Plain } from '../fn/health-check/check-error-async-plain';
import { CheckErrorAsync$Plain$Params } from '../fn/health-check/check-error-async-plain';
import { checkExceptionAsync } from '../fn/health-check/check-exception-async';
import { CheckExceptionAsync$Params } from '../fn/health-check/check-exception-async';
import { checkExceptionAsync$Plain } from '../fn/health-check/check-exception-async-plain';
import { CheckExceptionAsync$Plain$Params } from '../fn/health-check/check-exception-async-plain';
import { checkLiquidAsync } from '../fn/health-check/check-liquid-async';
import { CheckLiquidAsync$Params } from '../fn/health-check/check-liquid-async';
import { checkLiquidAsync$Plain } from '../fn/health-check/check-liquid-async-plain';
import { CheckLiquidAsync$Plain$Params } from '../fn/health-check/check-liquid-async-plain';
import { checkMasterAccessAsync } from '../fn/health-check/check-master-access-async';
import { CheckMasterAccessAsync$Params } from '../fn/health-check/check-master-access-async';
import { checkMasterAccessAsync$Plain } from '../fn/health-check/check-master-access-async-plain';
import { CheckMasterAccessAsync$Plain$Params } from '../fn/health-check/check-master-access-async-plain';
import { checkMyFxBookAsync } from '../fn/health-check/check-my-fx-book-async';
import { CheckMyFxBookAsync$Params } from '../fn/health-check/check-my-fx-book-async';
import { checkMyFxBookAsync$Plain } from '../fn/health-check/check-my-fx-book-async-plain';
import { CheckMyFxBookAsync$Plain$Params } from '../fn/health-check/check-my-fx-book-async-plain';
import { checkValidationsAsync } from '../fn/health-check/check-validations-async';
import { CheckValidationsAsync$Params } from '../fn/health-check/check-validations-async';
import { checkValidationsAsync$Plain } from '../fn/health-check/check-validations-async-plain';
import { CheckValidationsAsync$Plain$Params } from '../fn/health-check/check-validations-async-plain';
import { StringApiResponse } from '../models/string-api-response';
import { SuccessApiResponse } from '../models/success-api-response';

@Injectable({ providedIn: 'root' })
export class HealthCheckService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `checkApiWorkingAsync()` */
  static readonly CheckApiWorkingAsyncPath = '/api/health-check';

  /**
   * Check API working.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkApiWorkingAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiWorkingAsync$Plain$Response(params?: CheckApiWorkingAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StringApiResponse>> {
    return checkApiWorkingAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check API working.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkApiWorkingAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiWorkingAsync$Plain(params?: CheckApiWorkingAsync$Plain$Params, context?: HttpContext): Observable<StringApiResponse> {
    return this.checkApiWorkingAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringApiResponse>): StringApiResponse => r.body)
    );
  }

  /**
   * Check API working.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkApiWorkingAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiWorkingAsync$Response(params?: CheckApiWorkingAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<StringApiResponse>> {
    return checkApiWorkingAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check API working.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkApiWorkingAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkApiWorkingAsync(params?: CheckApiWorkingAsync$Params, context?: HttpContext): Observable<StringApiResponse> {
    return this.checkApiWorkingAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<StringApiResponse>): StringApiResponse => r.body)
    );
  }

  /** Path part for operation `checkAdminAccessAsync()` */
  static readonly CheckAdminAccessAsyncPath = '/api/health-check/admin-access';

  /**
   * Check Authorization against Admin policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Plain$Response(params?: CheckAdminAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization against Admin policy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Plain(params?: CheckAdminAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization against Admin policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAdminAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync$Response(params?: CheckAdminAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkAdminAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization against Admin policy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkAdminAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkAdminAccessAsync(params?: CheckAdminAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkAdminAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkMasterAccessAsync()` */
  static readonly CheckMasterAccessAsyncPath = '/api/health-check/master-access';

  /**
   * Check Authorization against Master policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMasterAccessAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Plain$Response(params?: CheckMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkMasterAccessAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization against Master policy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMasterAccessAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Plain(params?: CheckMasterAccessAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkMasterAccessAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check Authorization against Master policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMasterAccessAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync$Response(params?: CheckMasterAccessAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkMasterAccessAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Authorization against Master policy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMasterAccessAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMasterAccessAsync(params?: CheckMasterAccessAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkMasterAccessAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkExceptionAsync()` */
  static readonly CheckExceptionAsyncPath = '/api/health-check/exception';

  /**
   * Check domain exception response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkExceptionAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkExceptionAsync$Plain$Response(params?: CheckExceptionAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkExceptionAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check domain exception response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkExceptionAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkExceptionAsync$Plain(params?: CheckExceptionAsync$Plain$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkExceptionAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /**
   * Check domain exception response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkExceptionAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkExceptionAsync$Response(params?: CheckExceptionAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BooleanApiResponse>> {
    return checkExceptionAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check domain exception response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkExceptionAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkExceptionAsync(params?: CheckExceptionAsync$Params, context?: HttpContext): Observable<BooleanApiResponse> {
    return this.checkExceptionAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanApiResponse>): BooleanApiResponse => r.body)
    );
  }

  /** Path part for operation `checkLiquidAsync()` */
  static readonly CheckLiquidAsyncPath = '/api/health-check/liquid';

  /**
   * Check Liquid template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLiquidAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLiquidAsync$Plain$Response(params?: CheckLiquidAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkLiquidAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Liquid template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLiquidAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLiquidAsync$Plain(params?: CheckLiquidAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkLiquidAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Check Liquid template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLiquidAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLiquidAsync$Response(params?: CheckLiquidAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkLiquidAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Liquid template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLiquidAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLiquidAsync(params?: CheckLiquidAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkLiquidAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /** Path part for operation `checkErrorAsync()` */
  static readonly CheckErrorAsyncPath = '/api/health-check/error';

  /**
   * Check ErrorOr response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkErrorAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkErrorAsync$Plain$Response(params?: CheckErrorAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkErrorAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check ErrorOr response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkErrorAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkErrorAsync$Plain(params?: CheckErrorAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkErrorAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Check ErrorOr response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkErrorAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkErrorAsync$Response(params?: CheckErrorAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkErrorAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check ErrorOr response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkErrorAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkErrorAsync(params?: CheckErrorAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkErrorAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /** Path part for operation `checkValidationsAsync()` */
  static readonly CheckValidationsAsyncPath = '/api/health-check/validations';

  /**
   * Check Validations response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkValidationsAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidationsAsync$Plain$Response(params?: CheckValidationsAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkValidationsAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Validations response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkValidationsAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidationsAsync$Plain(params?: CheckValidationsAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkValidationsAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Check Validations response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkValidationsAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidationsAsync$Response(params?: CheckValidationsAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkValidationsAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check Validations response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkValidationsAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkValidationsAsync(params?: CheckValidationsAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkValidationsAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /** Path part for operation `checkMyFxBookAsync()` */
  static readonly CheckMyFxBookAsyncPath = '/api/health-check/my-fx-book';

  /**
   * Check MyFxBook session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMyFxBookAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMyFxBookAsync$Plain$Response(params?: CheckMyFxBookAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkMyFxBookAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Check MyFxBook session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMyFxBookAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMyFxBookAsync$Plain(params?: CheckMyFxBookAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkMyFxBookAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Check MyFxBook session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkMyFxBookAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMyFxBookAsync$Response(params?: CheckMyFxBookAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return checkMyFxBookAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Check MyFxBook session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkMyFxBookAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkMyFxBookAsync(params?: CheckMyFxBookAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.checkMyFxBookAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
