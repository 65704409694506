<p-table
  #dtTransactions
  dataKey="id"
  styleClass="p-datatable-striped"
  [tableStyle]="{ 'min-width': '100%', padding: 0 }"
  [value]="transactions"
  [scrollable]="true"
  scrollHeight="525px"
  [rows]="10">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 25%" pSortableColumn="createdDate">
        {{ 'Common.Date' | translate
        }}<p-sortIcon field="createdDate"></p-sortIcon>
      </th>
      <th style="width: 25%" pSortableColumn="amount">
        {{ 'Common.Amount' | translate
        }}<p-sortIcon field="amount"></p-sortIcon>
      </th>
      <th style="width: 20%">{{ 'Transactions.Type' | translate }}</th>
      <th style="width: 30%" class="text-center">
        {{ 'Transactions.File' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-transaction>
    <tr>
      <td>{{ transaction.createdDate | date: 'dd MMM yyyy hh:mm a' }}</td>
      <td>{{ transaction.amount | currency }}</td>
      <td>
        <span
          [class]="
            'transaction-badge type-' + transaction.transactionType | lowercase
          ">
          {{ 'Transactions.Types.' + transaction.transactionType | translate }}
        </span>
      </td>
      <td class="text-center">
        @if (transaction.document) {
          <p-button
            (click)="onDownloadDocument(transaction.document.id)"
            icon="pi pi-paperclip"
            [rounded]="true"
            [text]="true"
            severity="info">
          </p-button>
        }
        @if (
          allowedUploadTransactionTypes.includes(transaction.transactionType)
        ) {
          <app-add-transaction-document
            (uploadDocument)="onUploadDocumentTransaction()"
            [contractId]="transaction.contractId"
            [transactionId]="transaction.id"></app-add-transaction-document>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">{{ 'Transactions.NotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
