<p-button
  (click)="onAddClick()"
  icon="pi pi-upload"
  [rounded]="true"
  [text]="true"
  severity="info">
</p-button>
<p-dialog
  header="{{ 'Transactions.UploadFile' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '35rem' }"
  [draggable]="false"
  [resizable]="false">
  <app-contract-deposit-transaction-form
    [isEditing]="true"
    [contractId]="contractId"
    [transactionId]="transactionId"
    (submitDeposit)="onSubmit()"
    (cancelDeposit)="onCancelClick()"></app-contract-deposit-transaction-form>
</p-dialog>
