import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { transformDateToISO } from '@app/core/helpers/date.helper';
import { AlertsService } from '@app/core/services';
import { ExpenseService } from '@app/data/amn-api/services';
import { ForbiddenError } from '@app/data/models';
import { AmnSingleFileUploadComponent } from '@app/shared/components';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { Subscription, first } from 'rxjs';

@Component({
  selector: 'app-expenses-form',
  standalone: true,
  imports: [
    PrimeNgModule,
    SharedModule,
    AmnSingleFileUploadComponent,
    TranslateModule
  ],
  templateUrl: './expenses-form.component.html',
  styleUrl: './expenses-form.component.scss'
})
export class ExpensesFormComponent implements OnInit {
  @Input() isEditing: boolean = false;
  @Output() cancelExpense = new EventEmitter<void>();
  @Output() submitExpense = new EventEmitter<void>();
  @Output() documentsUploaded = new EventEmitter<void>();
  @Input() expenseId: number = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile!: any;

  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
  @ViewChild('amnFileUploader') fileUploader!: AmnSingleFileUploadComponent;

  ObjectId?: string = '';
  expenseCategory!: SelectItem[];
  currency!: SelectItem[];
  submitLabel: string = 'Common.Add';

  expenseForm!: FormGroup;

  customerSubscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private expenseService: ExpenseService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  get isNew(): boolean {
    return this.expenseId === 0;
  }

  ngOnInit() {
    this.expenseCategory = [
      { label: 'FOOD', value: 'FOOD' },
      { label: 'FISCAL', value: 'FISCAL' },
      { label: 'GENERAL', value: 'GENERAL' },
      { label: 'TRANSPORTATION', value: 'TRANSPORTATION' },
      { label: 'UTILITIES', value: 'UTILITIES' },
      { label: 'TECHNOLOGY', value: 'TECHNOLOGY' },
      { label: 'CONTRIBUTOR', value: 'CONTRIBUTOR' },
      { label: 'THIRDPARTY', value: 'THIRDPARTY' },
      { label: 'CLIENT', value: 'CLIENT' },
      { label: 'EXTRA', value: 'EXTRA' }
    ];

    this.currency = [
      { label: 'USD', value: 'USD' },
      { label: 'MXN', value: 'MXN' }
    ];

    this.expenseForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(100)]],
      amount: [0, [Validators.required, Validators.min(1)]],
      currency: ['', Validators.required],
      category: ['', Validators.required],
      date: ['', Validators.required],
      notes: ['', Validators.maxLength(250)],
      file: ['']
    });

    this.setEditForm();
  }

  closeDialog(): void {
    this.cancelExpense.emit();
  }

  onEditClick() {
    this.isEditing = !this.isEditing;
    this.setEditForm();
  }

  private setEditForm() {
    if (this.isEditing) {
      this.expenseForm.enable();
    } else {
      this.expenseForm.disable();
    }
  }

  onCancelClick() {
    this.fileUploader.clearFileUpload();
    this.cleanForm();
    this.selectedFile = null;
    this.cancelExpense.emit();
  }

  public cleanForm() {
    this.expenseForm.reset({
      interestType: ''
    });
  }

  private resetForm() {
    this.isEditing = false;
    this.setEditForm();
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.expenseForm.get(formControl)?.hasError(validationName) &&
      this.expenseForm.get(formControl)?.touched
    );
  }

  onUpload(file: Blob) {
    if (file) {
      this.selectedFile = file;
      this.documentsUploaded.emit();
    }
  }

  createExpense() {
    const expenseData = {
      ...this.expenseForm.value,
      description: this.expenseForm.value.description,
      amount: Number(this.expenseForm.value.amount),
      currency: this.expenseForm.value.currency,
      category: this.expenseForm.value.category,
      date: transformDateToISO(this.expenseForm.value.date),
      notes: this.expenseForm.value.notes,
      file: this.selectedFile
    };
    const expenseFile = {
      File: this.selectedFile
    };
    if (this.isNew) {
      if (this.expenseForm?.valid) {
        this.expenseService
          .createExpenseAsync({ body: expenseData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.ExpenseAdded',
                'Alerts.ExpenseAddedCorrectly',
                true
              );
              this.resetForm();
              this.submitExpense.emit();
              this.isEditing = true;
              this.expenseForm.enable();
              this.fileUploader.clearFileUpload();
              this.selectedFile = null;
            },
            error: (error: unknown) => {
              if (error instanceof ForbiddenError) {
                this.alertsService.addWarning('Error', error.message, false);
              } else if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                  // Conflict
                  try {
                    const errorDetail = JSON.parse(error.error?.detail);
                    let errorMessage = '';
                    for (const key in errorDetail) {
                      if (
                        Object.prototype.hasOwnProperty.call(errorDetail, key)
                      ) {
                        errorMessage +=
                          (errorMessage ? ' ' : '') + errorDetail[key];
                      }
                    }
                    this.alertsService.addWarning(
                      this.translateService.instant(error.error.title),
                      errorMessage ||
                        this.translateService.instant('Alerts.UnknownError'),
                      false
                    );
                  } catch (e) {
                    this.alertsService.addWarning(
                      error.error.title,
                      'Alerts.ErrorCreatingExpense',
                      true
                    );
                  }
                }
              } else {
                console.error(
                  this.translateService.instant('Alerts.UnknownError'),
                  error
                );
              }
            }
          });
      }
    } else {
      this.expenseService
        .uploadExpenseDocumentAsync({
          id: this.expenseId,
          body: expenseFile
        })
        .pipe(first())
        .subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.DocumentAdded',
              'Alerts.DocumentAddedDesc',
              true
            );
            this.submitExpense.emit();
            this.fileUploader.clearFileUpload();
          },
          error: (error: unknown) => {
            if (error instanceof ForbiddenError) {
              this.alertsService.addWarning('Error', error.message, false);
            } else if (error instanceof HttpErrorResponse) {
              if (error.status === 400) {
                // Conflict
                try {
                  const errorDetail = JSON.parse(error.error?.detail);
                  let errorMessage = '';
                  for (const key in errorDetail) {
                    if (
                      Object.prototype.hasOwnProperty.call(errorDetail, key)
                    ) {
                      errorMessage +=
                        (errorMessage ? ' ' : '') + errorDetail[key];
                    }
                  }
                  this.alertsService.addWarning(
                    this.translateService.instant(
                      'Alerts.ErrorUploadingContract'
                    ),
                    errorMessage ||
                      this.translateService.instant('Alerts.UnknownError'),
                    false
                  );
                } catch (e) {
                  this.alertsService.addWarning(
                    'Alerts.ErrorUploadingContract',
                    'Alerts.UnknownError',
                    true
                  );
                }
              }
            } else {
              console.error(
                this.translateService.instant('Alerts.UnknownError'),
                error
              );
            }
          }
        });
    }
  }
}
