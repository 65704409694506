import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractDetailsDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { LanguageService } from '@app/core/services';

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

interface IContractHistories {
  id: number;
  status: string;
  updatedDate: string;
}

@Component({
  selector: 'app-contract-document-timeline',
  standalone: true,
  imports: [CommonModule, PrimeNgModule, SharedModule, TranslateModule],
  templateUrl: './contract-document-timeline.component.html',
  styleUrls: ['./contract-document-timeline.component.scss']
})
export class ContractDocumentTimelineComponent implements OnInit, OnChanges {
  public languageService = inject(LanguageService);

  @Input() contractDetails!: ContractDetailsDto;

  events!: EventItem[];
  currentLang = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes['contractDetails'] &&
      !!changes['contractDetails'].currentValue
    ) {
      this.contractDetails = changes['contractDetails'].currentValue;
      this.setDataOnHistory(
        changes['contractDetails'].currentValue.contractHistories
      );
    }
  }

  ngOnInit() {
    this.currentLang = this.setLocaleDatePipe();

    this.languageService.getLanguage().subscribe({
      next: (lang: string) => (this.currentLang = this.setLocaleDatePipe(lang))
    });
  }

  setLocaleDatePipe(lang: string | null = null): string {
    if (!lang) {
      return localStorage.getItem('language') === 'es' ? 'es-MX' : 'en-US';
    } else {
      return lang === 'es' ? 'es-MX' : 'en-US';
    }
  }

  setDataOnHistory(contractHistories: IContractHistories[]) {
    if (contractHistories) {
      this.events = [];
      contractHistories.forEach((record: IContractHistories) => {
        const eventItems: EventItem = {
          status: record.status,
          date: record.updatedDate
        };
        this.events.push(eventItems);
      });
    }
  }
}
