/* tslint:disable */
/* eslint-disable */
export enum ContractStatus {
  New = 'NEW',
  Draft = 'DRAFT',
  Senttosign = 'SENTTOSIGN',
  Signpending = 'SIGNPENDING',
  Signed = 'SIGNED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}
