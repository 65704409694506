/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreatedApiResponse } from '../models/created-api-response';
import { createSettingAsync } from '../fn/settings/create-setting-async';
import { CreateSettingAsync$Params } from '../fn/settings/create-setting-async';
import { createSettingAsync$Plain } from '../fn/settings/create-setting-async-plain';
import { CreateSettingAsync$Plain$Params } from '../fn/settings/create-setting-async-plain';
import { deleteSettingAsync } from '../fn/settings/delete-setting-async';
import { DeleteSettingAsync$Params } from '../fn/settings/delete-setting-async';
import { deleteSettingAsync$Plain } from '../fn/settings/delete-setting-async-plain';
import { DeleteSettingAsync$Plain$Params } from '../fn/settings/delete-setting-async-plain';
import { editSettingAsync } from '../fn/settings/edit-setting-async';
import { EditSettingAsync$Params } from '../fn/settings/edit-setting-async';
import { editSettingAsync$Plain } from '../fn/settings/edit-setting-async-plain';
import { EditSettingAsync$Plain$Params } from '../fn/settings/edit-setting-async-plain';
import { getSettingAsync } from '../fn/settings/get-setting-async';
import { GetSettingAsync$Params } from '../fn/settings/get-setting-async';
import { getSettingAsync$Plain } from '../fn/settings/get-setting-async-plain';
import { GetSettingAsync$Plain$Params } from '../fn/settings/get-setting-async-plain';
import { searchSettings } from '../fn/settings/search-settings';
import { SearchSettings$Params } from '../fn/settings/search-settings';
import { searchSettings$Plain } from '../fn/settings/search-settings-plain';
import { SearchSettings$Plain$Params } from '../fn/settings/search-settings-plain';
import { SettingDtoApiResponse } from '../models/setting-dto-api-response';
import { SettingDtoSearchResponse } from '../models/setting-dto-search-response';
import { SuccessApiResponse } from '../models/success-api-response';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchSettings()` */
  static readonly SearchSettingsPath = '/api/settings';

  /**
   * Search Settings by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSettings$Plain$Response(params?: SearchSettings$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoSearchResponse>> {
    return searchSettings$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Settings by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSettings$Plain(params?: SearchSettings$Plain$Params, context?: HttpContext): Observable<SettingDtoSearchResponse> {
    return this.searchSettings$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoSearchResponse>): SettingDtoSearchResponse => r.body)
    );
  }

  /**
   * Search Settings by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSettings$Response(params?: SearchSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoSearchResponse>> {
    return searchSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Settings by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchSettings(params?: SearchSettings$Params, context?: HttpContext): Observable<SettingDtoSearchResponse> {
    return this.searchSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoSearchResponse>): SettingDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `createSettingAsync()` */
  static readonly CreateSettingAsyncPath = '/api/settings';

  /**
   * Create setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSettingAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSettingAsync$Plain$Response(params?: CreateSettingAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createSettingAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSettingAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSettingAsync$Plain(params?: CreateSettingAsync$Plain$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createSettingAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /**
   * Create setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSettingAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSettingAsync$Response(params?: CreateSettingAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createSettingAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSettingAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSettingAsync(params?: CreateSettingAsync$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createSettingAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /** Path part for operation `getSettingAsync()` */
  static readonly GetSettingAsyncPath = '/api/settings/{id}';

  /**
   * Get setting by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingAsync$Plain$Response(params: GetSettingAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoApiResponse>> {
    return getSettingAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get setting by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingAsync$Plain(params: GetSettingAsync$Plain$Params, context?: HttpContext): Observable<SettingDtoApiResponse> {
    return this.getSettingAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoApiResponse>): SettingDtoApiResponse => r.body)
    );
  }

  /**
   * Get setting by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingAsync$Response(params: GetSettingAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoApiResponse>> {
    return getSettingAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get setting by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSettingAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingAsync(params: GetSettingAsync$Params, context?: HttpContext): Observable<SettingDtoApiResponse> {
    return this.getSettingAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoApiResponse>): SettingDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editSettingAsync()` */
  static readonly EditSettingAsyncPath = '/api/settings/{id}';

  /**
   * Edit setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSettingAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editSettingAsync$Plain$Response(params: EditSettingAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoApiResponse>> {
    return editSettingAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editSettingAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editSettingAsync$Plain(params: EditSettingAsync$Plain$Params, context?: HttpContext): Observable<SettingDtoApiResponse> {
    return this.editSettingAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoApiResponse>): SettingDtoApiResponse => r.body)
    );
  }

  /**
   * Edit setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSettingAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editSettingAsync$Response(params: EditSettingAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDtoApiResponse>> {
    return editSettingAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editSettingAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editSettingAsync(params: EditSettingAsync$Params, context?: HttpContext): Observable<SettingDtoApiResponse> {
    return this.editSettingAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDtoApiResponse>): SettingDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteSettingAsync()` */
  static readonly DeleteSettingAsyncPath = '/api/settings/{id}';

  /**
   * Delete setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSettingAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSettingAsync$Plain$Response(params: DeleteSettingAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteSettingAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSettingAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSettingAsync$Plain(params: DeleteSettingAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteSettingAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Delete setting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSettingAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSettingAsync$Response(params: DeleteSettingAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteSettingAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete setting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSettingAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSettingAsync(params: DeleteSettingAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteSettingAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
