<form (ngSubmit)="onSubmit()" [formGroup]="userForm">
  <div class="grid formgrid p-fluid">
    <div class="field col-6">
      <app-amn-input
        id="name"
        label="{{ 'Common.Name' | translate }}"
        formControlName="name"
        [errorMessages]="{
          required: 'Name is required',
          maxlength: 'Name cannot be more than 100 characters'
        }">
      </app-amn-input>
    </div>
    <div class="field col-6">
      <app-amn-input
        id="lastName"
        label="{{ 'Customers.LastName' | translate }}"
        formControlName="lastName"
        [errorMessages]="{
          required: 'Last Name is required.',
          maxlength: 'Last name cannot be more than 100 characters'
        }">
      </app-amn-input>
    </div>
    <div class="field w-full flex align-items-center">
      <div
        class="col-12"
        [ngClass]="{
          'lg:col-9 md:col-9 sm:col-12': isToEdit
        }">
        <app-amn-input
          id="email"
          label="{{ 'Customers.Email' | translate }}"
          formControlName="email"
          [errorMessages]="{
            required: 'Email is required.',
            email: 'Email format is invalid',
            maxlength: 'Email cannot be more than 50 characters'
          }">
        </app-amn-input>
      </div>
      @if (isToEdit) {
        <div
          class="col-12 lg:col-3 md:col-3 sm:col-12 -ml-4 justify-content-start custom-margin">
          <p-toggleButton
            [(ngModel)]="hasB2CRecord"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="true"
            [onLabel]="'B2C User'"
            [offLabel]="'Not B2C User'"
            [onIcon]="'pi pi-lock'"
            [offIcon]="'pi pi-lock-open'"
            styleClass="w-12rem" />
        </div>
      }
    </div>
    <div class="field col-12 md:col-12">
      <label for="selectedRoles" class="font-medium text-900">{{
        'Users.RoleLabelTitle' | translate
      }}</label>
      <p-multiSelect
        [showHeader]="false"
        [options]="rolesArray"
        formControlName="selectedRoles"
        optionLabel="name"
        placeholder="{{ 'Users.RoleLabelTitle' | translate }}"
        display="chip">
      </p-multiSelect>
    </div>
    <div class="field col-12 md:col-12 flex flex-column">
      <label for="isActive" class="font-medium text-900">{{
        'Users.IsActive' | translate
      }}</label>
      <p-inputSwitch
        class="pl-0"
        inputId="isActive"
        formControlName="isActive"
        (onChange)="onIsActiveChange($event)"></p-inputSwitch>
    </div>
    <div class="w-full flex justify-content-end flex-wrap">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"></p-button>
      @if (!isToEdit) {
        <p-button
          type="submit"
          label="{{ 'Common.Save' | translate }}"
          icon="pi pi-check"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="!this.userForm.valid"></p-button>
      } @else {
        <p-button
          type="submit"
          label="{{ 'Common.Save' | translate }}"
          icon="pi pi-user-edit"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="!this.userForm.valid"></p-button>
      }
    </div>
  </div>
</form>
