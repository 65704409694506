/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-amn-files-upload',
  standalone: true,
  imports: [PrimeNgModule, TranslateModule, CommonModule],
  templateUrl: './amn-files-upload.component.html',
  styleUrl: './amn-files-upload.component.scss'
})
export class AmnFilesUploadComponent {
  selectedFiles: Array<any> = [];
  @Output() uploadingFiles = new EventEmitter<Array<Blob>>();

  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
  @ViewChild('fileUploader') fileUploader!: FileUpload;

  onFileSelected(event: FileSelectEvent) {
    this.selectedFiles = event.currentFiles;
  }

  onUpload() {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      const files: Blob[] = this.selectedFiles.map(
        (file: File) => file as Blob
      );
      this.uploadingFiles.emit(files);
    }
  }

  onImageMouseOver(file: Image) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'flex')
        : null;
    });
  }

  onImageMouseLeave(file: Image) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'none')
        : null;
    });
  }

  removeImage(event: Event, file: any) {
    event.stopPropagation();

    const index = this.fileUploader.files.indexOf(file);
    if (index > -1) {
      this.fileUploader.files.splice(index, 1);
    }

    this.selectedFiles = this.selectedFiles.filter((i) => i !== file);
  }

  public clearFileUpload() {
    if (this.fileUploader) {
      this.fileUploader.clear();
    }
    this.selectedFiles = [];
  }
}

interface Image {
  id: number;
  name: string;
  objectURL: string;
}
