<form
  (ngSubmit)="CreateDepositTransaction()"
  #customerForm="ngForm"
  [formGroup]="transactionForm">
  <div class="flex flex-column md:flex-row">
    @if (isNew) {
      <div
        class="w-full md:w-7 flex flex-column align-items-center justify-content-center gap-3 py-5">
        <div class="grid formgrid p-fluid">
          <div class="field col-12">
            <app-amn-input-money
              id="amountInvested"
              label="{{ 'Common.Amount' | translate }}"
              formControlName="amount"
              [errorMessages]="{
                required: 'Amount is required.',
                min: 'the amount must be great than 10000'
              }">
            </app-amn-input-money>
          </div>
          <div class="field col-12">
            <app-amn-input-date
              id="transferedDate"
              label="{{ 'Common.Date' | translate }}"
              formControlName="transferedDate"
              view="date"
              [errorMessages]="{ required: 'Transferred date is required.' }">
            </app-amn-input-date>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1">
        <p-divider layout="vertical" styleClass="hidden md:flex"></p-divider>
      </div>
      <div
        class="w-full md:w-4 flex align-items-center justify-content-center py-5">
        <div class="field mb-4 col-12">
          <app-amn-single-file-upload
            #amnFileUploader
            (uploadingFile)="onUpload($event)">
          </app-amn-single-file-upload>
        </div>
      </div>
    }
  </div>
  @if (!isNew) {
    <div class="field mb-4 col-12">
      <app-amn-single-file-upload
        #amnFileUploader
        (uploadingFile)="onUpload($event)">
      </app-amn-single-file-upload>
    </div>
  }
  <div class="w-full flex align-items-center justify-content-end flex-wrap">
    <p-button
      label="{{ 'Common.Cancel' | translate }}"
      icon="pi pi-times"
      class="w-auto mr-3 mt-3"
      [outlined]="true"
      severity="secondary"
      (click)="onCancelClick()"></p-button>
    @if (isNew) {
      <p-button
        type="submit"
        label="{{ submitLabel | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="
          !this.transactionForm.valid || !this.selectedFile
        "></p-button>
    }
    @if (!isNew) {
      <p-button
        type="submit"
        label="{{ submitLabel | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="!this.selectedFile"></p-button>
    }
  </div>
</form>
