<div class="field">
  <label for="{{ id }}" class="font-medium text-900">{{ label }}</label>
  <p-calendar
    [id]="id"
    [(ngModel)]="value"
    [dateFormat]="dateFormat"
    [showIcon]="true"
    [iconDisplay]="'input'"
    [showTime]="showTime"
    [showSeconds]="showTime"
    [disabled]="isDisabled"
    [view]="view"
    (onSelect)="onValueChanged()"
    appendTo="body"
    [class.ng-invalid]="
      !ngControl || !ngControl.control
        ? false
        : ngControl.control.enabled &&
          !ngControl.control.valid &&
          !ngControl.control.pristine
    ">
  </p-calendar>
  <div *ngFor="let error of ngControl?.errors | keyvalue">
    @if (hasError(error.key)) {
      <small [id]="id + '-help' + error" class="p-error">
        {{ errorMessages[error.key] }}
      </small>
    }
  </div>
</div>
