import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderService } from '@core/services/loader.service';
import { LoaderInterceptor } from '@core/interceptors';
import { LoaderComponent } from '@shared/components';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, SharedModule],
  exports: [LoaderComponent],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class LoaderModule {}
