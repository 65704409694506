import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app/core/services/loader.service';
import { environment } from '@env/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequest = 0;
  private urlsToNotUse: Array<string>;

  constructor(public loaderService: LoaderService) {
    this.urlsToNotUse = [
      // Insert here the Urls that does not require to have the loader.
    ];
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(req.url)) {
      this.totalRequest++;
      this.loaderService.show();

      return next.handle(req).pipe(
        finalize(() => {
          this.totalRequest--;
          if (this.totalRequest === 0) {
            this.loaderService.hide();
          }
        })
      );
    }
    return next.handle(req);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    const positionIndicator = environment.api.url; // This is the URL we are expecting to wait for during loading.
    const position = requestUrl.indexOf(positionIndicator);

    if (position > 0) {
      const destination: string = requestUrl.slice(
        position + positionIndicator.length
      );
      for (const address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) return false;
      }
    }
    return true;
  }
}
