/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import jwt_decode from 'jwt-decode';
import {
  AccountInfo,
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  SilentRequest,
  IdTokenClaims,
  EventMessage,
  EventType
} from '@azure/msal-browser';
import {
  Observable,
  Subject,
  filter,
  first,
  from,
  lastValueFrom,
  map,
  of,
  takeUntil
} from 'rxjs';
import { authScopes } from '@core/constants';
import { LanguageService, ProfileService } from '@core/services';
import { UserService } from '@app/data/amn-api/services';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _token: string | null = null;
  private msalTokenKeysString = `msal.token.keys.${environment.azureAD.clientId}`;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private profileService: ProfileService,
    private languageService: LanguageService,
    private userService: UserService
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((session: any) => {
        this.profileService.loadUser();
        const payload = {
          body: {
            browserInformation: navigator.userAgent,
            email: session.payload.account.idTokenClaims.email,
            ipAddress: 'NULL'
          }
        };
        this.userService
          .addUserLoginInformationAsync$Response(payload)
          .pipe(first())
          .subscribe({
            error: (error: unknown) => console.error(error)
          });
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.profileService.loadUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.profileService.clearUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.error(
          '🚀 ~ AuthenticationService ~ .subscribe ~ LOGIN_FAILURE'
        );

        // Handle cancel from B2C
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          window.location.replace(environment.azureAD.redirectUri);
        }

        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        /*
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.azureAD.policies.authorities.resetPassword.authority,
            scopes: [],
          };

          this.login(resetPasswordFlowRequest);
        };
        */
      });
  }

  public getToken(): string | null {
    if (this._token !== null) {
      return this._token;
    }

    const msalTokenKeys = this.validateLocalStorageItem(
      this.msalTokenKeysString
    );
    if (!msalTokenKeys?.idToken) {
      return null;
    }

    const idTokenValue = msalTokenKeys.idToken[0];
    const idToken = this.validateLocalStorageItem(idTokenValue);
    if (!idToken?.secret) {
      return null;
    }

    this._token = idToken.secret;
    return this._token;
  }

  public getTokenClaims(): IdTokenClaims | null {
    const token = this._token ?? this.getToken();
    if (token == null) return null;

    this._token = token;
    return jwt_decode(token);
  }

  private validateLocalStorageItem(itemName: string): any {
    const item = localStorage.getItem(itemName);
    if (item === null) return null;

    const itemObject = JSON.parse(item);
    return itemObject;
  }

  getAccountDataFromToken() {
    const getData = this.validateLocalStorageItem(this.msalTokenKeysString)[0];
    return this.validateLocalStorageItem(getData);
  }

  async handleRefreshToken() {
    const currentAccount: AccountInfo = this.getAccountDataFromToken();
    const silentRequest: SilentRequest = {
      scopes: authScopes,
      account: currentAccount,
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.Default
    };

    const request = {
      scopes: authScopes,
      loginHint: currentAccount.username // For v1 endpoints, use upn from idToken claims
    };

    return await lastValueFrom(
      this.msalService.acquireTokenSilent(silentRequest)
    )
      .then((value) => {
        this._token = value.idToken;
        return value;
      })
      .catch((error: InteractionRequiredAuthError) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.msalService.acquireTokenRedirect(request);
        }
        return error;
      });
  }

  public renewToken(): Observable<string | null> {
    const token = this.getToken();
    if (token === null) return of(null);

    if (!this.isTokenExpired(token)) return of(token);

    const dateFromToken = this.getDateFromToken(token, 'nbf');
    if (dateFromToken === null) return of(null);

    const refreshTokenUntil =
      dateFromToken.valueOf() + 1000 * 60 * environment.token.maxRefreshTime;
    if (Date.now() < refreshTokenUntil) {
      return this.acquireTokenSilent();
    }
    return of(null);
  }

  isTokenExpired(token: string): boolean {
    const date = this.getDateFromToken(token, 'exp');
    if (date === undefined || date === null) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getDateFromToken(token: string, dateClaim: string): Date | null {
    const decoded = jwt_decode(token) as { [key: string]: any };

    if (decoded[dateClaim] === undefined) {
      return null;
    }
    const date = new Date(0);
    const seconds = Number(decoded[dateClaim]);
    date.setUTCSeconds(seconds);
    return date;
  }

  acquireTokenSilent(): Observable<string | null> {
    const renewTokenRequest = {
      scopes: authScopes
    };
    return from(this.msalService.acquireTokenSilent(renewTokenRequest)).pipe(
      map(() => {
        return this.getToken();
      })
    );
  }

  public login(): void {
    this.msalService.loginRedirect({
      scopes: authScopes
    });
  }

  public logout(): void {
    const lang = this.languageService.language || 'es';
    localStorage.clear();
    this.languageService.language = lang;
    this.msalService.logout();
  }
}
