<form
  (ngSubmit)="updateOrInsert()"
  #customerForm="ngForm"
  [formGroup]="beneficiaryForm">
  <div class="grid formgrid p-fluid">
    <!-- Primera fila -->
    <div class="field col-12 md:col-6 lg:col-6">
      <app-amn-input
        id="name"
        label="{{ 'Common.Name' | translate }}"
        formControlName="name"
        [errorMessages]="{
          required: 'Beneficiaries.NameRequired' | translate
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-6 lg:col-6">
      <app-amn-input
        id="lastName"
        label="{{ 'Common.LastName' | translate }}"
        formControlName="lastName"
        [errorMessages]="{
          required: 'Beneficiaries.LastNameRequired' | translate
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-6 lg:col-6">
      <label for="RelationshipType" class="font-medium text-900">{{
        'Beneficiaries.Relationship' | translate
      }}</label>
      <p-dropdown
        id="RelationshipType"
        [options]="relationshipType"
        optionValue="value"
        optionLabel="label"
        formControlName="relationship"
        placeholder="{{ 'Beneficiaries.SelectRelationship' | translate }}"
        appendTo="body">
      </p-dropdown>
    </div>
    @if (this.isOtherRelationship) {
      <div class="field col-12 md:col-6 lg:col-6">
        <app-amn-input
          id="otherRelationship"
          label="{{ 'Beneficiaries.OtherRelationship' | translate }}"
          formControlName="otherRelationship"
          [errorMessages]="{
            required: 'Beneficiaries.OtherRelationshipRequired' | translate
          }">
        </app-amn-input>
      </div>
    }
    <div class="field col-12 md:col-6 lg:col-3">
      <app-amn-input-percent
        id="percentage"
        label="{{ 'Common.Percentage' | translate }}"
        formControlName="percentage"
        [errorMessages]="{
          required: 'Beneficiaries.PercentageRequired' | translate,
          max: 'Max 100',
          min: 'Min 0'
        }">
      </app-amn-input-percent>
    </div>
    <div class="field col-12 md:col-6 lg:col-3">
      <app-amn-input-checkbox
        id="isPrincipal"
        label="{{ 'Beneficiaries.IsPrincipal' | translate }}"
        formControlName="isPrincipal">
      </app-amn-input-checkbox>
    </div>

    <div class="flex align-items-center justify-content-end flex-wrap col-12">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"></p-button>
      @if (isNew) {
        <p-button
          type="submit"
          label="{{ 'Common.Add' | translate }}"
          icon="pi pi-check"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="!this.beneficiaryForm.valid"></p-button>
      } @else {
        <p-button
          type="submit"
          label="{{ 'Common.Save' | translate }}"
          icon="pi pi-check"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="!this.beneficiaryForm.valid"></p-button>
      }
    </div>
  </div>
</form>
