<div class="mb-4">
  <span class="text-900 font-bold text-xl ml-4">{{
    'Contracts.history' | translate
  }}</span>
</div>
<div class="text-center mb-4 flex justify-content-center align-items-start">
  <p-timeline [value]="events" class="w-full md:w-20rem mr-3">
    <ng-template pTemplate="content" let-event>
      <span class="text-sm">{{
        event.date | date: 'mediumDate' : '' : currentLang
      }}</span>
    </ng-template>
    <ng-template pTemplate="opposite" let-event>
      <span class="text-sm">{{
        'Contracts.StatusDescriptions.' + event.status | translate
      }}</span>
    </ng-template>
  </p-timeline>
</div>
