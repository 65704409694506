import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { AlertsService } from '@app/core/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { first } from 'rxjs';
import { ContractService } from '@app/data/amn-api/services';
import { AmnFilesUploadComponent } from '@app/shared/components';

@Component({
  selector: 'app-contract-document-upload',
  standalone: true,
  imports: [
    PrimeNgModule,
    SharedModule,
    TranslateModule,
    CommonModule,
    AmnFilesUploadComponent
  ],
  templateUrl: './contract-document-upload.component.html',
  styleUrls: ['./contract-document-upload.component.scss']
})
export class ContractDocumentUploadComponent {
  @Input() contractId: number = 0;
  @Output() documentsUploaded = new EventEmitter<void>();

  @ViewChild('amnFilesUploader') fileUploader!: AmnFilesUploadComponent;

  constructor(
    private contractService: ContractService,
    private alertsService: AlertsService
  ) {}

  onUpload(files: Blob[]) {
    if (files && files.length > 0) {
      this.contractService
        .uploadContractDocumentAsync({ id: this.contractId, body: { files } })
        .pipe(first())
        .subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.DocumentsUploaded',
              'Alerts.ContractDocumentsUploadedSuccessfully',
              true
            );
            this.documentsUploaded.emit();
            this.fileUploader.clearFileUpload();
          },
          error: () => {
            this.alertsService.addWarning(
              'Alerts.ErrorUploadingDocuments',
              'Alerts.ErrorUploadingDocumentsDesc',
              true
            );
          }
        });
    }
  }
}
