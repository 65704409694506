import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerBeneficiariesDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { SharedModule } from '@app/shared/shared.module';
import { CustomerBeneficiariesModalComponent } from '../customer-beneficiaries-modal/customer-beneficiaries-modal.component';

@Component({
  selector: 'app-customer-beneficiaries',
  standalone: true,
  imports: [
    PrimeNgModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    CustomerBeneficiariesModalComponent
  ],
  templateUrl: './customer-beneficiaries.component.html',
  styleUrl: './customer-beneficiaries.component.scss'
})
export class CustomerBeneficiariesComponent {
  @Input() beneficiaries!: CustomerBeneficiariesDto[];
  @Output() typeSelected = new EventEmitter<string>();
  @Output() editBeneficiary = new EventEmitter<void>();

  clear(table: Table) {
    table.clear();
  }

  onEditBeneficiaryClick(): void {
    this.editBeneficiary.emit();
  }
}
