import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UserDtoSearchResponse } from '@app/data/amn-api/models';
import { HealthCheckService, UserService } from '@app/data/amn-api/services';
import { ForbiddenError } from '@app/data/models';
import { AvatarComponent } from '@app/shared/components';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { AlertsService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-playground',
  imports: [TranslateModule, PrimeNgModule, AvatarComponent],
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit, OnDestroy {
  timeChanged = new EventEmitter<string>();
  unsubscribe$ = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private healthCheckService: HealthCheckService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.emitTime(); // Emit time immediately on initialization

    // Emit time every second
    setInterval(() => {
      this.emitTime();
    }, 1000);
  }

  showSuccess(): void {
    this.alertsService.addSuccess('Yeah!', 'Good job!', false);
  }

  showInfo(): void {
    this.alertsService.addInfo('Ok', 'Seems good', false);
  }

  showWarn(): void {
    this.alertsService.addWarning('Warning!', 'Wait... is it working?', false);
  }

  showError(): void {
    this.alertsService.addError(
      'Error',
      'Oops... Something goes wrong!',
      false
    );
  }

  checkApi(): void {
    this.healthCheckService
      .checkApiWorkingAsync()
      .pipe(first())
      .subscribe((res) => {
        this.alertsService.addSuccess(
          'Cool',
          res.data?.toString() ?? '',
          false
        );
      });
  }

  getUsers(): void {
    this.userService
      .searchUsers()
      .pipe(first())
      .subscribe({
        next: (value: UserDtoSearchResponse) => {
          console.table(value.data);
          this.alertsService.addSuccess(
            'Users',
            'Users searched correctly',
            false
          );
        },
        error: (error: unknown) => {
          if (error instanceof ForbiddenError) {
            this.alertsService.addWarning('Error', error.message, false);
          } else if (error instanceof HttpErrorResponse) {
            console.warn('Error getting users:', error);
          } else {
            console.error('Unknown error', error);
          }
        }
      });
  }

  emitTime(): void {
    const currentTime = new Date().toLocaleTimeString();
    this.timeChanged.emit(currentTime);
  }

  subscribe(): void {
    this.timeChanged.subscribe((e) => {
      console.log('🚀 ~ subscribe ~ e:', e);
    });
  }

  subscribeModule(): void {
    this.timeChanged.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
      console.log('🚀 ~ subscribeModule ~ e:', e);
    });
  }

  subscribeFirst(): void {
    this.timeChanged.pipe(first()).subscribe((e) => {
      console.log('🚀 ~ subscribeFirst ~ e:', e);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
