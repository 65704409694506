import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-avatar',
  imports: [CommonModule, PrimeNgModule],
  template: `
    <p-avatar
      [size]="size"
      [shape]="shape"
      [image]="image"
      [label]="hasImage ? undefined : (initial | slice: 0 : 1 | uppercase)"
      [style]="avatarStyles">
    </p-avatar>
  `
})
export class AvatarComponent implements OnInit, OnDestroy {
  private _displayName: string | Observable<string> = '';
  private subscription!: Subscription;
  public initial: string = '';
  avatarColor: string = '#000';
  avatarStyles: object = {};

  @Input() size: 'normal' | 'large' | 'xlarge' | undefined;
  @Input() shape: 'square' | 'circle' | undefined;
  @Input() image: string | undefined;

  @Input()
  set displayName(value: string | Observable<string>) {
    if (this.subscription) this.subscription.unsubscribe();

    if (value instanceof Observable) {
      this.subscription = value.subscribe((name) => {
        this.updateDisplayName(name);
      });
    } else {
      this.updateDisplayName(value);
    }
  }

  get displayName(): string | Observable<string> {
    return this._displayName;
  }

  get hasImage(): boolean {
    return (
      this.image !== undefined && this.image !== null && this.image.length > 0
    );
  }

  ngOnInit() {
    this.setAvatarStyles();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateDisplayName(name: string) {
    this.initial = name;
    this.avatarColor = this.getColorForLetter(name[0]);
    this.setAvatarStyles();
  }

  private setAvatarStyles() {
    if (this.hasImage) this.avatarStyles = {};
    else
      this.avatarStyles = {
        'background-color': this.avatarColor,
        width: this.getSize(),
        height: this.getSize(),
        'font-size': `calc(${this.getSize()} / 2)`, // Adjust font size based on the size of the avatar
        'line-height': this.getSize(),
        color: '#fff'
      };
  }

  // Get size in pixels according to size property
  private getSize(): string {
    switch (this.size) {
      case 'normal':
        return '2rem';
      case 'large':
        return '3rem';
      case 'xlarge':
        return '4rem';
      default:
        return '2rem';
    }
  }

  getColorForLetter(letter: string): string {
    const defaultColor = '#000';

    if (!letter) return defaultColor;

    const upperLetter = letter.toUpperCase();
    return this.colorMap[upperLetter] || defaultColor;
  }

  // Define a color map for Avatars
  private colorMap: { [letter: string]: string } = {
    A: '#e57373',
    B: '#f06292',
    C: '#ba68c8',
    D: '#9575cd',
    E: '#7986cb',
    F: '#64b5f6',
    G: '#4fc3f7',
    H: '#4dd0e1',
    I: '#4db6ac',
    J: '#81c784',
    K: '#aed581',
    L: '#ff8a65',
    M: '#d4e157',
    N: '#ffd54f',
    O: '#ffb74d',
    P: '#a1887f',
    Q: '#90a4ae',
    R: '#e0e0e0',
    S: '#616161',
    T: '#455a64',
    U: '#ff8a80',
    V: '#ff80ab',
    W: '#b388ff',
    X: '#8c9eff',
    Y: '#80d8ff',
    Z: '#84ffff'
  };
}
