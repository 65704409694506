/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { searchCustomersInvestments } from '../fn/report/search-customers-investments';
import { SearchCustomersInvestments$Params } from '../fn/report/search-customers-investments';
import { searchCustomersInvestments$Plain } from '../fn/report/search-customers-investments-plain';
import { SearchCustomersInvestments$Plain$Params } from '../fn/report/search-customers-investments-plain';
import { sendContractsFromInvestmentsCompanyToAdminAsync } from '../fn/report/send-contracts-from-investments-company-to-admin-async';
import { SendContractsFromInvestmentsCompanyToAdminAsync$Params } from '../fn/report/send-contracts-from-investments-company-to-admin-async';
import { sendContractsFromInvestmentsCompanyToAdminAsync$Plain } from '../fn/report/send-contracts-from-investments-company-to-admin-async-plain';
import { SendContractsFromInvestmentsCompanyToAdminAsync$Plain$Params } from '../fn/report/send-contracts-from-investments-company-to-admin-async-plain';
import { sendCustomerProfitEmailAsync } from '../fn/report/send-customer-profit-email-async';
import { SendCustomerProfitEmailAsync$Params } from '../fn/report/send-customer-profit-email-async';
import { sendCustomerProfitEmailAsync$Plain } from '../fn/report/send-customer-profit-email-async-plain';
import { SendCustomerProfitEmailAsync$Plain$Params } from '../fn/report/send-customer-profit-email-async-plain';
import { sendExpiringContractsToAdminAsync } from '../fn/report/send-expiring-contracts-to-admin-async';
import { SendExpiringContractsToAdminAsync$Params } from '../fn/report/send-expiring-contracts-to-admin-async';
import { sendExpiringContractsToAdminAsync$Plain } from '../fn/report/send-expiring-contracts-to-admin-async-plain';
import { SendExpiringContractsToAdminAsync$Plain$Params } from '../fn/report/send-expiring-contracts-to-admin-async-plain';
import { sendExpiringContractsToFinancialAdvisorsAsync } from '../fn/report/send-expiring-contracts-to-financial-advisors-async';
import { SendExpiringContractsToFinancialAdvisorsAsync$Params } from '../fn/report/send-expiring-contracts-to-financial-advisors-async';
import { sendExpiringContractsToFinancialAdvisorsAsync$Plain } from '../fn/report/send-expiring-contracts-to-financial-advisors-async-plain';
import { SendExpiringContractsToFinancialAdvisorsAsync$Plain$Params } from '../fn/report/send-expiring-contracts-to-financial-advisors-async-plain';
import { sendMonthlyDepositsAsync } from '../fn/report/send-monthly-deposits-async';
import { SendMonthlyDepositsAsync$Params } from '../fn/report/send-monthly-deposits-async';
import { sendMonthlyDepositsAsync$Plain } from '../fn/report/send-monthly-deposits-async-plain';
import { SendMonthlyDepositsAsync$Plain$Params } from '../fn/report/send-monthly-deposits-async-plain';
import { sendMonthlyTransactionsToAdminAsync } from '../fn/report/send-monthly-transactions-to-admin-async';
import { SendMonthlyTransactionsToAdminAsync$Params } from '../fn/report/send-monthly-transactions-to-admin-async';
import { sendMonthlyTransactionsToAdminAsync$Plain } from '../fn/report/send-monthly-transactions-to-admin-async-plain';
import { SendMonthlyTransactionsToAdminAsync$Plain$Params } from '../fn/report/send-monthly-transactions-to-admin-async-plain';
import { Success } from '../models/success';
import { TotalInvestmentsDtoApiResponse } from '../models/total-investments-dto-api-response';

@Injectable({ providedIn: 'root' })
export class ReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendMonthlyTransactionsToAdminAsync()` */
  static readonly SendMonthlyTransactionsToAdminAsyncPath = '/api/report/admin/monthly-transactions';

  /**
   * Send monthly transactions to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonthlyTransactionsToAdminAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyTransactionsToAdminAsync$Plain$Response(params?: SendMonthlyTransactionsToAdminAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendMonthlyTransactionsToAdminAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly transactions to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonthlyTransactionsToAdminAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyTransactionsToAdminAsync$Plain(params?: SendMonthlyTransactionsToAdminAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendMonthlyTransactionsToAdminAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send monthly transactions to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonthlyTransactionsToAdminAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyTransactionsToAdminAsync$Response(params?: SendMonthlyTransactionsToAdminAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendMonthlyTransactionsToAdminAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly transactions to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonthlyTransactionsToAdminAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyTransactionsToAdminAsync(params?: SendMonthlyTransactionsToAdminAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendMonthlyTransactionsToAdminAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `sendExpiringContractsToAdminAsync()` */
  static readonly SendExpiringContractsToAdminAsyncPath = '/api/report/admin/expiring-contracts';

  /**
   * Send expiring contracts to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendExpiringContractsToAdminAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToAdminAsync$Plain$Response(params?: SendExpiringContractsToAdminAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendExpiringContractsToAdminAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send expiring contracts to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendExpiringContractsToAdminAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToAdminAsync$Plain(params?: SendExpiringContractsToAdminAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendExpiringContractsToAdminAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send expiring contracts to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendExpiringContractsToAdminAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToAdminAsync$Response(params?: SendExpiringContractsToAdminAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendExpiringContractsToAdminAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send expiring contracts to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendExpiringContractsToAdminAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToAdminAsync(params?: SendExpiringContractsToAdminAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendExpiringContractsToAdminAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `sendExpiringContractsToFinancialAdvisorsAsync()` */
  static readonly SendExpiringContractsToFinancialAdvisorsAsyncPath = '/api/report/financialadvisors/expiring-contracts';

  /**
   * Send expiring contracts to financial advisors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendExpiringContractsToFinancialAdvisorsAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToFinancialAdvisorsAsync$Plain$Response(params?: SendExpiringContractsToFinancialAdvisorsAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendExpiringContractsToFinancialAdvisorsAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send expiring contracts to financial advisors.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendExpiringContractsToFinancialAdvisorsAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToFinancialAdvisorsAsync$Plain(params?: SendExpiringContractsToFinancialAdvisorsAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendExpiringContractsToFinancialAdvisorsAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send expiring contracts to financial advisors.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendExpiringContractsToFinancialAdvisorsAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToFinancialAdvisorsAsync$Response(params?: SendExpiringContractsToFinancialAdvisorsAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendExpiringContractsToFinancialAdvisorsAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send expiring contracts to financial advisors.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendExpiringContractsToFinancialAdvisorsAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendExpiringContractsToFinancialAdvisorsAsync(params?: SendExpiringContractsToFinancialAdvisorsAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendExpiringContractsToFinancialAdvisorsAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `sendCustomerProfitEmailAsync()` */
  static readonly SendCustomerProfitEmailAsyncPath = '/api/report/customer/profits';

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerProfitEmailAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCustomerProfitEmailAsync$Plain$Response(params?: SendCustomerProfitEmailAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendCustomerProfitEmailAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCustomerProfitEmailAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCustomerProfitEmailAsync$Plain(params?: SendCustomerProfitEmailAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendCustomerProfitEmailAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendCustomerProfitEmailAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCustomerProfitEmailAsync$Response(params?: SendCustomerProfitEmailAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendCustomerProfitEmailAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendCustomerProfitEmailAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendCustomerProfitEmailAsync(params?: SendCustomerProfitEmailAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendCustomerProfitEmailAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `sendContractsFromInvestmentsCompanyToAdminAsync()` */
  static readonly SendContractsFromInvestmentsCompanyToAdminAsyncPath = '/api/report/admin/contracts-from-investments-company';

  /**
   * Send payments monthly from investments to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendContractsFromInvestmentsCompanyToAdminAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendContractsFromInvestmentsCompanyToAdminAsync$Plain$Response(params?: SendContractsFromInvestmentsCompanyToAdminAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendContractsFromInvestmentsCompanyToAdminAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send payments monthly from investments to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendContractsFromInvestmentsCompanyToAdminAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendContractsFromInvestmentsCompanyToAdminAsync$Plain(params?: SendContractsFromInvestmentsCompanyToAdminAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendContractsFromInvestmentsCompanyToAdminAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send payments monthly from investments to admin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendContractsFromInvestmentsCompanyToAdminAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendContractsFromInvestmentsCompanyToAdminAsync$Response(params?: SendContractsFromInvestmentsCompanyToAdminAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendContractsFromInvestmentsCompanyToAdminAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send payments monthly from investments to admin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendContractsFromInvestmentsCompanyToAdminAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendContractsFromInvestmentsCompanyToAdminAsync(params?: SendContractsFromInvestmentsCompanyToAdminAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendContractsFromInvestmentsCompanyToAdminAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `sendMonthlyDepositsAsync()` */
  static readonly SendMonthlyDepositsAsyncPath = '/api/report/legal/monthly-deposits';

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonthlyDepositsAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyDepositsAsync$Plain$Response(params?: SendMonthlyDepositsAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendMonthlyDepositsAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonthlyDepositsAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyDepositsAsync$Plain(params?: SendMonthlyDepositsAsync$Plain$Params, context?: HttpContext): Observable<Success> {
    return this.sendMonthlyDepositsAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMonthlyDepositsAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyDepositsAsync$Response(params?: SendMonthlyDepositsAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Success>> {
    return sendMonthlyDepositsAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Send monthly profits to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendMonthlyDepositsAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendMonthlyDepositsAsync(params?: SendMonthlyDepositsAsync$Params, context?: HttpContext): Observable<Success> {
    return this.sendMonthlyDepositsAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Success>): Success => r.body)
    );
  }

  /** Path part for operation `searchCustomersInvestments()` */
  static readonly SearchCustomersInvestmentsPath = '/api/report/customers-investments';

  /**
   * Search all customers investments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomersInvestments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomersInvestments$Plain$Response(params?: SearchCustomersInvestments$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<TotalInvestmentsDtoApiResponse>> {
    return searchCustomersInvestments$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search all customers investments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomersInvestments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomersInvestments$Plain(params?: SearchCustomersInvestments$Plain$Params, context?: HttpContext): Observable<TotalInvestmentsDtoApiResponse> {
    return this.searchCustomersInvestments$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TotalInvestmentsDtoApiResponse>): TotalInvestmentsDtoApiResponse => r.body)
    );
  }

  /**
   * Search all customers investments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomersInvestments()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomersInvestments$Response(params?: SearchCustomersInvestments$Params, context?: HttpContext): Observable<StrictHttpResponse<TotalInvestmentsDtoApiResponse>> {
    return searchCustomersInvestments(this.http, this.rootUrl, params, context);
  }

  /**
   * Search all customers investments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomersInvestments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomersInvestments(params?: SearchCustomersInvestments$Params, context?: HttpContext): Observable<TotalInvestmentsDtoApiResponse> {
    return this.searchCustomersInvestments$Response(params, context).pipe(
      map((r: StrictHttpResponse<TotalInvestmentsDtoApiResponse>): TotalInvestmentsDtoApiResponse => r.body)
    );
  }

}
