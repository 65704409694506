import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ExpensesFormComponent } from '../expenses-form/expenses-form.component';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-add-expenses',
  standalone: true,
  imports: [PrimeNgModule, ExpensesFormComponent, TranslateModule],
  templateUrl: './modal-add-expenses.component.html',
  styleUrl: './modal-add-expenses.component.scss'
})
export class ModalAddExpensesComponent {
  visible: boolean = false;

  @Output() cancelExpense = new EventEmitter<void>();
  @Output() addExpense = new EventEmitter<void>();

  @ViewChild(ExpensesFormComponent) addExpenseForm!: ExpensesFormComponent;

  onAddClick(): void {
    this.visible = true;
    this.addExpenseForm.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelExpense.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.addExpense.emit();
  }
}
