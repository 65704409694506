<div class="field mt-5">
  <p-checkbox
    [(ngModel)]="value"
    [binary]="true"
    [inputId]="id"
    (onChange)="onValueChanged()"
    [disabled]="isDisabled"
    [class.ng-invalid]="
      !ngControl || !ngControl.control
        ? false
        : ngControl.control.enabled &&
          !ngControl.control.valid &&
          !ngControl.control.pristine
    ">
  </p-checkbox>
  <label for="{{ id }}" class="font-medium ml-3 text-900">{{ label }}</label>
  <div *ngFor="let error of ngControl?.errors | keyvalue">
    @if (hasError(error.key)) {
      <small [id]="id + '-help' + error" class="p-error">
        {{ errorMessages[error.key] }}
      </small>
    }
  </div>
</div>
