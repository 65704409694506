import { Component, Injector, Input, forwardRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { InputClass } from '@app/core/classes/input';

const INPUT_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AmnInputTextareaComponent),
  multi: true
};

@Component({
  selector: 'app-amn-input-textarea',
  templateUrl: './amn-input-textarea.component.html',
  styleUrls: ['./amn-input-textarea.component.scss'],
  providers: [INPUT_CONTROL_ACCESSOR]
})
export class AmnInputTextareaComponent
  extends InputClass
  implements ControlValueAccessor, OnInit
{
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() placeholder: string = '';
  @Input() pattern: string = '';

  public ngControl!: NgControl;
  public isDisabled: boolean = false;

  constructor(private injector: Injector) {
    super();
  }

  ngOnInit() {
    Promise.resolve().then(
      () => (this.ngControl = this.injector.get(NgControl))
    );
  }

  onValueChanged() {
    this.updateValue(this.value);
  }

  hasError(errorCode: string): boolean | null {
    return (
      this.ngControl.enabled &&
      this.ngControl.hasError(errorCode) &&
      this.ngControl.dirty
    );
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
