<form
  (ngSubmit)="updateOrInsert()"
  #customerForm="ngForm"
  [formGroup]="settingForm">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-4 lg:col-3">
      <app-amn-input
        id="name"
        label="{{ 'Common.Name' | translate }}"
        formControlName="name"
        pattern="^[A-Za-z]+$"
        [errorMessages]="{
          required: 'Name is required',
          pattern: 'Special characters or spaces are not allowed.'
        }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-4 lg:col-3">
      <app-amn-input
        id="description"
        label="{{ 'Common.Description' | translate }}"
        formControlName="description"
        [errorMessages]="{ required: 'Description is required' }">
      </app-amn-input>
    </div>
    <div class="field col-12 md:col-4 lg:col-3">
      <app-amn-input
        id="value"
        label="{{ 'Common.Value' | translate }}"
        formControlName="value"
        [errorMessages]="{ required: 'Value is required' }">
      </app-amn-input>
    </div>
    <div class="field col-6 md:col-4 lg:col-3">
      <label for="CategoryType" class="font-medium text-900">{{
        'Common.Category' | translate
      }}</label>
      <p-dropdown
        id="CategoryType"
        [options]="categoriesOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="category"
        placeholder="Select category"
        appendTo="body">
      </p-dropdown>
    </div>
    <div
      class="flex align-items-center justify-content-end flex-wrap col-10 md:col-4 lg:col-12">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"></p-button>
      <p-button
        type="submit"
        label="{{ submitLabel | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="!this.settingForm.valid"></p-button>
    </div>
  </div>
</form>
