<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<p-fileUpload
  #fileUploader
  name="demo[]"
  accept=".pdf,.png,.jpg,.jpeg"
  (onUpload)="onUpload()"
  [customUpload]="true"
  [multiple]="true"
  (onSelect)="onFileSelected($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [auto]="true"
  class="w-full"
  [maxFileSize]="1000000">
  <ng-template pTemplate="content">
    <div
      class="w-full py-3"
      (click)="fileUploader.advancedFileInput.nativeElement.click()">
      @if (!selectedFiles.length) {
        <div
          class="h-full flex flex-column justify-content-center align-items-center">
          <i class="pi pi-upload text-900 text-2xl mb-3"></i>
          <span class="font-bold text-900 text-xl mb-3">{{
            'Common.UploadFiles' | translate
          }}</span>
          <span class="font-medium text-600 text-md text-center">{{
            'Common.DropOrSelectFiles' | translate
          }}</span>
        </div>
      }
      @if (selectedFiles.length) {
        <div class="flex flex-wrap gap-5">
          <div
            *ngFor="let file of selectedFiles; let i = index"
            class="h-full relative w-7rem h-7rem border-3 border-transparent border-round hover:bg-primary transition-duration-100 cursor-auto p-1"
            (mouseenter)="onImageMouseOver(file)"
            (mouseleave)="onImageMouseLeave(file)">
            @if (file.objectURL) {
              <img
                [src]="file.objectURL"
                [alt]="file.name"
                title="file"
                class="w-full h-full border-round shadow-2" />
            } @else {
              <div class="w-full h-full">
                <i class="pi pi-file-pdf text-center p-1 text-8xl"></i>
                <span class="file-name">{{ file.name }}</span>
              </div>
            }
            <button
              [id]="file.name"
              #buttonEl
              pButton
              pRipple
              title="Remove file"
              type="button"
              icon="pi pi-times"
              class="delete-btn p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer"
              (click)="removeImage($event, file)"></button>
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-fileUpload>
<div class="w-full flex align-items-center justify-content-center flex-wrap">
  <p-button
    class="w-auto mt-3 mr-3"
    label="{{ 'Common.Upload' | translate }}"
    (click)="onUpload()"
    icon="pi pi-upload"
    [outlined]="true"
    severity="primary"
    [disabled]="!selectedFiles || selectedFiles.length === 0"></p-button>
</div>
