<p-button
  (click)="onAddClick()"
  label="{{ 'Common.AddNew' | translate }}"
  icon="pi pi-user-plus"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'Users.AddUser' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw', height: '500px' }"
  [draggable]="false"
  [resizable]="false">
  <app-add-user-form
    (showParentModal)="handleOnShowParentModal($event)"
    (addNewUser)="handleOnAddNewUser()"
    [isToEdit]="false"></app-add-user-form>
</p-dialog>
