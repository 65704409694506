import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContractDepositTransactionFormComponent } from '../contract-deposit-transaction-form/contract-deposit-transaction-form.component';

@Component({
  standalone: true,
  selector: 'app-add-deposit-contract',
  imports: [
    PrimeNgModule,
    ContractDepositTransactionFormComponent,
    TranslateModule
  ],
  templateUrl: './add-deposit-contract.component.html',
  styleUrls: ['./add-deposit-contract.component.scss']
})
export class AddDepositContractComponent {
  visible: boolean = false;

  @Output() cancelDeposit = new EventEmitter<void>();
  @Output() addDeposit = new EventEmitter<void>();

  @ViewChild(ContractDepositTransactionFormComponent)
  addDepositTransaction!: ContractDepositTransactionFormComponent;

  onAddClick(): void {
    this.visible = true;
    this.addDepositTransaction.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelDeposit.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.addDeposit.emit();
  }
}
