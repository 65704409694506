import { Component } from '@angular/core';
import { LanguageService } from '@app/core/services';
import { ButtonModule } from 'primeng/button';

@Component({
  standalone: true,
  selector: 'app-switch-language',
  imports: [ButtonModule],
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent {
  constructor(private languageService: LanguageService) {}

  isEs(): boolean {
    return this.languageService.language === 'es';
  }

  switchLang(lang: string) {
    this.languageService.switchLang(lang);
  }
}
