<div class="mb-4">
  <span class="text-900 font-bold text-xl ml-4">{{
    'Documents.Title' | translate
  }}</span>
</div>
<p-table
  styleClass="p-datatable-sm"
  [value]="documents ?? []"
  [tableStyle]="{ width: '100%' }">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'Common.Name' | translate }}</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td style="width: 60%">
        @if (rowData.extension === '.png' || rowData.extension === '.jpg') {
          <span class="pi pi-image mr-1"></span>
        }
        @if (rowData.extension === '.pdf') {
          <span class="pi pi-file-pdf mr-1"></span>
        }
        {{ rowData.fileName }}
      </td>
      <td style="width: 40%">
        <div>
          <p-button
            (click)="requestDeleteDocument(rowData.id, $event)"
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger">
          </p-button>
          <p-button
            (click)="onDownloadDocument(rowData.id)"
            icon="pi pi-download"
            [rounded]="true"
            [text]="true"
            severity="info">
          </p-button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="2">{{ 'Documents.NotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog></p-confirmDialog>
