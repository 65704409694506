<div class="field">
  <label for="{{ id }}" class="font-medium text-900">{{ label }}</label>
  <p-inputMask
    [id]="id"
    [mask]="mask"
    [(ngModel)]="value"
    (ngModelChange)="onValueChanged()"
    [placeholder]="placeholder"
    title="{{ label }}"
    [disabled]="isDisabled"
    [class.ng-invalid]="
      !ngControl || !ngControl.control
        ? false
        : ngControl.control.enabled &&
          !ngControl.control.valid &&
          !ngControl.control.pristine
    ">
  </p-inputMask>
  <div *ngFor="let error of ngControl?.errors | keyvalue">
    @if (hasError(error.key)) {
      <small [id]="id + '-help' + error" class="p-error">
        {{ errorMessages[error.key] }}
      </small>
    }
  </div>
</div>
