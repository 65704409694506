<div class="mb-3 flex justify-content-between align-items-center">
  <span class="text-900 font-bold text-xl">{{
    'TaxRegime.TitleSection' | translate
  }}</span>
  <app-add-tax-regimes
    [itemsToAdd]="taxRegimesByUser"
    (updatedRegimes)="handleGetUpdatedRegimes($event)"></app-add-tax-regimes>
</div>
<p-table [value]="taxRegimesByUser" [tableStyle]="{ width: '100%' }">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'Common.Name' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td>{{ rowData.name }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="empty">
    <tr>
      <td colspan="2">{{ 'TaxRegime.TableNoRecords' | translate }}/td></td>
    </tr>
  </ng-template>
</p-table>
