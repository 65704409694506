<p-button
  (click)="onAddClick()"
  label="{{ 'Deposit.AddDeposit' | translate }}"
  icon="pi pi-dollar"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'Deposit.AddDeposit' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-contract-deposit-transaction-form
    [isEditing]="true"
    (submitDeposit)="onSubmit()"
    (cancelDeposit)="onCancelClick()"></app-contract-deposit-transaction-form>
</p-dialog>
