<div class="grid">
  <div class="col-12">
    <div class="card">
      <h3>{{ 'Menu.Playground' | translate }}</h3>

      <div class="card flex flex-wrap gap-3 justify-content-center">
        <h3>{{ 'Playground.Alerts' | translate }}</h3>
        <p-button
          label="Success"
          severity="success"
          (onClick)="showSuccess()"></p-button>
        <p-button
          label="Information"
          severity="help"
          (onClick)="showInfo()"></p-button>
        <p-button
          label="Warning"
          severity="warning"
          (onClick)="showWarn()"></p-button>
        <p-button
          label="Error"
          severity="danger"
          (onClick)="showError()"></p-button>
      </div>
      <div class="card flex flex-wrap gap-3 justify-content-center">
        <h3>{{ 'Playground.HealthChecks' | translate }}</h3>
        <p-button
          label="Check Api"
          severity="info"
          (onClick)="checkApi()"></p-button>
        <p-button
          label="Get users"
          severity="secondary"
          (onClick)="getUsers()"></p-button>
      </div>

      <div class="card flex flex-wrap gap-3 justify-content-center">
        <h3>{{ 'Playground.Clocks' | translate }}</h3>
        <p-button
          label="Clock (first)"
          severity="success"
          (onClick)="subscribeFirst()"></p-button>
        <p-button
          label="Clock (module)"
          severity="warning"
          (onClick)="subscribeModule()"></p-button>
        <p-button
          label="Clock (all)"
          severity="danger"
          (onClick)="subscribe()"></p-button>
      </div>

      <div class="card flex flex-wrap gap-3 justify-content-center">
        <h2>{{ 'Playground.Forms' | translate }}</h2>
        <p-checkbox [binary]="true" inputId="binary"></p-checkbox>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText title="Test" />
        </span>
      </div>

      <div class="card flex flex-wrap gap-3 justify-content-center">
        <h2>Avatars</h2>
        <app-avatar displayName="Obi Wan" size="normal"></app-avatar>
        <app-avatar
          displayName="Darth Vader"
          size="xlarge"
          shape="circle"></app-avatar>

        <app-avatar
          displayName="Link"
          size="large"
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png"></app-avatar>

        <p-avatar label="J" size="xlarge"></p-avatar>
        <p-avatar
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png"
          size="xlarge"
          shape="circle" />
      </div>
    </div>
  </div>
</div>
