import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContractFormComponent } from '../contract-form/contract-form.component';

@Component({
  standalone: true,
  selector: 'app-add-contract',
  imports: [PrimeNgModule, ContractFormComponent, TranslateModule],
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent {
  visible: boolean = false;

  @Output() cancelContract = new EventEmitter<void>();
  @Output() addContract = new EventEmitter<void>();

  @ViewChild(ContractFormComponent)
  editCustomerComponent!: ContractFormComponent;

  onAddClick(): void {
    this.visible = true;
    this.editCustomerComponent.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelContract.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.addContract.emit();
  }
}
