<p-button
  (click)="onAddClick()"
  label="{{ 'Expenses.AddExpense' | translate }}"
  icon="pi pi-money-bill"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'Expenses.AddExpense' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '85rem' }"
  [draggable]="false"
  [resizable]="false">
  <app-expenses-form
    [isEditing]="true"
    (submitExpense)="onSubmit()"
    (cancelExpense)="onCancelClick()"></app-expenses-form>
</p-dialog>
