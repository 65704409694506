import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, first } from 'rxjs';
import { StorageService } from './storage.service';
import { UserService } from '@app/data/amn-api/services';
import {
  RoleDto,
  UserDto,
  UserDtoApiResponse,
  UserLogin
} from '@app/data/amn-api/models';
import {
  ROLE_ADMIN,
  ROLE_LEGAL,
  ROLE_MASTER,
  ROLE_FINANCIAL_ADVISOR,
  ROLE_USER
} from '@app/data/models';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userLoaded = new Subject<boolean>();
  private userSubject = new BehaviorSubject<UserDto | null>(null);
  user$ = this.userSubject.asObservable();
  userLogins: UserLogin[] = [];

  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) {}

  /* User */
  get user(): UserDto | null {
    return this.userSubject.value;
  }

  clearUser(): void {
    this.userSubject.next(null);
  }

  /* User ID */
  get userId(): number | null {
    return this.storageService.userId;
  }
  set userId(id: number) {
    this.storageService.userId = id;
  }

  /* Email */
  get email(): string | null {
    return this.storageService.userEmail;
  }
  set email(email: string) {
    this.storageService.userEmail = email;
  }

  /* Name */
  get name(): string | null {
    return this.storageService.userName;
  }
  set name(name: string) {
    this.storageService.userName = name;
  }

  /* Last Name */
  get lastName(): string | null {
    return this.storageService.userLastName;
  }
  set lastName(lastName: string) {
    this.storageService.userLastName = lastName;
  }

  get initials(): string {
    let initials = '';

    if (this.name != null) initials += this.name[0];

    if (this.lastName != null) initials += this.lastName[0];

    return initials === '' ? 'UN' : initials;
  }

  /* Full Name */
  get fullName(): string | null {
    return this.storageService.userFullName;
  }
  set fullName(fullName: string) {
    this.storageService.userFullName = fullName;
  }

  /* Roles */
  get roles(): Array<RoleDto> | null {
    return this.storageService.userRoles;
  }
  set roles(roles: Array<RoleDto> | null) {
    this.storageService.userRoles = roles;
  }

  get isAdmin(): boolean {
    return Boolean(this.roles?.some((role) => role.id === ROLE_ADMIN));
  }

  get isMaster(): boolean {
    return Boolean(this.roles?.some((role) => role.id === ROLE_MASTER));
  }

  get isUser(): boolean {
    return Boolean(this.roles?.some((role) => role.id === ROLE_USER));
  }

  get isLegal(): boolean {
    return Boolean(this.roles?.some((role) => role.id === ROLE_LEGAL));
  }

  get isFinancialAdvisor(): boolean {
    return Boolean(
      this.roles?.some((role) => role.id === ROLE_FINANCIAL_ADVISOR)
    );
  }

  public hasRole(requiredRoles: number[]): boolean {
    if (this.roles && this.roles.length === 0) {
      return false;
    }

    return requiredRoles.some((roleId) =>
      this.roles?.some((role) => role.id === roleId)
    );
  }

  public loadUser(): void {
    this.userService
      .getCurrentUserAsync()
      .pipe(first())
      .subscribe((res: UserDtoApiResponse) => {
        if (res.status === 200 && res.data !== undefined && res.data !== null) {
          const { id, name, lastName, fullName, email, roles, userLogins } =
            res.data;

          this.userId = id || 0;
          this.name = name || 'Unknown';
          this.lastName = lastName || '';
          this.fullName = fullName || 'Unknown';
          this.email = email || '';
          this.roles = roles || [];
          this.userLogins = userLogins || [];

          this.userSubject.next(res.data);
          this.userLoaded.next(true);
        }
        this.userLoaded.next(false);
      });
  }
}
