import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-amn-single-file-upload',
  standalone: true,
  imports: [PrimeNgModule, TranslateModule, CommonModule],
  templateUrl: './amn-single-file-upload.component.html',
  styleUrl: './amn-single-file-upload.component.scss'
})
export class AmnSingleFileUploadComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFile!: any;
  @Output() uploadingFile = new EventEmitter<Blob>();

  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;
  @ViewChild('fileUploader') fileUploader!: FileUpload;

  onFileSelected(event: FileSelectEvent) {
    this.selectedFile = event.currentFiles[0];
    if (this.selectedFile != null) {
      this.onUpload();
    }
  }

  onUpload() {
    if (this.selectedFile) {
      this.uploadingFile.emit(this.selectedFile);
    }
  }

  onImageMouseOver(file: Image) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'flex')
        : null;
    });
  }

  onImageMouseLeave(file: Image) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'none')
        : null;
    });
  }

  removeImage(event: Event) {
    event.stopPropagation();
    this.selectedFile = null;
    this.uploadingFile.emit(this.selectedFile);
  }

  public clearFileUpload() {
    if (this.fileUploader) {
      this.fileUploader.clear();
    }
    this.selectedFile = null;
  }
}

interface Image {
  id: number;
  name: string;
  objectURL: string;
}
