/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FinancialAdvisorDtoSearchResponse } from '../models/financial-advisor-dto-search-response';
import { searchFinancialAdvisors } from '../fn/financial-advisor/search-financial-advisors';
import { SearchFinancialAdvisors$Params } from '../fn/financial-advisor/search-financial-advisors';
import { searchFinancialAdvisors$Plain } from '../fn/financial-advisor/search-financial-advisors-plain';
import { SearchFinancialAdvisors$Plain$Params } from '../fn/financial-advisor/search-financial-advisors-plain';

@Injectable({ providedIn: 'root' })
export class FinancialAdvisorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchFinancialAdvisors()` */
  static readonly SearchFinancialAdvisorsPath = '/api/financial-advisor';

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFinancialAdvisors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Plain$Response(params?: SearchFinancialAdvisors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDtoSearchResponse>> {
    return searchFinancialAdvisors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchFinancialAdvisors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Plain(params?: SearchFinancialAdvisors$Plain$Params, context?: HttpContext): Observable<FinancialAdvisorDtoSearchResponse> {
    return this.searchFinancialAdvisors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDtoSearchResponse>): FinancialAdvisorDtoSearchResponse => r.body)
    );
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFinancialAdvisors()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Response(params?: SearchFinancialAdvisors$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDtoSearchResponse>> {
    return searchFinancialAdvisors(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchFinancialAdvisors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors(params?: SearchFinancialAdvisors$Params, context?: HttpContext): Observable<FinancialAdvisorDtoSearchResponse> {
    return this.searchFinancialAdvisors$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDtoSearchResponse>): FinancialAdvisorDtoSearchResponse => r.body)
    );
  }

}
