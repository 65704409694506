import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '@app/core/services';
import { ExpenseDto, ExpenseDtoApiResponse } from '@app/data/amn-api/models';
import { ExpenseService } from '@app/data/amn-api/services';
import { ForbiddenError } from '@app/data/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { transformDateToISO } from '@app/core/helpers/date.helper';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-edit-expense-form',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, TranslateModule],
  templateUrl: './edit-expense-form.component.html',
  styleUrl: './edit-expense-form.component.scss'
})
export class EditExpenseFormComponent {
  @Output() cancelExpense = new EventEmitter<void>();
  @Output() submitExpense = new EventEmitter<void>();
  @Input() expenseId: number = 0;
  expenseDetails!: ExpenseDto;
  visible = false;
  expenseCategory: SelectItem[] = [
    { label: 'FOOD', value: 'FOOD' },
    { label: 'FISCAL', value: 'FISCAL' },
    { label: 'GENERAL', value: 'GENERAL' },
    { label: 'TRANSPORTATION', value: 'TRANSPORTATION' },
    { label: 'UTILITIES', value: 'UTILITIES' },
    { label: 'TECHNOLOGY', value: 'TECHNOLOGY' },
    { label: 'CONTRIBUTOR', value: 'CONTRIBUTOR' },
    { label: 'THIRDPARTY', value: 'THIRDPARTY' },
    { label: 'CLIENT', value: 'CLIENT' },
    { label: 'EXTRA', value: 'EXTRA' }
  ];

  currency: SelectItem[] = [
    { label: 'USD', value: 'USD' },
    { label: 'MXN', value: 'MXN' }
  ];

  ObjectId?: string = '';
  submitLabel: string = 'Common.Save';
  private initialFormValues: ExpenseDto = {};

  expenseForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private expenseService: ExpenseService,
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {
    this.initializeForm();
  }

  get isNew(): boolean {
    return this.expenseId === 0;
  }

  initializeForm() {
    this.expenseForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(100)]],
      amount: [0, [Validators.required, Validators.min(1)]],
      currency: ['', Validators.required],
      category: ['', Validators.required],
      date: ['', Validators.required],
      notes: ['', Validators.maxLength(250)]
    });
  }

  public loadExpenseDetails(expenseId: number): void {
    setTimeout(() => {
      this.expenseService
        .getExpenseAsync({ id: expenseId })
        .pipe(first())
        .subscribe({
          next: (value: ExpenseDtoApiResponse) => {
            if (value && value.data) {
              this.expenseDetails = value.data;
            }
            this.setInitialFormValues();
          },
          error: (error: unknown) => {
            if (error instanceof ForbiddenError) {
              this.alertsService.addWarning('Error', error.message, false);
            } else if (error instanceof HttpErrorResponse) {
              console.warn('Error getting setting: ', error);
            } else {
              console.error('Unknown error', error);
            }
          }
        });
    }, 100);
  }

  private setInitialFormValues() {
    this.expenseForm.setValue({
      description: this.expenseDetails.description,
      amount: this.expenseDetails.amount,
      currency: this.expenseDetails.currency,
      category: this.expenseDetails.category,
      date: this.expenseDetails.date
        ? new Date(this.expenseDetails.date)
        : null,
      notes: this.expenseDetails.notes
    });
    this.initialFormValues = this.expenseForm.value;
  }

  onCancelClick() {
    if (this.initialFormValues) {
      this.expenseForm.setValue(this.initialFormValues);
    }
    this.cancelExpense.emit();
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.expenseForm.get(formControl)?.hasError(validationName) &&
      this.expenseForm.get(formControl)?.touched
    );
  }

  updateExpense() {
    if (this.expenseForm?.valid) {
      const expenseData = {
        ...this.expenseForm.value,
        description: this.expenseForm.value.description,
        amount: this.expenseForm.value.amount,
        currency: this.expenseForm.value.currency,
        category: this.expenseForm.value.category,
        date: transformDateToISO(this.expenseForm.value.date),
        notes: this.expenseForm.value.notes
      };
      this.expenseService
        .editExpenseAsync({ id: this.expenseId, body: expenseData })
        .pipe(first())
        .subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.ExpenseEdited',
              'Alerts.ExpenseEditedCorrectly',
              true
            );
            this.submitExpense.emit();
          },
          error: (error: unknown) => {
            if (error instanceof ForbiddenError) {
              this.alertsService.addWarning('Error', error.message, false);
            } else if (error instanceof HttpErrorResponse) {
              if (error.status === 400) {
                // Conflict
                try {
                  const errorDetail = JSON.parse(error.error?.detail);
                  let errorMessage = '';
                  for (const key in errorDetail) {
                    if (
                      Object.prototype.hasOwnProperty.call(errorDetail, key)
                    ) {
                      errorMessage +=
                        (errorMessage ? ' ' : '') + errorDetail[key];
                    }
                  }
                  this.alertsService.addWarning(
                    this.translateService.instant('Alerts.ErrorEditingExpense'),
                    errorMessage ||
                      this.translateService.instant('Alerts.UnknownError'),
                    false
                  );
                } catch (e) {
                  this.alertsService.addWarning(
                    'Alerts.ErrorEditingExpense',
                    'Alerts.UnknownError',
                    true
                  );
                }
              }
            } else {
              console.error(
                this.translateService.instant('Alerts.UnknownError'),
                error
              );
            }
          }
        });
    }
  }
}
