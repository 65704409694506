/* tslint:disable */
/* eslint-disable */
export enum ExpenseCategory {
  Food = 'FOOD',
  Fiscal = 'FISCAL',
  General = 'GENERAL',
  Transportation = 'TRANSPORTATION',
  Utilities = 'UTILITIES',
  Technology = 'TECHNOLOGY',
  Contributor = 'CONTRIBUTOR',
  Thirdparty = 'THIRDPARTY',
  Client = 'CLIENT',
  Extra = 'EXTRA'
}
