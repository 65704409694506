<p-table
  #dt
  dataKey="id"
  styleClass="p-datatable-striped"
  [tableStyle]="{ 'min-width': '50rem' }"
  [value]="beneficiaries"
  [scrollable]="true"
  scrollHeight="400px">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 40%" pSortableColumn="name">
        {{ 'Beneficiaries.Beneficiary' | translate
        }}<p-sortIcon field="name"></p-sortIcon>
      </th>
      <th style="width: 15%">
        {{ 'Beneficiaries.Relationship' | translate }}
      </th>
      <th style="width: 15%" pSortableColumn="percentage">
        {{ 'Common.Percentage' | translate
        }}<p-sortIcon field="percentage"></p-sortIcon>
      </th>
      <th class="text-center" style="width: 20%" pSortableColumn="isPrincipal">
        {{ 'Beneficiaries.IsPrincipal' | translate
        }}<p-sortIcon field="isPrincipal"></p-sortIcon>
      </th>
      <th style="width: 10%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-beneficiary>
    <tr>
      <td>{{ beneficiary.name }} {{ beneficiary.lastName }}</td>
      <td>
        @if (beneficiary.relationship === 'OTHER') {
          <span>{{ beneficiary.otherRelationship }}</span>
        } @else {
          <span>{{
            'Beneficiaries.RelationshipTypes.' + beneficiary.relationship
              | translate
          }}</span>
        }
      </td>
      <td class="text-center">{{ beneficiary.percentage | percent }}</td>
      @if (beneficiary.isPrincipal) {
        <td class="text-center">
          <i class="pi pi-star-fill" style="color: orange"></i>
        </td>
      } @else {
        <td class="text-center"></td>
      }
      <td>
        <app-customer-beneficiaries-modal
          (submitBeneficiary)="onEditBeneficiaryClick()"
          [beneficiaryId]="beneficiary.id"
          [customerId]="beneficiary.userId"></app-customer-beneficiaries-modal>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">{{ 'Beneficiaries.NotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
