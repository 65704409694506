import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ProfileService } from '@app/core/services';

export const RoleGuard: CanActivateFn = (route) => {
  const profileService = inject(ProfileService);
  const router = inject(Router);
  const allowedRoles = route.data?.['allowedRoles'] as number[];

  if (!profileService.hasRole(allowedRoles)) {
    router.navigate(['/dashboard']);
    return false;
  }

  return true;
};
