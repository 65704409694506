import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const modules = [CommonModule];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules]
})
export class DataModule {}
