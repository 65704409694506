/* tslint:disable */
/* eslint-disable */
export enum TransactionType {
  Initial = 'INITIAL',
  Deposit = 'DEPOSIT',
  Profit = 'PROFIT',
  Withdrawal = 'WITHDRAWAL',
  Settlement = 'SETTLEMENT',
  Reinvestment = 'REINVESTMENT',
  Refund = 'REFUND',
  Financialadvisorcommission = 'FINANCIALADVISORCOMMISSION',
  Supervisorcommission = 'SUPERVISORCOMMISSION',
  Penalty = 'PENALTY'
}
