<p-table
  #dt
  dataKey="id"
  styleClass="p-datatable-striped"
  [tableStyle]="{ 'min-width': '50rem' }"
  [value]="contracts"
  [scrollable]="true"
  scrollHeight="400px">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 14%" pSortableColumn="contractNumber">
        {{ 'Contracts.ContractNo' | translate
        }}<p-sortIcon field="contractNumber"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="interestRate">
        {{ 'Contracts.Percentage' | translate
        }}<p-sortIcon field="interestRate"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="amount">
        {{ 'Common.Amount' | translate
        }}<p-sortIcon field="amount"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="startDate">
        {{ 'Contracts.InitialDate' | translate
        }}<p-sortIcon field="startDate"></p-sortIcon>
      </th>
      <th style="width: 14%" pSortableColumn="endDate">
        {{ 'Contracts.FinalDate' | translate
        }}<p-sortIcon field="endDate"></p-sortIcon>
      </th>
      <th style="width: 14%">{{ 'Contracts.Status' | translate }}</th>
      <th style="width: 16%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contract>
    <tr>
      <td>{{ contract.contractNumber }}</td>
      <td class="text-center">{{ contract.interestRate | percent }}</td>
      <td>{{ contract.amount | currency }} {{ contract.currency }}</td>
      <td>{{ contract.startDate | date: 'dd MMM yyyy' }}</td>
      <td>{{ contract.endDate | date: 'dd MMM yyyy' }}</td>
      <td>
        <span [class]="'contract-badge status-' + contract.status | lowercase">
          {{
            'Contracts.StatusDescriptions.' + contract.status | translate
          }}</span
        >
      </td>
      <td class="text-center">
        <a [routerLink]="['contract', contract.id]">{{
          'Common.Details' | translate
        }}</a>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">{{ 'Contracts.NotFound' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
