import { RoleDto } from '@data/amn-api/models';

export const ROLE_MASTER = 1;
export const ROLE_ADMIN = 2;
export const ROLE_USER = 3;
export const ROLE_FINANCIAL_ADVISOR = 4;
export const ROLE_LEGAL = 5;

export const roles: RoleDto[] = [
  { id: ROLE_MASTER, description: 'Master' },
  { id: ROLE_ADMIN, description: 'Admin' },
  { id: ROLE_USER, description: 'User' },
  { id: ROLE_FINANCIAL_ADVISOR, description: 'FinancialAdvisor' },
  { id: ROLE_LEGAL, description: 'Legal' }
];

/*
  Badge severity map class, according to the color in _amn_badges.scss
*/
export const severityMap = {
  User: 'user',
  Admin: 'admin',
  Master: 'master',
  FinancialAdvisor: 'financialAdvisor',
  Legal: 'legal'
};
