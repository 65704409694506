import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  standalone: true,
  selector: 'app-contracts',
  imports: [PrimeNgModule, TranslateModule, CommonModule, SharedModule],
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent {
  @Input() contracts!: ContractDto[];
  @Output() typeSelected = new EventEmitter<string>();

  clear(table: Table) {
    table.clear();
  }
}
