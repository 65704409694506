import { Component, EventEmitter, Output } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { AddUserFormComponent } from '../add-user-form/add-user-form.component';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, AddUserFormComponent],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.scss'
})
export class AddUserComponent {
  @Output() addNewUser = new EventEmitter<void>();

  visible = false;

  onAddClick(): void {
    this.visible = true;
  }

  handleOnShowParentModal($event: boolean) {
    this.visible = $event;
  }

  handleOnAddNewUser() {
    this.addNewUser.emit();
  }
}
