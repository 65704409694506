import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { BankDto } from '@app/data/amn-api/models';

@Component({
  selector: 'app-banks-grid',
  standalone: true,
  imports: [PrimeNgModule, TranslateModule, CommonModule, SharedModule],
  templateUrl: './banks-grid.component.html',
  styleUrl: './banks-grid.component.scss'
})
export class BanksGridComponent {
  @Input() banks!: BankDto[];
  @Output() bankSelectedData = new EventEmitter<BankDto>();
  @Output() bankSelectedDeactivate = new EventEmitter<BankDto>();
  @Output() bankSelectedActivate = new EventEmitter<BankDto>();

  getCustomerBankData(bank: BankDto) {
    this.bankSelectedData.emit(bank);
  }

  setCustomerBankDeactivate(bank: BankDto) {
    this.bankSelectedDeactivate.emit(bank);
  }

  setCustomerBankActivate(bank: BankDto) {
    this.bankSelectedActivate.emit(bank);
  }
}
