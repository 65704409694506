import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { TaxRegimeDto } from '@app/data/amn-api/models';
import { AddTaxRegimesComponent } from '../add-tax-regimes/add-tax-regimes.component';

@Component({
  selector: 'app-tax-regimes',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PrimeNgModule,
    AddTaxRegimesComponent
  ],
  templateUrl: './tax-regimes.component.html',
  styleUrl: './tax-regimes.component.scss'
})
export class TaxRegimesComponent {
  @Input() taxRegimesByUser: TaxRegimeDto[] = [];

  handleGetUpdatedRegimes(regimes: TaxRegimeDto[]) {
    this.taxRegimesByUser = [];
    this.taxRegimesByUser = regimes;
  }
}
