import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, PrimeIcons, SharedModule } from 'primeng/api';

@Component({
  selector: 'app-documents-list',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, TranslateModule],
  templateUrl: './documents-list.component.html',
  styleUrl: './documents-list.component.scss'
})
export class DocumentsListComponent {
  @Input() documents!: DocumentDto[] | null | undefined;
  @Output() deleteDocumentRequest = new EventEmitter<number>();
  @Output() requestDownloadDocument = new EventEmitter<{
    documentId: number;
    filename?: string | null | undefined;
  }>();

  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  requestDeleteDocument(documentId: number, event: Event): void {
    const message = this.translateService.instant('Documents.DeleteConfirm');
    const header = this.translateService.instant('Documents.Confirmation');
    const accept = this.translateService.instant('Common.Accept');
    const cancel = this.translateService.instant('Common.Cancel');

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-secondary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptIcon: PrimeIcons.CHECK + ' mr-2',
      rejectIcon: PrimeIcons.TIMES + ' mr-2',
      acceptLabel: accept,
      rejectLabel: cancel,

      accept: () => {
        this.deleteDocumentRequest.emit(documentId);
      }
    });
  }

  onDownloadDocument(documentId: number): void {
    if (!this.documents) return;

    const document = this.documents.find((doc) => doc.id === documentId);

    if (document) {
      this.requestDownloadDocument.emit({
        documentId,
        filename: document.fileName
      });
    } else {
      console.error('Document not found for ID:', documentId);
    }
  }
}
