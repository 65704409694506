import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AlertsService,
  AuthenticationService,
  LanguageService,
  ProfileService,
  RolesService,
  StorageService,
  NavigationService
} from '@core/services';
import { ConfirmationService, MessageService } from 'primeng/api';

@NgModule({
  declarations: [],
  providers: [
    AlertsService,
    AuthenticationService,
    LanguageService,
    ProfileService,
    RolesService,
    StorageService,
    ConfirmationService,
    MessageService,
    NavigationService
  ],
  imports: [CommonModule]
})
export class CoreModule {}
