<p-button
  (click)="handleAddClick()"
  label="{{ 'Common.AddNew' | translate }}"
  icon="pi pi-plus"
  [outlined]="true"></p-button>
<p-dialog
  [header]="isEditing ? titleToAdd : titleToEdit"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <p-toast></p-toast>
  <section class="addBanks">
    <form (ngSubmit)="handleSubmit()" [formGroup]="bankForm">
      <div class="grid formgrid p-fluid">
        <div class="field col-6">
          <app-amn-input
            id="bankName"
            label="{{ 'Banks.BankName' | translate }}"
            formControlName="bankName"
            [errorMessages]="{
              required: 'Banks.Errors.BankNameRequired' | translate
            }">
          </app-amn-input>
        </div>
        <div class="field col-6 flex justify-content-between">
          <div class="field w-5">
            <label for="country" class="font-medium text-900">{{
              'Banks.Country' | translate
            }}</label>
            <p-dropdown
              id="country"
              [options]="countryOptions"
              optionValue="value"
              optionLabel="label"
              formControlName="country"
              placeholder="Select country"
              appendTo="body"
              (onChange)="handleChangeCountry($event)">
            </p-dropdown>
          </div>
          <div class="field w-5">
            <label for="currency" class="font-medium text-900">{{
              'Banks.Currency' | translate
            }}</label>
            <p-dropdown
              id="currency"
              [options]="currencyOptions"
              optionValue="value"
              optionLabel="label"
              formControlName="currency"
              placeholder="Select currency"
              appendTo="body">
            </p-dropdown>
          </div>
        </div>
        <div class="field col-6">
          <app-amn-input-mask
            id="cardNumber"
            label="{{ 'Banks.CardNumber' | translate }}"
            mask="9999 9999 9999 9999"
            formControlName="cardNumber"
            [errorMessages]="{
              pattern: 'Banks.Errors.CardNumberInvalid' | translate
            }"
            placeholder="#### #### #### ####">
          </app-amn-input-mask>
        </div>
        <div class="field col-6">
          <app-amn-input-mask
            id="accountNumber"
            label="{{ 'Banks.AccountNumber' | translate }}"
            mask="999999999?999999999"
            formControlName="accountNumber"
            [errorMessages]="{
              required: 'Banks.Errors.AccountNumberRequired' | translate,
              pattern: 'Banks.Errors.AccountNumberInvalid' | translate
            }"
            placeholder="#########?#########">
          </app-amn-input-mask>
        </div>
        <div class="field col-6">
          <app-amn-input-mask
            id="clabe"
            label="{{ 'Banks.Clabe' | translate }}"
            mask="999 999 99999999999 9"
            formControlName="clabe"
            [errorMessages]="{
              required: 'Banks.Errors.CLABERequired' | translate,
              pattern: 'Banks.Errors.CLABEInvalid' | translate
            }"
            placeholder="### ### ########### #">
          </app-amn-input-mask>
        </div>
        <div class="field col-6">
          <app-amn-input-mask
            id="routingNumber"
            label="{{ 'Banks.RoutingNumber' | translate }}"
            mask="999999999"
            formControlName="routingNumber"
            [errorMessages]="{
              required: 'Banks.Errors.RoutingNumberRequired' | translate,
              pattern: 'Banks.Errors.RoutingNumberInvalid' | translate
            }"
            placeholder="#########">
          </app-amn-input-mask>
        </div>
        <div class="field col-6">
          <app-amn-input
            id="SWIFT"
            label="{{ 'Banks.SWIFT' | translate }}"
            formControlName="SWIFT">
          </app-amn-input>
        </div>
        <div class="field col-12">
          <p-checkbox
            formControlName="isDefault"
            [binary]="true"
            inputId="binary"
            label="{{ 'Banks.IsDefault' | translate }}"></p-checkbox>
        </div>

        <div class="w-full flex justify-content-end flex-wrap">
          <p-button
            label="{{ 'Common.Cancel' | translate }}"
            icon="pi pi-times"
            class="w-auto mr-3 mt-3"
            [outlined]="true"
            severity="secondary"
            (click)="handleCancelClick()"
            [style.display]="this.isEditing ? 'block' : 'none'"></p-button>
          <p-button
            type="submit"
            label="{{ submitLabel | translate }}"
            icon="pi pi-check"
            class="w-auto mt-3 mr-3"
            [outlined]="true"
            severity="primary"
            [disabled]="!this.bankForm.valid"
            [style.display]="this.isEditing ? 'block' : 'none'"></p-button>
          <p-button
            label="{{ 'Common.Edit' | translate }}"
            icon="pi pi-user-edit"
            class="w-auto mr-3 mt-3"
            (click)="onEditClick()"
            [style.display]="this.isEditing ? 'none' : 'block'"></p-button>
        </div>
      </div>
    </form>
  </section>
</p-dialog>
