<form
  (ngSubmit)="updateExpense()"
  #customerForm="ngForm"
  [formGroup]="expenseForm">
  <div class="flex flex-column md:flex-row">
    <div
      class="w-full flex flex-column align-items-center justify-content-center gap-3 py-5">
      <div class="grid formgrid p-fluid">
        <div class="field col-12 sm:col-6">
          <app-amn-input
            id="description"
            label="{{ 'Expenses.ExpenseDescription' | translate }}"
            formControlName="description"
            [errorMessages]="{
              required: 'Expenses.DescriptionRequired' | translate
            }"></app-amn-input>
        </div>
        <div class="field col-12 sm:col-3">
          <app-amn-input-money
            id="amount"
            label="{{ 'Common.Amount' | translate }}"
            formControlName="amount"
            [errorMessages]="{
              required: 'Expenses.AmountRequired' | translate,
              min: 'Expenses.AmountMin' | translate
            }">
          </app-amn-input-money>
        </div>
        <div class="field col-12 sm:col-3">
          <label for="currency" class="font-medium text-900">{{
            'Common.Currency' | translate
          }}</label>
          <p-dropdown
            id="currency"
            [options]="currency"
            optionValue="value"
            optionLabel="label"
            formControlName="currency"
            placeholder="{{ 'Deposit.SelectCurrency' | translate }}"
            appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="category" class="font-medium text-900">{{
            'Expenses.ExpenseCategory' | translate
          }}</label>
          <p-dropdown
            id="category"
            [options]="expenseCategory"
            optionValue="value"
            optionLabel="label"
            formControlName="category"
            placeholder="{{
              'Expenses.ExpenseCategoryDescription' | translate
            }}"
            appendTo="body">
          </p-dropdown>
        </div>
        <div class="field col-12 sm:col-6">
          <app-amn-input-date
            id="date"
            label="{{ 'Common.Date' | translate }}"
            formControlName="date"
            view="date"
            [errorMessages]="{ required: 'Common.DateRequired' | translate }">
          </app-amn-input-date>
        </div>
        <div class="field col-12 sm:col-12">
          <app-amn-input-textarea
            id="notes"
            label="{{ 'Expenses.Notes' | translate }}"
            formControlName="notes"
            [errorMessages]="{ min: 'Expenses.NotesMax' | translate }">
          </app-amn-input-textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full flex align-items-center justify-content-end flex-wrap">
    <p-button
      label="{{ 'Common.Cancel' | translate }}"
      icon="pi pi-times"
      class="w-auto mr-3 mt-3"
      [outlined]="true"
      severity="secondary"
      (click)="onCancelClick()"></p-button>
    <p-button
      type="submit"
      label="{{ submitLabel | translate }}"
      icon="pi pi-check"
      class="w-auto mt-3 mr-3"
      [outlined]="true"
      severity="primary"
      [disabled]="!this.expenseForm.valid"></p-button>
  </div>
</form>
