import { roles, severityMap } from '@app/data/models';

export function getSeverityByDescription(description: string): string {
  return severityMap[description as keyof typeof severityMap] || 'unknown';
}

export function getSeverityById(id: number): string {
  const role = roles.find((role) => role.id === id);
  return role && role.description != null
    ? getSeverityByDescription(role.description)
    : 'unknown';
}

export function getDescriptionById(id: number): string | null {
  const role = roles.find((role) => role.id === id);
  return role ? (role.description ?? null) : null;
}
