/* eslint-disable @typescript-eslint/no-explicit-any */
export class InputClass {
  public onChange!: (value: any) => void;
  public onTouched!: () => void;

  value: any;

  /**** Control Accessor Events ****/

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  writeValue(value: any) {
    this.value = value;
  }
  updateValue(value: any) {
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
