import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { EditExpenseFormComponent } from '../edit-expense-form/edit-expense-form.component';

@Component({
  selector: 'app-modal-edit-expense',
  standalone: true,
  imports: [PrimeNgModule, EditExpenseFormComponent, TranslateModule],
  templateUrl: './modal-edit-expense.component.html',
  styleUrl: './modal-edit-expense.component.scss'
})
export class ModalEditExpenseComponent {
  visible: boolean = false;
  @Input() expenseId: number = 0;

  @Output() updateExpense = new EventEmitter<void>();

  @ViewChild(EditExpenseFormComponent) editExpense!: EditExpenseFormComponent;

  onAddClick(): void {
    this.visible = true;
    if (this.expenseId) {
      this.editExpense.loadExpenseDetails(this.expenseId);
    }
  }

  onCancelClick(): void {
    this.visible = false;
  }

  onSubmit(): void {
    this.visible = false;
    this.updateExpense.emit();
  }
}
