<div class="field">
  <label for="{{ id }}" class="font-medium text-900">{{ label }}</label>
  <textarea
    [id]="id"
    [(ngModel)]="value"
    (keyup)="onValueChanged()"
    [placeholder]="placeholder"
    [pattern]="pattern"
    title="{{ label }}"
    [disabled]="isDisabled"
    [class.ng-invalid]="
      !ngControl || !ngControl.control
        ? false
        : ngControl.control.enabled &&
          !ngControl.control.valid &&
          !ngControl.control.pristine
    "
    rows="5"
    cols="30"
    pInputTextarea></textarea>
  <div *ngFor="let error of ngControl?.errors | keyvalue">
    @if (hasError(error.key)) {
      <small [id]="id + '-help' + error" class="p-error">
        {{ errorMessages[error.key] }}
      </small>
    }
  </div>
</div>
