/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExchangeRateResponseApiResponse } from '../models/exchange-rate-response-api-response';
import { getLatestExchangeRateQuery } from '../fn/rates/get-latest-exchange-rate-query';
import { GetLatestExchangeRateQuery$Params } from '../fn/rates/get-latest-exchange-rate-query';
import { getLatestExchangeRateQuery$Plain } from '../fn/rates/get-latest-exchange-rate-query-plain';
import { GetLatestExchangeRateQuery$Plain$Params } from '../fn/rates/get-latest-exchange-rate-query-plain';
import { getLiveExchangeRateQuery } from '../fn/rates/get-live-exchange-rate-query';
import { GetLiveExchangeRateQuery$Params } from '../fn/rates/get-live-exchange-rate-query';
import { getLiveExchangeRateQuery$Plain } from '../fn/rates/get-live-exchange-rate-query-plain';
import { GetLiveExchangeRateQuery$Plain$Params } from '../fn/rates/get-live-exchange-rate-query-plain';

@Injectable({ providedIn: 'root' })
export class RatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getLiveExchangeRateQuery()` */
  static readonly GetLiveExchangeRateQueryPath = '/api/rates/live';

  /**
   * Get exchange rate from an external API (default: USD/MXN).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveExchangeRateQuery$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveExchangeRateQuery$Plain$Response(params?: GetLiveExchangeRateQuery$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateResponseApiResponse>> {
    return getLiveExchangeRateQuery$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get exchange rate from an external API (default: USD/MXN).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLiveExchangeRateQuery$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveExchangeRateQuery$Plain(params?: GetLiveExchangeRateQuery$Plain$Params, context?: HttpContext): Observable<ExchangeRateResponseApiResponse> {
    return this.getLiveExchangeRateQuery$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateResponseApiResponse>): ExchangeRateResponseApiResponse => r.body)
    );
  }

  /**
   * Get exchange rate from an external API (default: USD/MXN).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveExchangeRateQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveExchangeRateQuery$Response(params?: GetLiveExchangeRateQuery$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateResponseApiResponse>> {
    return getLiveExchangeRateQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * Get exchange rate from an external API (default: USD/MXN).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLiveExchangeRateQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveExchangeRateQuery(params?: GetLiveExchangeRateQuery$Params, context?: HttpContext): Observable<ExchangeRateResponseApiResponse> {
    return this.getLiveExchangeRateQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateResponseApiResponse>): ExchangeRateResponseApiResponse => r.body)
    );
  }

  /** Path part for operation `getLatestExchangeRateQuery()` */
  static readonly GetLatestExchangeRateQueryPath = '/api/rates/latest';

  /**
   * Get lates available exchange rate (default: USD/MXN).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestExchangeRateQuery$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestExchangeRateQuery$Plain$Response(params?: GetLatestExchangeRateQuery$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateResponseApiResponse>> {
    return getLatestExchangeRateQuery$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get lates available exchange rate (default: USD/MXN).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestExchangeRateQuery$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestExchangeRateQuery$Plain(params?: GetLatestExchangeRateQuery$Plain$Params, context?: HttpContext): Observable<ExchangeRateResponseApiResponse> {
    return this.getLatestExchangeRateQuery$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateResponseApiResponse>): ExchangeRateResponseApiResponse => r.body)
    );
  }

  /**
   * Get lates available exchange rate (default: USD/MXN).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestExchangeRateQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestExchangeRateQuery$Response(params?: GetLatestExchangeRateQuery$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateResponseApiResponse>> {
    return getLatestExchangeRateQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * Get lates available exchange rate (default: USD/MXN).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestExchangeRateQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestExchangeRateQuery(params?: GetLatestExchangeRateQuery$Params, context?: HttpContext): Observable<ExchangeRateResponseApiResponse> {
    return this.getLatestExchangeRateQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateResponseApiResponse>): ExchangeRateResponseApiResponse => r.body)
    );
  }

}
