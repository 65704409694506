import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ReportService } from '@amn-api/services/report.service';
import { AlertsService } from '@app/core/services';
import { FilterMatchMode } from 'primeng/api';
import { first } from 'rxjs';
import {
  CustomerInvestmentsDto,
  TotalInvestmentsDto,
  TotalInvestmentsDtoApiResponse
} from '@app/data/amn-api/models';
import { ContractService } from '@app/data/amn-api/services';
import { TotalInvestmentsComponent } from '@app/shared/components/total-investments/total-investments.component';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  selector: 'app-customers-investments',
  standalone: true,
  imports: [
    PrimeNgModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    SharedModule,
    TotalInvestmentsComponent
  ],
  templateUrl: './customers-investments.component.html',
  styleUrl: './customers-investments.component.scss'
})
export class CustomersInvestmentsComponent implements AfterViewInit, OnInit {
  @ViewChild('dt') table!: Table;

  customersTotals: TotalInvestmentsDto | undefined;
  customers!: CustomerInvestmentsDto;
  filteredCustomers!: CustomerInvestmentsDto;
  totalRecords!: number;
  isLoadingCustomers: boolean = false;

  expandedRows = {};

  top: number = 10;
  skip: number = 0;
  searchCriteriaCustomer: string = '';
  sortBy: string = '';
  sortDesc: boolean = false;
  backClicked: boolean = false;

  constructor(
    private reportService: ReportService,
    private alertsService: AlertsService,
    private contractService: ContractService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const searchData = localStorage.getItem('searchTermsCustomers');
    this.searchCriteriaCustomer = searchData ? searchData : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchCriteriaCustomer) {
        this.table.filterGlobal(
          this.searchCriteriaCustomer,
          FilterMatchMode.CONTAINS
        );
      }
    });
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteriaCustomer =
        (event.globalFilter as string) ?? this.searchCriteriaCustomer;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'customersFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteriaCustomer,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        })
      );
      this.loadCustomers();
    }
  }

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsCustomers(searchTerm);
    table.filterGlobal(this.searchCriteriaCustomer, FilterMatchMode.CONTAINS);
  }

  loadCustomers(): void {
    this.isLoadingCustomers = true;

    this.reportService
      .searchCustomersInvestments({
        SearchText: this.searchCriteriaCustomer,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc
      })
      .pipe(first())
      .subscribe({
        next: (value: TotalInvestmentsDtoApiResponse) => {
          this.isLoadingCustomers = false;
          if (value.data && value.data.customerInvestments) {
            this.customersTotals = value.data;
            this.totalRecords = value.data.totalRecords ?? 0;
          } else {
            this.alertsService.addWarning(
              'Alerts.CustomersNotFound',
              'Alerts.CustomersNotFoundDesc',
              true
            );
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  getSearchTermsCustomers(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteriaCustomer = searchTerm;
      localStorage.setItem('searchTermsCustomers', this.searchCriteriaCustomer);
    } else {
      this.searchCriteriaCustomer = '';
      localStorage.removeItem('searchTermsCustomers');
    }
  }

  collapseAll() {
    this.expandedRows = {};
  }

  onSendContractBalance(contractId: number): void {
    this.alertsService.addInfo(
      'Alerts.SendingBalance',
      'Alerts.SendingBalanceDescription',
      true
    );
    this.contractService
      .sendCustomerContractBalance({ id: contractId })
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertsService.addSuccess(
            'Alerts.SendContractBalanceSuccess',
            'Alerts.SendContractBalanceSuccessDescription',
            true
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }
}
