import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { Nullable } from 'primeng/ts-helpers';

const INPUT_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AmnInputPhoneComponent),
  multi: true
};

@Component({
  selector: 'app-amn-input-phone',
  templateUrl: './amn-input-phone.component.html',
  styleUrls: ['./amn-input-phone.component.scss'],
  providers: [INPUT_CONTROL_ACCESSOR]
})
export class AmnInputPhoneComponent implements ControlValueAccessor, OnInit {
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() errorMessages: { [key: string]: string } = {};

  countryCode: Nullable<string> | undefined;
  phoneNumber: string | null = '';
  public isDisabled: boolean = false;

  countriesCodes = [
    { label: 'US', value: '+1' },
    { label: 'MX', value: '+52' }
  ];

  ngOnInit() {
    this.countryCode = this.countriesCodes.find(
      (country) => country.label === 'MX'
    )?.value;
    Promise.resolve().then(
      () => (this.ngControl = this.injector.get(NgControl))
    );
  }
  public ngControl!: NgControl;

  constructor(private injector: Injector) {}

  writeValue(fullPhoneNumber: string): void {
    if (fullPhoneNumber) {
      const { countryCode, phoneNumber } =
        this.parsePhoneNumber(fullPhoneNumber);
      this.countryCode = countryCode;
      this.phoneNumber = phoneNumber;
    } else {
      this.countryCode = null;
      this.phoneNumber = null;
    }
  }

  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCountryCodeChange(): void {
    this.updateValue();
  }

  onPhoneNumberChange(): void {
    this.updateValue();
  }

  private parsePhoneNumber(fullPhoneNumber: string): {
    countryCode: string;
    phoneNumber: string;
  } {
    // Logic to parse the phone number
    const parts = fullPhoneNumber.match(/\+(\d+)\s\((\d+)\)\s(\d+-\d+)/);
    if (parts) {
      return {
        countryCode: `+${parts[1]}`,
        phoneNumber: `(${parts[2]}) ${parts[3]}`
      };
    }
    return { countryCode: '', phoneNumber: fullPhoneNumber }; // Default fallback
  }

  updateValue(): void {
    if (this.onChange) {
      this.onChange(`${this.countryCode} ${this.phoneNumber}`);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public getCountryLabel(value: string) {
    const val = this.countriesCodes.find((country) => country.value === value);
    return val ? val.label.toLowerCase() : 'mx';
  }
}
