/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentDtoListApiResponse } from '../../models/document-dto-list-api-response';

export interface UploadTransactionDocumentAsync$Params {
  contractId: number;
  transactionId: number;
      body?: {
'ContractId'?: number;
'TransactionId'?: number;
'File'?: Blob;
}
}

export function uploadTransactionDocumentAsync(http: HttpClient, rootUrl: string, params: UploadTransactionDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
  const rb = new RequestBuilder(rootUrl, uploadTransactionDocumentAsync.PATH, 'post');
  if (params) {
    rb.path('contractId', params.contractId, {});
    rb.path('transactionId', params.transactionId, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentDtoListApiResponse>;
    })
  );
}

uploadTransactionDocumentAsync.PATH = '/api/contract/{contractId}/transaction/{transactionId}/document';
