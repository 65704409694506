import { Injectable } from '@angular/core';
import { RoleDto } from '@app/data/amn-api/models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /* User */
  private readonly ID_KEY = 'userId';
  get userId(): number | null {
    return Number(localStorage.getItem(this.ID_KEY)) || null;
  }
  set userId(id: number) {
    localStorage.setItem(this.ID_KEY, id.toString());
  }

  private readonly EMAIL_KEY = 'email';
  get userEmail(): string | null {
    return localStorage.getItem(this.EMAIL_KEY);
  }
  set userEmail(email: string) {
    localStorage.setItem(this.EMAIL_KEY, email);
  }

  private readonly NAME_KEY = 'name';
  get userName(): string | null {
    return localStorage.getItem(this.NAME_KEY);
  }
  set userName(name: string) {
    localStorage.setItem(this.NAME_KEY, name);
  }

  private readonly LAST_NAME_KEY = 'lastName';
  get userLastName(): string | null {
    return localStorage.getItem(this.LAST_NAME_KEY);
  }
  set userLastName(lastName: string) {
    localStorage.setItem(this.LAST_NAME_KEY, lastName);
  }

  private readonly FULL_NAME_KEY = 'fullName';
  get userFullName(): string | null {
    return localStorage.getItem(this.FULL_NAME_KEY);
  }
  set userFullName(fullName: string) {
    localStorage.setItem(this.FULL_NAME_KEY, fullName);
  }

  private readonly ROLES_KEY = 'roles';
  get userRoles(): Array<RoleDto> | null {
    const rolesString = localStorage.getItem(this.ROLES_KEY);
    if (rolesString) {
      return JSON.parse(rolesString);
    }
    return null;
  }
  set userRoles(roles: Array<RoleDto> | null) {
    localStorage.setItem(this.ROLES_KEY, JSON.stringify(roles));
  }

  /* Language */
  get language(): string | null {
    return localStorage.getItem('language');
  }
  set language(lang: string) {
    localStorage.setItem('language', lang);
  }
}
